import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import BasicDialog from "../../base/BasicDialog";
import PurchasingTreeItemForm from "../../form/PurchasingTreeItemForm";
import useItemService from "../../../services/itemService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useForm } from "../../../hooks/useForm";
import { getErrorMsg, isEmptyValue } from "../../../helpers/methods";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import TreeItemForm from "../../form/TreeItemForm";

export default function PurchasingTreeItemDialog(props) {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const newItemRequiredFields = ["item_number", "item_name"];
  const existItemRequiredFields = ["item_parent"]
  const requiredFields = props.branchId ? newItemRequiredFields.concat(existItemRequiredFields) : newItemRequiredFields
  const [isItemParentChanged, setIsItemParentChanged] = useState(false)

  const { getItemData, createItem, updateItem, getParametersList, getItemsSelectList, getNextItemNumber, getItemsFilteringData } =
    useItemService();

  const itemFilteringData = useAsync(getItemsFilteringData);

  const {
    formValue: itemFormValue,
    setFormValue: setItemFormValue,
    onChange,
    onChangeAutocompleteFieldWithObjectOptions,
  } = useForm({});

  const createItemFn = useAsyncFn(createItem);
  const updateItemFn = useAsyncFn(updateItem);

  const itemData = useAsync(() => {
    if (props.branchId) {
      return getItemData(props.branchId);
    }
    return Promise.resolve({});
  }, []);

  const itemSelectListData = useAsync(() => {
    let searchParams = props.branchId ? { children_excludes: props.branchId } : {}
    return getItemsSelectList(searchParams);
  }, []);

  const parentItemData = useAsync(() => {
    if (props.parentBranchId) {
      return getItemData(props.parentBranchId, { is_parent_branch: true });
    }
    return Promise.resolve({});
  }, []);

  const nextItemParentNumber = useAsync(() => {
    if (isItemParentChanged && itemFormValue?.item_parent) {
      return getNextItemNumber(itemFormValue.item_parent);
    }
    return Promise.resolve(itemFormValue);
  }, [itemFormValue?.item_parent]);

  const parameterList = useAsync(
    () => getParametersList());

  const { formValue: parentFormValue, setFormValue: setParentFormVale } =
    useForm({});

  useEffect(() => {
    if (itemData.loading) {
      return;
    }
    if (itemData.value) {
      setItemFormValue(itemData.value);
    }
  }, [itemData.loading]);

  useEffect(() => {
    if (nextItemParentNumber.loading) {
      return;
    }
    if (nextItemParentNumber.value && !isEmptyValue(nextItemParentNumber?.value?.next_number_to_replace)) {
      setItemFormValue((prev) => ({ ...prev, item_number: nextItemParentNumber.value.next_number_to_replace }));
    }
  }, [nextItemParentNumber.loading]);


  const onChangeAutocompleteItemParent = useCallback(
    (e, value_object, value_key, state_value_name) => {
      setIsItemParentChanged(true)
      setItemFormValue((prev) => ({ ...prev, item_number: '  ' }))
      return onChangeAutocompleteFieldWithObjectOptions(e, value_object, value_key, state_value_name)
    },
    []
  );

  useEffect(() => {
    if (parentItemData.loading) {
      return;
    }
    if (parentItemData.value) {
      setParentFormVale(parentItemData.value);
      setItemFormValue({ item_number: parentItemData.value.next_item_number });
    }
  }, [parentItemData.loading]);

  const preprareDataToSave = (data) => {

    if (props.branchId) {
      return data
    }
    return { ...data, item_parent: props.parentBranchId };
  };

  const isValid = itemFormValue
    ? requiredFields.every(
      (fieldName) => !isEmptyValue(itemFormValue[fieldName])
    )
    : false;

  const onSubmit = useCallback(() => {
    if (props.branchId) {
      updateItemFn
        .execute(props.branchId, preprareDataToSave(itemFormValue))
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.item_updated")
          );
          props.onClose(true);
        })
        .catch((err) => {
          snackbarAlert.openErrorSnackbarAlert(
            getErrorMsg(err.data),
            t("snackbar_alert.occurred_error_during_item_updating")
          );
        });
    } else {
      createItemFn
        .execute(preprareDataToSave(itemFormValue))
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.item_created")
          );
          props.onClose(true);
        })
        .catch((err) => {
          snackbarAlert.openErrorSnackbarAlert(
            getErrorMsg(err.data),
            t("snackbar_alert.occurred_error_during_item_creating")
          );
        });
    }
  }, [itemFormValue]);

  return (
    <BasicDialog
      open={props.open}
      onClose={() => props.onClose()}
      titleAlign="center"
      contentAlign="center"
      title={
        props.branchId
          ? t(`dialog.purchasing_tree_item_dialog.edit_branch`)
          : t(`dialog.purchasing_tree_item_dialog.add_branch`)
      }
      maxWidth={props.showExtendForm ? "xl" : "sm"}
      showDialogActions
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <LoaderWrapper showLoader={itemSelectListData.loading}>
          <Grid item xs={12}>
            <PurchasingTreeItemForm
              branchId={props.branchId}
              parentBranchId={props.parentBranchId}
              parentFormValue={parentFormValue}
              itemFormValue={itemFormValue}
              itemSelectListData={itemSelectListData}
              parameterList={parameterList}
              isItemParentChanged={isItemParentChanged}
              onChange={onChange}
              onChangeAutocompleteItemParent={onChangeAutocompleteItemParent}
              onChangeAutocompleteFieldWithObjectOptions={
                onChangeAutocompleteFieldWithObjectOptions
              }
              showExtendForm={props.showExtendForm}
            />
          </Grid>
          <Grid item xs={12}>
            <TreeItemForm
              item={itemFormValue}
              filteringData={itemFilteringData}
              readOnly={false}
              onChangeAutocomplete={
                onChangeAutocompleteFieldWithObjectOptions
              }
              onChange={onChange}
              onChangeAutocompleteItemParent={onChangeAutocompleteItemParent}
              showSubmitButton = {false}
              allowChangeParent={true}
              parents={itemSelectListData?.value ? itemSelectListData.value : []}
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              loading={false}
              disabled={!isValid}
              onClick={onSubmit}
            >
              {t("save")}
            </LoadingButton>
          </Grid>
        </LoaderWrapper>
      </Grid>
    </BasicDialog>
  );
}

PurchasingTreeItemDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  branchId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  parentBranchId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  openSnackbarAlert: PropTypes.func,
  showExtendForm: PropTypes.bool
};

PurchasingTreeItemDialog.defaultProps = {
  open: false,
  showExtendForm: false
};
