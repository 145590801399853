import React, { useState } from "react";

import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import Divider from "@mui/material/Divider";

import ItemDetailsForm from "../../UniversalDetailsDrawerForms/ItemDetailsForm";
import PersonsForm from "../../UniversalDetailsDrawerForms/PersonsForm";
import LocalizationForm from "../../UniversalDetailsDrawerForms/LocalizationForm";
import CommunicationLogForm from "../../UniversalDetailsDrawerForms/CommunicationLogForm";
import DateForm from "../../UniversalDetailsDrawerForms/DateForm";
import ActionIconsForm from "../../UniversalDetailsDrawerForms/ActionIconsForm";

import { useForm } from "../../../../../hooks/useForm";
import { useSnackbarAlert } from "../../../../../context/snackbarAlert";
import { useTranslation } from "react-i18next";
import { getErrorMsg } from "../../../../../helpers/methods";

const OrderDrawerContent = (props) => {
  const {
    formValue,
    setFormValue,
    onChangeWithCallback,
    onChangeAutocompleteFieldWithObjectOptionsAndCallback,
    onChangeDateWithCallback,
  } = useForm(props.itemData);

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const snackbarAlert = useSnackbarAlert();

  const handleChangeFieldValue = (name, value) => {
    setIsLoading(true);
    const oldValue = formValue[name];
    props
      .onUpdateDataPromise(formValue.id, { [name]: value })
      .then(() => {
        props.onRefetchData();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(getErrorMsg(error.data));
        setFormValue((prev) => ({ ...prev, [name]: oldValue }));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangeLocations = (locationData, onCloseDialog) => {
    const oldLocationData = formValue.locations;
    setIsLoading(true);
    props
      .onUpdateDataPromise(formValue.id, { objects: locationData })
      .then((data) => {
        setFormValue((prev) => ({ ...prev, locations: data.locations }));
        onCloseDialog();
        props.onRefetchData();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(getErrorMsg(error.data));
        setFormValue((prev) => ({ ...prev, locations: oldLocationData }));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangeStatus = (statusData, onCloseDialog) => {
    const oldStatus = formValue.status;
    setIsLoading(true);
    props
      .onUpdateDataPromise(formValue.id, { status_data: statusData })
      .then((data) => {
        setFormValue((prev) => ({
          ...prev,
          status: data.status,
        }));
        onCloseDialog();
        props.onRefetchData();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(getErrorMsg(error.data));
        setFormValue((prev) => ({ ...prev, status: oldStatus }));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const isOrderClosed = props.itemData.is_closed;
  const isOrderFinallyClosed = props.itemData.is_finally_closed;

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      paddingX={1}
      rowGap={1}
      marginTop={1}
      marginBottom={3}
    >
      <Grid item xs={12}>
        <PersonsForm
          ownerName={formValue.ticket_owner?.full_name}
          ownerId={formValue.ticket_owner?.id}
          tenantName={formValue.tenant?.tenant_short_name}
          tenantId={formValue.tenant?.id}
          omName={formValue.ticket_om?.full_name}
          omId={formValue.ticket_om?.id}
          reposoniblePersonId={formValue.order_kt}
          onChangeResponsiblePerson={onChangeAutocompleteFieldWithObjectOptionsAndCallback(
            handleChangeFieldValue
          )}
          isResponsiblePersonAllowedToChange={!props.readOnly && !isOrderClosed}
          reposoniblePersonOptions={props.filteringData.order_kt}
          itemType={props.itemType}
          skillId={formValue.skill}
          skillOptions={props.filteringData.skill}
          onChangeSkill={onChangeAutocompleteFieldWithObjectOptionsAndCallback(
            handleChangeFieldValue
          )}
          isSkillAllowedToChange={!props.readOnly && !isOrderClosed}
          orderTId={formValue.order_t}
          onChangeOrderT={onChangeAutocompleteFieldWithObjectOptionsAndCallback(
            handleChangeFieldValue
          )}
          isOrderTAllowedToChange={!props.readOnly && !isOrderClosed}
          orderTOptions={props.filteringData.order_t}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <LocalizationForm
          itemForChangeLocalizationId={formValue.id}
          isAllowedToChangeLocalization={!props.readOnly && !isOrderClosed}
          localizationData={formValue.locations}
          isMultipleLocalization={formValue.is_multiple_locations}
          onChangeLocalization={handleChangeLocations}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <DateForm
          createdDate={formValue.created}
          onChangeFinishPlannedDate={onChangeDateWithCallback(
            handleChangeFieldValue
          )}
          isAllowedToChangeFinishPlannedDate={!props.readOnly && !isOrderClosed}
          finishPlannedDate={formValue.order_deadlineKT}
          finishPlannedDateFieldName={"order_deadlineKT"}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <ItemDetailsForm
          isPaid={formValue.order_paid}
          isAllowedToChangeIsPaid={!props.readOnly && !isOrderClosed}
          onChangeIsPaid={onChangeWithCallback(handleChangeFieldValue)}
          statusName={formValue.status}
          categoryId={formValue.ticket_category}
          isCategoryAllowedToChange={false}
          categoryOptions={props.filteringData.ticket_category}
          onChangeCategory={onChangeAutocompleteFieldWithObjectOptionsAndCallback(
            handleChangeFieldValue
          )}
          priorityId={formValue.order_priority}
          priorityOptions={props.filteringData.order_priority}
          showIsDeadLineConfirmed={true}
          isDeadlineConfirmed={formValue.is_deadline_confirmed}
          isAllowedToChangeDeadlineConfirmed={false}
          itemType={props.itemType}
          onChangePriority={onChangeAutocompleteFieldWithObjectOptionsAndCallback(
            handleChangeFieldValue
          )}
          isPriorityAllowedToChange={!props.readOnly && !isOrderClosed}
          itemName={formValue.order_name}
          onChangeTextField={onChangeWithCallback(handleChangeFieldValue)}
          isAllowedToChangeItemName={!props.readOnly && !isOrderClosed}
          orderStdTimeValue={formValue.order_std_time_value}
          onChangeOrderStdTimeValue={onChangeWithCallback(
            handleChangeFieldValue
          )}
          isAllowedToChangeOrderStdTimeValue={!props.readOnly && !isOrderClosed}
          orderType={formValue.order_type}
          orderTypeOptions={props.filteringData.order_type}
          onChangeOrderType={onChangeAutocompleteFieldWithObjectOptionsAndCallback(
            handleChangeFieldValue
          )}
          isAllowedToChangeOrderType={!props.readOnly && !isOrderClosed}
          ticketDescCreator={formValue.ticket_desc_creator}
          orderDescCreator={formValue.order_desc_creator}
          isAllowedToChangeOrderDescCreator={
            !props.readOnly && !isOrderClosed && formValue.is_order_responsible
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <CommunicationLogForm
        lastMessages={formValue.order_last_messages}
        itemId={formValue.id}
        itemType={props.itemType}
        showExtendedAddEnclosureDialog={true}
        onOpenDialogParamsAction={props.onOpenDialogParamsAction}
        onRefetchData={props.onRefetchData}
      />
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <ActionIconsForm
          itemId={formValue.id}
          itemData={formValue}
          isItemClosed={isOrderClosed}
          isItemFinallyClosed={isOrderFinallyClosed}
          onChangeStatus={handleChangeStatus}
          isLoading={isLoading}
          enclosuresCount={formValue.enclosures_count}
          availableActionButtons={props.availableActionButtons}
          itemType={props.itemType}
          onRefetchData={props.onRefetchData}
          onRefetchTableData={props.onRefetchTableData}
        />
      </Grid>
    </Grid>
  );
};

OrderDrawerContent.propTypes = {
  itemData: PropTypes.object,
  itemType: PropTypes.oneOf(["order"]),
  onRefetchData: PropTypes.func,
  readOnly: PropTypes.bool,
  availableActionButtons: PropTypes.array,
  onRefetchTableData: PropTypes.func,
};

OrderDrawerContent.defaultProps = {
  itemType: "order",
  isLoading: false,
  readOnly: false,
};

export default OrderDrawerContent;
