import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import i18n from "../../../../i18n";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../../field/TextFieldFormControl";
import FieldWithLabel from "../../../field/FieldWithLabel";
import DateService from "../../../../services/dateService";
import PersonIcon from "@mui/icons-material/Person";
import AutocompleteField from "../../../field/AutocompleteField";
import TrueFalseSelectField from "../../../field/TrueFalseSelectField";
import UserDetailsDialog from "../../../dialog/UserDetailsDialog";
import TenantDetailsDialog from "../../../dialog/TenantDetailsDialog";
import useDialog from "../../../../hooks/useDialog";
import OwnerDetailsDialog from "../../../dialog/OwnerDetailsDialog";
import TicketDetailsDialog from "../../../dialog/TicketDetailsDialog"
import { useTranslation } from "react-i18next";
import SettlementFormBox from "../../../box/SettlementFormBox/SettlementFormBox";
import DateField from "../../../field/DateField/DateField";
import { useAuth } from "../../../../context/auth";

const OfferForm = ({
  data,
  filteringData,
  onChange,
  onClear,
  readOnly,
  onChangeAutocompletedOfferDetails,
  dialogMode,
  unfillRequiredFields,
  onDecrementPayers,
  settlements,
  showSettlements,
  onChangeDate }) => {

  const { t } = useTranslation();

  const { user } = useAuth()

  const [
    openUserDetailsDialog,
    onOpenUserDetailsDialog,
    onCloseUserDetailsDialog,
  ] = useDialog();

  const [
    openTenantDetailsDialog,
    onOpenTenantDetailsDialog,
    onCloseTenantDetailsDialog,
  ] = useDialog();

  const [
    openOwnerDetailsDialog,
    onOpenOwnerDetailsDialog,
    onCloseOwnerDetailsDialog,
  ] = useDialog();

  const [
    openTicketDetailsDialog,
    onOpenTicketDetailsDialog,
    onCloseTicketDetailsDialog,
  ] = useDialog();

  const selectedItemId = useRef();
  const tenantDialogTitle = useRef();

  const handleOpenTenantDetailsDialog = (id, title) => {
    selectedItemId.current = id;
    tenantDialogTitle.current = title
    onOpenTenantDetailsDialog()
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      {dialogMode !== "newOffer" &&
        <>
          <Grid item xs={12}>
            <FieldWithLabel
              label={i18n.t("form.offer.created_date")}
              field={
                <TextFieldFormControl
                  value={DateService.convertDatetimeFromBackendToFormatYYYYMMDD(data?.created)}
                  disabled
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FieldWithLabel
              label={i18n.t("form.offer.updated_date")}
              field={
                <TextFieldFormControl
                  value={DateService.convertDatetimeFromBackendToFormatYYYYMMDD(data?.updated)}
                  disabled
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FieldWithLabel
              label={i18n.t("form.offer.date_send_om")}
              field={
                <TextFieldFormControl
                  value={DateService.convertDatetimeFromBackendToFormatYYYYMMDD(data?.offer_date_send_om)}
                  disabled
                />
              }
            />
          </Grid>
          {!data?.offer_internal &&
            <Grid item xs={12}>
              <FieldWithLabel
                label={i18n.t("form.offer.terminated_date")}
                field={
                  readOnly ?
                    <TextFieldFormControl
                      value={DateService.convertDatetimeFromBackendToFormatYYYYMMDD(data?.offer_date_terminated)}
                      disabled
                    /> :
                    <DateField
                      name={"offer_date_terminated"}
                      value={data?.offer_date_terminated && data?.offer_date_terminated !=""? DateService.convertDatetimeFromBackendToFormatYYYYMMDD(data?.offer_date_terminated ): undefined}
                      onChange={onChangeDate}
                    />
                }
              />
            </Grid>
          }
        </>
      }
      <Grid item xs={12}>
        <FieldWithLabel
          label={i18n.t("form.offer.refers_to_ticket")}
          field={
            <TextFieldFormControl
              value={data?.ticket_nr}
              disabled
              suffix={
                data?.ticket_nr ? (
                  <PersonIcon
                    style={{ cursor: "pointer" }}
                    onClick={onOpenTicketDetailsDialog}
                  />
                ) : null
              }
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={i18n.t("form.offer.main_account")}
          required
          field={ 
            (readOnly && !user.is_k) ?
              <TextFieldFormControl
                value={data?.account_name}
                disabled
              />
              :
              <AutocompleteField
                name={"account"}
                value={data?.account}
                options={filteringData ? filteringData.account : []}
                isObjectOption={true}
                optionLabelKey={"account_name"}
                onChange={onChangeAutocompletedOfferDetails}
                disableClearable={true}
                addNewValue={false}
                error={unfillRequiredFields.includes("account")}
                helperText={
                  unfillRequiredFields.includes("account") &&
                  t("field_required")
                }
              />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={i18n.t("form.offer.owner")}
          required
          field={
            readOnly ?
              <TextFieldFormControl
                value={data?.owner_name}
                disabled
                suffix={
                  data?.owner ? (
                    <PersonIcon
                      style={{ cursor: "pointer" }}
                      onClick={onOpenOwnerDetailsDialog}
                    />
                  ) : null
                }
              />
              :
              <AutocompleteField
                name={"owner"}
                value={data?.owner}
                options={filteringData ? filteringData.owner : []}
                isObjectOption={true}
                optionLabelKey={"owner_short_name"}
                onChange={onChangeAutocompletedOfferDetails}
                onClear={(e) => onClear(e, "owner")}
                addNewValue={false}
                error={unfillRequiredFields.includes("owner")}
                helperText={
                  unfillRequiredFields.includes("owner") &&
                  t("field_required")
                }
              />
          }
        />
      </Grid>
      {!data?.offer_internal &&
        <Grid item xs={12}>
          <FieldWithLabel
            label={i18n.t("form.offer.receiver")}
            field={
              <TextFieldFormControl
                value={data?.receiver_name}
                disabled
                suffix={
                  data?.receiver ? (
                    <PersonIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => handleOpenTenantDetailsDialog(data.receiver, "receiver")}
                    />
                  ) : null
                }
              />
            }
          />
        </Grid>}
      {showSettlements &&
        <Grid item xs={12}>
          <SettlementFormBox
            settlements={settlements}
            onDecrementPayers={onDecrementPayers}
            readOnly={readOnly}
          />
        </Grid>}
      {!data?.offer_internal &&
        <Grid item xs={12}>
          <FieldWithLabel
            label={i18n.t("form.offer.receiver_user")}
            field={
              <TextFieldFormControl
                value={data?.receiver_user_name}
                disabled
              />
            }
          />
        </Grid>}
      <Grid item xs={12}>
        <FieldWithLabel
          label={i18n.t("form.offer.creator")}
          field={
            <TextFieldFormControl
              value={data?.creator}
              disabled
            />
          }
        />
      </Grid>
      {data?.inputer_name &&
        <Grid item xs={12}>
          <FieldWithLabel
            label={i18n.t("form.offer.inputer")}
            field={
              <TextFieldFormControl
                value={data?.inputer_name}
                disabled
              />
            }
          />
        </Grid>
      }

      <Grid item xs={12}>
        <FieldWithLabel
          label={i18n.t("form.offer.offer_subcost")}
          field={
            (readOnly && !user.is_k) ?
              <TextFieldFormControl
                value={data?.offer_subcost ? i18n.t("true") : i18n.t("false")}
                disabled
              />
              :
              <TrueFalseSelectField
                name={"offer_subcost"}
                value={data?.offer_subcost}
                readOnly={readOnly && !user.is_k}
                valuesAsBool={true}
                addEmptyOptions={false}
                onChange={onChange}
              />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={i18n.t("form.offer.id_from_bd")}
          field={
            <TextFieldFormControl
              name={"offer_subcost_bd"}
              value={data?.offer_subcost_bd}
              readOnly={readOnly && !user.is_k}
              onChange={onChange}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={i18n.t("form.offer.subtenant")}
          field={
            <TrueFalseSelectField
            name={"offer_for_subtenant"}
            value={data?.offer_for_subtenant}
            readOnly={readOnly && !user.is_k}
            onChange={onChange}
            valuesAsBool={true}
            addEmptyOptions={false}
          />
          }
        />
      </Grid>
      {!data?.offer_internal &&
        <Grid item xs={12}>
          <FieldWithLabel
            label={i18n.t("form.offer.accept_rr")}
            field={
              readOnly ?
                <TextFieldFormControl
                  value={data?.offer_accept_rr ? i18n.t("true") : i18n.t("false")}
                  disabled
                />
                :
                <TrueFalseSelectField
                  name={"offer_accept_rr"}
                  value={data?.offer_accept_rr}
                  readOnly={readOnly}
                  onChange={onChange}
                  valuesAsBool={true}
                  addEmptyOptions={false}
                />
            }
          />
        </Grid>}
      {openUserDetailsDialog && (
        <UserDetailsDialog
          open={openUserDetailsDialog}
          onClose={onCloseUserDetailsDialog}
          userId={data.receiver_user}
          title={'receiver'}
        />
      )}
      {openTenantDetailsDialog && (
        <TenantDetailsDialog
          open={openTenantDetailsDialog}
          onClose={onCloseTenantDetailsDialog}
          tenantId={selectedItemId.current}
          title={tenantDialogTitle.current}
        />
      )}
      {openOwnerDetailsDialog && (
        <OwnerDetailsDialog
          open={openOwnerDetailsDialog}
          onClose={onCloseOwnerDetailsDialog}
          ownerId={data.owner}
          title={t(`dialog.owner_details.owner_offer`)}
          readOnly={true}
        />
      )}
      {openTicketDetailsDialog && (
        <TicketDetailsDialog
          open={openTicketDetailsDialog}
          onClose={onCloseTicketDetailsDialog}
          ticketId={data.ticket_id}
          readOnly={true}
          itemType={"ticket"}
        />
      )}
    </Grid>
  );
};

OfferForm.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  readOnly: PropTypes.bool,
  filteringData: PropTypes.object,
  onChangeAutocompletedOfferDetails: PropTypes.func,
  dialogMode: PropTypes.oneOf(["newOffer", "existOffer"]),
  showSettlements: PropTypes.bool,
  unfillRequiredFields : PropTypes.array
};

OfferForm.defaultProps = {
  data: {},
  readOnly: true,
  showSettlements: false,
  unfillRequiredFields : []
};

export default OfferForm;
