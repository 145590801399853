import { useState } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import { useAsync } from "../../../hooks/useAsync";

import useEquipmentService from "../../../services/equipmentService";

import BasicDialog from "../../base/BasicDialog";

import EquipmentTable from "../../table/EquipmentTable/EquipmentTable";
import { EQUIPMENT_BMS_ORDER_TABLE_CONFIG } from "./TableConfig";

import useDialogWithId from "../../../hooks/useDialogWithId";
import LoaderWrapper from "../../wrapper/LoaderWrapper";

import EquipmentBmsOrderDetailsDialog from "../EquipmentBmsOrderDetailsDialog/EquipmentBmsOrderDetailsDialog";

const EquipmentBmsOrdersDialog = (props) => {
  const { t } = useTranslation();

  const [existChanges, setExistChanges] = useState(false);

  const [
    openEquipmentBmsDetailsDialog,
    equipmentOrderId,
    onOpenEquipmentBmsDetailsDialog,
    onCloseEquipmentBmsDetailsDialog,
  ] = useDialogWithId();

  const { getEquipmentBmsDataForOrder } = useEquipmentService();

  const equipmentBmsData = useAsync(
    () => getEquipmentBmsDataForOrder(props.orderId),
    []
  );

  const onSumbitCallbackEquipmentBmsDetailsDialog = (existChanges) => {
    if (existChanges) {
      setExistChanges(true);
      equipmentBmsData.refetch();
    }
  };

  const handleClose = () => {
    if (existChanges && props.onSubmit) {
      props.onSubmit();
    }
    props.onClose();
  };

  const getDialogContent = () => {
    if (equipmentBmsData.loading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item xs={12}>
          <EquipmentTable
            data={equipmentBmsData.value}
            showCheckbox={false}
            showCleanFilterIcon={false}
            countRecords={equipmentBmsData.value.length}
            style={{ maxHeight: "75vh" }}
            tableConfig={EQUIPMENT_BMS_ORDER_TABLE_CONFIG}
            hiddenColumns={[]}
            columnsOrders={[]}
            showExportToFileButton={false}
            withPagination={false}
            showFilters={false}
            onClickMultipleLocationAlert={onOpenEquipmentBmsDetailsDialog}
          />
        </Grid>

        {openEquipmentBmsDetailsDialog && (
          <EquipmentBmsOrderDetailsDialog
            open={openEquipmentBmsDetailsDialog}
            onClose={onCloseEquipmentBmsDetailsDialog}
            equipmentOrderId={equipmentOrderId}
            onSubmitCallback={onSumbitCallbackEquipmentBmsDetailsDialog}
          />
        )}
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={handleClose}
      titleAlign="center"
      title={t("dialog.equipment.equipment_bms_orders")}
      maxWidth={"md"}
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

EquipmentBmsOrdersDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  orderId: PropTypes.string,
};

EquipmentBmsOrdersDialog.defaultProps = {};

export default EquipmentBmsOrdersDialog;
