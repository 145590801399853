import i18n from "../../i18n";




export const ITEMN_LINKED_ELEMENTS_TABLE_FIELDS_CONFIG = [

  {
    name: "name",
    getValueCallback: (rowData) => rowData.name,
    label: i18n.t("table.item_linked_elements_table.name"),
  },
  {
    name: "subcontractor",
    getValueCallback: (rowData) => rowData.subcontractor,
    label: i18n.t("table.item_linked_elements_table.subcontractor_producer"),

  },
];
