import React, { useState } from "react";

import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import Divider from "@mui/material/Divider";

import ItemDetailsForm from "../../UniversalDetailsDrawerForms/ItemDetailsForm";
import PersonsForm from "../../UniversalDetailsDrawerForms/PersonsForm";
import LocalizationForm from "../../UniversalDetailsDrawerForms/LocalizationForm";
import CommunicationLogForm from "../../UniversalDetailsDrawerForms/CommunicationLogForm";
import ActionIconsForm from "../../UniversalDetailsDrawerForms/ActionIconsForm";

import { useForm } from "../../../../../hooks/useForm";
import { useTranslation } from "react-i18next";
import TaskDateTimeForm from "../../UniversalDetailsDrawerForms/TaskDatetimeForm";
import { useAuth } from "../../../../../context/auth";
import hasUserPermission, {
  IS_KT,
} from "../../../../../helpers/userPermissions";

const TaskDrawerContent = (props) => {
  const {
    formValue,
    setFormValue,
    onChangeWithCallback,
    onChangeAutocompleteFieldWithObjectOptionsAndCallback,
    onChangeDateWithCallback,
  } = useForm(props.itemData);

  const { user } = useAuth();
  const isKt = hasUserPermission([IS_KT], user);

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeFieldValue = (name, value) => {
    setIsLoading(true);
    const oldValue = formValue[name];
    props
      .onUpdateDataPromise(formValue.id, { [name]: value })
      .then(() => {
        props.onRefetchData();
      })
      .catch((error) => {
        setFormValue((prev) => ({ ...prev, [name]: oldValue }));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangeLocations = (locationData, onCloseDialog) => {
    const oldLocationData = formValue.locations;
    setIsLoading(true);
    props
      .onUpdateDataPromise(formValue.id, { task_object: locationData[0] })
      .then((data) => {
        setFormValue((prev) => ({ ...prev, locations: data.locations }));
        onCloseDialog();
        props.onRefetchData();
      })
      .catch((error) => {
        setFormValue((prev) => ({ ...prev, locations: oldLocationData }));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangeStatus = (statusData, onCloseDialog) => {
    const oldStatus = formValue.status;
    setIsLoading(true);
    props
      .onUpdateDataPromise(formValue.id, { status_data: statusData })
      .then((data) => {
        setFormValue((prev) => ({
          ...prev,
          status: data.status,
        }));
        onCloseDialog();
        props.onRefetchData();
      })
      .catch((error) => {
        setFormValue((prev) => ({ ...prev, status: oldStatus }));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const isTaskClosed = props.itemData.is_closed;
  const isTaskFinallyClosed = props.itemData.is_finally_closed;

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      paddingX={1}
      rowGap={1}
      marginTop={1}
      marginBottom={3}
    >
      <Grid item xs={12}>
        <PersonsForm
          ownerName={formValue.ticket_owner?.full_name}
          ownerId={formValue.ticket_owner?.id}
          omName={formValue.ticket_om?.full_name}
          omId={formValue.ticket_om?.id}
          tenantName={formValue.tenant?.tenant_short_name}
          tenantId={formValue.tenant?.id}
          reposoniblePersonId={formValue.task_kt}
          onChangeResponsiblePerson={onChangeAutocompleteFieldWithObjectOptionsAndCallback(
            handleChangeFieldValue
          )}
          isResponsiblePersonAllowedToChange={
            !props.readOnly && !isTaskClosed && isKt
          }
          reposoniblePersonOptions={props.filteringData.task_kt}
          taskTId={formValue.task_t}
          isTaskTAllowedToChange={!props.readOnly && !isTaskClosed && isKt}
          taskTIOptions={props.filteringData.task_t}
          onChangeTaskT={onChangeAutocompleteFieldWithObjectOptionsAndCallback(
            handleChangeFieldValue
          )}
          itemType={props.itemType}
          skillId={formValue.skill}
          skillOptions={props.filteringData.skill}
          onChangeSkill={onChangeAutocompleteFieldWithObjectOptionsAndCallback(
            handleChangeFieldValue
          )}
          isSkillAllowedToChange={!props.readOnly && !isTaskClosed && isKt}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <LocalizationForm
          itemForChangeLocalizationId={formValue.id}
          isAllowedToChangeLocalization={!props.readOnly && !isTaskClosed}
          localizationData={formValue.locations}
          isMultipleLocalization={false}
          onChangeLocalization={handleChangeLocations}
          allowToAddMultipleLocations={false}
        />
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <ItemDetailsForm
          showIsPaid={false}
          statusName={formValue.status}
          substatusName={formValue.substatus}
          categoryId={formValue.ticket_category}
          isCategoryAllowedToChange={false}
          categoryOptions={props.filteringData.ticket_category}
          onChangeCategory={onChangeAutocompleteFieldWithObjectOptionsAndCallback(
            handleChangeFieldValue
          )}
          priorityId={formValue.order_priority}
          priorityOptions={props.filteringData.order_priority}
          showIsDeadLineConfirmed={true}
          isDeadlineConfirmed={formValue.is_deadline_confirmed}
          isAllowedToChangeDeadlineConfirmed={false}
          itemType={props.itemType}
          isPriorityAllowedToChange={false}
          itemName={formValue.task_name}
          onChangeTextField={onChangeWithCallback(handleChangeFieldValue)}
          isAllowedToChangeItemName={!props.readOnly && !isTaskClosed}
          ticketDescCreator={formValue.ticket_desc_creator}
          orderDescCreator={formValue.order_desc_creator}
          taskDescCreator={formValue.task_desc_creator}
          isAllowedToChangeTaskDescCreator={
            !props.readOnly && !isTaskClosed && isKt
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <TaskDateTimeForm
          formValue={formValue}
          onChange={onChangeWithCallback(handleChangeFieldValue)}
          readOnly={props.readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <CommunicationLogForm
        lastMessages={formValue.order_last_messages}
        itemId={formValue.order_id}
        itemType={"order"}
        showExtendedAddEnclosureDialog={true}
        onOpenDialogParamsAction={props.onOpenDialogParamsAction}
        onRefetchData={props.onRefetchData}
      />
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <ActionIconsForm
          itemId={formValue.order_id}
          itemData={formValue}
          isItemClosed={isTaskClosed}
          isItemFinallyClosed={isTaskFinallyClosed}
          isAllowedToChangeStatusManualy={
            formValue.is_allowed_to_manualy_change_status
          }
          onChangeStatus={handleChangeStatus}
          isLoading={isLoading}
          enclosuresCount={formValue.enclosures_count}
          availableActionButtons={props.availableActionButtons}
          itemType={"task"}
          onRefetchData={props.onRefetchData}
          onRefetchTableData={props.onRefetchTableData}
        />
      </Grid>
    </Grid>
  );
};

TaskDrawerContent.propTypes = {
  itemData: PropTypes.object,
  itemType: PropTypes.oneOf(["task"]),
  onRefetchData: PropTypes.func,
  readOnly: PropTypes.bool,
  availableActionButtons: PropTypes.array,
  onRefetchTableData: PropTypes.func,
};

TaskDrawerContent.defaultProps = {
  itemType: "task",
  isLoading: false,
  readOnly: false,
};

export default TaskDrawerContent;
