import {
  hideParameterValueDetailsUrl,
  itemDataUrl,
  itemLocationRecordUrl,
  itemSelectListUrl,
  itemStructureUrl,
  itemUrl,
  parameterDataUrl,
  parameterValueDataUrl,
  parameterValueDetailsUrl,
  parametersSelectListUrl,
  parametersSelectListForMetersUrl,
  partParameterDataUrl,
  equipmentItemParametersUrl,
  parameterListForItemUrl,
  itemNextItemNumberUrl,
  itemReferencyUrl,
  itemShortDataUrl,
  itemReferenceDataUrl,
  itemFilteringDataUrl,
  itemDataWithElementsUrl,
} from "../helpers/apiUrls";
import { makeRequest } from "../helpers/makeRequest";
import { isEmptyObject, prepareUrlWithQueryParams } from "../helpers/methods";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

export default function useItemService() {
  const axiosPrivate = useAxiosPrivate();

  const getItems = (searchParams = undefined) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(itemUrl, searchParams)
    );
  };
  const getItemsSelectList = (searchParams = undefined) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(itemSelectListUrl, searchParams)
    );
  };

  const getRelatedItems = (searchParams = undefined) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(itemReferencyUrl, searchParams)
    );
  };

  const getEmptyItemLevel = (parent = null, level = 1) => {
    return {
      parent: parent,
      level: level,
    };
  };

  const getItemRecordById = (itemId) => {
    return makeRequest(axiosPrivate, itemLocationRecordUrl(itemId));
  };

  const getEmptyItemElement = (parent = null, level = 1) => {
    return [getEmptyItemLevel(parent, level)];
  };

  const getParametersList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(parametersSelectListUrl, searchParams)
    );
  };

  const getParametersListForMeters = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(parametersSelectListForMetersUrl, searchParams)
    );
  };

  const getPartParametersList = (partId) => {
    return makeRequest(axiosPrivate, partParameterDataUrl(partId));
  };

  const getEquipmentParametersList = (equipmentId) => {
    return makeRequest(axiosPrivate, equipmentItemParametersUrl(equipmentId));
  };

  const createParameter = (parameterData) => {
    return makeRequest(axiosPrivate, parameterDataUrl, {
      method: "POST",
      data: parameterData,
    });
  };

  const createParameterValue = (parameterData) => {
    return makeRequest(axiosPrivate, parameterValueDataUrl, {
      method: "POST",
      data: parameterData,
    });
  };

  const updateParameterValue = (parameterValueId, parameterData) => {
    return makeRequest(
      axiosPrivate,
      parameterValueDetailsUrl(parameterValueId),
      {
        method: "PATCH",
        data: parameterData,
      }
    );
  };

  const removeParameterValue = (parameterValueId, parameterData) => {
    return makeRequest(
      axiosPrivate,
      hideParameterValueDetailsUrl(parameterValueId),
      {
        method: "PATCH",
        data: parameterData,
      }
    );
  };

  const prepareItemDataFromBackendForItemRecordComponent = (
    item,
    addEmptyLevel = true
  ) => {
    if (!item || isEmptyObject(item)) return getEmptyItemElement();

    let preparedItem = item;
    preparedItem = Object.values(item)
      .filter((level) => level !== null)
      .map((level, idx) => ({
        id: level.id,
        parent: level.item_parent,
        level: idx,
      }));

    if (addEmptyLevel && preparedItem.length) {
      preparedItem = [
        ...preparedItem,
        getEmptyItemLevel(
          preparedItem[preparedItem.length - 1].id,
          preparedItem.length
        ),
      ];
    }

    return preparedItem;
  };

  const getBranchStructure = (searchParams = {}) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(itemStructureUrl, searchParams)
    );
  };

  const getItemData = (itemId, searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(itemDataUrl(itemId), searchParams)
    );
  };  
  
  const getItemDataWitElements = (itemId) => {
    return makeRequest(
      axiosPrivate,
      itemDataWithElementsUrl(itemId)
    );
  };

  const getNextItemNumber = (itemId) => {
    return makeRequest(
      axiosPrivate,
      itemNextItemNumberUrl(itemId), 
    );
  };
  const getItemShortData = (itemId) => {
    return makeRequest(
      axiosPrivate,
      itemShortDataUrl(itemId), 
    );
  };
  const createItem = (itemData) => {
    return makeRequest(axiosPrivate, itemUrl, {
      method: "POST",
      data: itemData,
    });
  };

  const updateItem = (itemId, itemData) => {
    return makeRequest(axiosPrivate, itemDataUrl(itemId), {
      method: "PATCH",
      data: itemData,
    });
  };

  const removeItem = (itemId) => {
    return makeRequest(axiosPrivate, itemDataUrl(itemId), {
      method: "DELETE",
    });
  };

  const getParametersListForItem = (itemId) => {
    return makeRequest(axiosPrivate, parameterListForItemUrl(itemId));
  };

  const createItemReferenceValue = (referenceData) => {
    return makeRequest(axiosPrivate, itemReferencyUrl, {
      method: "POST",
      data: referenceData,
    });
  };

  
  const removeItemReference = (itemReferenceId) => {
    return makeRequest(axiosPrivate, itemReferenceDataUrl(itemReferenceId), {
      method: "DELETE",
    });
  };

  const getItemsFilteringData = () => {
    return makeRequest(axiosPrivate, itemFilteringDataUrl);
  };



  return {
    getItems,
    getItemsSelectList,
    getEmptyItemElement,
    getParametersList,
    getParametersListForMeters,
    createParameter,
    getPartParametersList,
    createParameterValue,
    updateParameterValue,
    removeParameterValue,
    prepareItemDataFromBackendForItemRecordComponent,
    getItemRecordById,
    getBranchStructure,
    getItemData,
    getItemDataWitElements,
    getEquipmentParametersList,
    createItem,
    updateItem,
    removeItem,
    getParametersListForItem,
    getNextItemNumber,
    getRelatedItems,
    getItemShortData,
    createItemReferenceValue,
    removeItemReference,
    getItemsFilteringData
  };
}
