import React, { useCallback, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Grid } from "@mui/material";
import './PurchasingTree.css'
import StyledItemTree from "./StyledItemTree";


const PurchasingTree = React.memo((props) => {
    const [expanded, setExpanded] = useState([])    
    const [selected, setSelected] = useState([])


    useEffect(() => {
        if (props.branchStucture !== undefined) {
        
            var tempAllBranchId = []
            for (const branch of props.branchStucture) {
                addBranchChildsIdToList(branch, tempAllBranchId)
            }
            setSelected([])
        }
    }, [props.branchStucture])


    useEffect(() => {
        if (props.expandedList !== undefined) {
            var expandedList = []
            for(const element of props.expandedList){
                expandedList.push(element.toString())
            }
           setExpanded(expandedList)
        }
    }, [props.expandedList])

    const handleToggle  = useCallback((event, nodeIds) => {
        setExpanded(nodeIds);
    }, []);

    const handleSelect  = useCallback((event, nodeIds) => {
        setSelected(nodeIds);
    }, []);


    const handleClickEdit  = useCallback((event, branchId) => {
        event.stopPropagation();
        props.onClickEdit(branchId)
    }, [props.onClickEdit])

    const handleClickAdd  = useCallback((event, branchId) => {
        event.stopPropagation();
        props.onClickAdd(branchId)
    }, [props.onClickAdd])

    const handleClickRemove = useCallback((event, itemId) => {
        event.stopPropagation();
        props.onClickRemove(itemId)
    }, [props.onClickRemove])

    const handleClickRelated = useCallback((event, itemId) => {
        event.stopPropagation();
        props.onClickRelatedItems(itemId)
    }, [props.onClickRelatedItems])
    

    const addBranchChildsIdToList = useCallback((branch, branchIdList) => {
        branchIdList.push(branch.id.toString())
        for (var child of branch.branch_childs) {
            addBranchChildsIdToList(child, branchIdList)
        }
    }, [])

    const onRemoveChildExpand = useCallback((itemId) =>{
        setExpanded((exp) => exp.filter((id) =>id !== itemId))
    }, [])



    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item xs={12}>
                <TreeView
                    aria-label="controlled"
                    defaultCollapseIcon={<ExpandMoreIcon style={{ paddingLeft: "5px" }} />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    expanded={expanded}
                    onNodeToggle={handleToggle}
                    onNodeSelect={handleSelect}
                    multiSelect
                >
                    {props.branchStucture.map((branch) => (
                        <StyledItemTree 
                        branch = {branch}
                        selectedIds ={selected}
                        handleClickEdit = {handleClickEdit}
                        handleClickAdd = {handleClickAdd}
                        handleClickRemove={handleClickRemove}
                        handleClickRelatedItems = {handleClickRelated}
                        hadleClickItem= {props.hadleClickItem}
                        expanded ={expanded} 
                        onRemoveChildExpand= {onRemoveChildExpand} 
                        hasEditPermission={props.hasEditPermission}
                        selectedItemId = {props.selectedItemId}
                        />
                    ))}
                </TreeView>
            </Grid>
        </Grid>
  );
});

export default PurchasingTree;

PurchasingTree.propTypes = {
    branchStucture: PropTypes.array,
    onClickAdd: PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickRelatedItems : PropTypes.func,
    expandedList: PropTypes.array
}

PurchasingTree.defaultProps = {

}
