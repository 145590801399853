import { useCallback, useEffect, useMemo, useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import { NAVIGATION_DRAWER_TYPE_WAREHOUSES } from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import PropTypes from "prop-types";
import useItemService from "../../services/itemService";
import { useAsync } from "../../hooks/useAsync";
import { useSnackbarAlert } from "../../context/snackbarAlert";
import UniversalToolBarWithDialogs from "../../components/bar/UniversalToolBarWithDialogs";
import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";
import { ITEM_REGISTRY_TABLE_FIELDS_CONFIG, ITEM_REGISTRY_TABLE_NAME } from "./PageTablesConfig";
import TableService from "../../services/tableService";
import useLocalData from "../../hooks/useLocalData";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import FilterTable from "../../components/table/FilterTable";
import { itemExportXslxUrl } from "../../helpers/apiUrls";
import TextFieldFormControl from "../../components/field/TextFieldFormControl";
import { useForm } from "../../hooks/useForm";
import { isEmptyValue } from "../../helpers/methods";
import useDebounce from "../../hooks/useDebouce";
import PurchasingTreeItemDialog from "../../components/dialog/PurchasingTreeItemDialog";
import useDialogWithId from "../../hooks/useDialogWithId";
import { MANAGE_ITEMS_TREE_PERMISSION } from "../../helpers/constants";
import useCheckPermission from "../../hooks/usePermission";


const TABLE_CONFIGS = [
  { name: ITEM_REGISTRY_TABLE_NAME, config: ITEM_REGISTRY_TABLE_FIELDS_CONFIG },
];

export default function ItemRegistryPage(props) {
  const { pageName } = props;

  const { t } = useTranslation();
  const [hasEditPermission] = useCheckPermission(MANAGE_ITEMS_TREE_PERMISSION)


  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState([]);
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState([]);

  const [
    openItemTreeBranchDialog,
    selectedItemId,
    onOpenItemTreeBranchDialog,
    onCloseItemTreeBranchDialog
  ] = useDialogWithId();


  const {
    page: itemPage,
    pageSize: itemPageSize,
    handleChangePageWithSearchParams: handleChangeItemPage,
    handleChangePageSizeWithSearchParams: handleChangeItemPageSize,
    resetPageNumber,
    searchParams,
    setSearchParams
  } = usePaginationWithSearchParams(undefined, undefined, 100);

  const {
    formValue: extraSearchField,
    setFormValue: setExtraSearchField,
    onChange
  } = useForm();


  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('page_size', itemPageSize || 100);
    setSearchParams(newSearchParams)
  }, [searchParams, props.itemType]);

  const onClickSearchEveryWhere = useCallback(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (isEmptyValue(extraSearchField?.search_everywhere)){
      newSearchParams.delete('search_everywhere')
    }else{
      newSearchParams.set('search_everywhere', extraSearchField?.search_everywhere);
    }
    
    newSearchParams.set('page', 1);
    setSearchParams(newSearchParams)
  }, [extraSearchField])

  const getDebounceDeps = () => {
    return Array.isArray(extraSearchField) ? extraSearchField.length : extraSearchField;
  };

  useDebounce(onClickSearchEveryWhere, 1000, [getDebounceDeps()]);

  const {
    getItems,
    getItemsFilteringData
  } = useItemService();

  const {
    data,
    dataCount: countRecords,
    dataLoading,
    dataRefetch
  } = useLocalData(getItems, searchParams, true)

  const filteringData = useAsync(getItemsFilteringData)

  const onClickEdit = useCallback((e, itemId) => {
    e.stopPropagation();
    onOpenItemTreeBranchDialog(itemId)
  }, [])

  const itemsData = React.useMemo(
    () =>
      TableService.getPreparedDataForCollapsibleTable(
        ITEM_REGISTRY_TABLE_FIELDS_CONFIG,
        data ? data : [],
        hiddenColumnsForTables ? hiddenColumnsForTables[ITEM_REGISTRY_TABLE_NAME] : [],
        columnsOrdersForTables ? columnsOrdersForTables[ITEM_REGISTRY_TABLE_NAME] : [],
        onClickEdit,
        hasEditPermission
      ),
    [ITEM_REGISTRY_TABLE_FIELDS_CONFIG,
      data,
      hiddenColumnsForTables,
      columnsOrdersForTables,
      onClickEdit,
      hasEditPermission]
  );

  const itemsExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      ITEM_REGISTRY_TABLE_FIELDS_CONFIG,
      hiddenColumnsForTables ? hiddenColumnsForTables[ITEM_REGISTRY_TABLE_NAME] : [],
      columnsOrdersForTables ? columnsOrdersForTables[ITEM_REGISTRY_TABLE_NAME] : []
    );
  }, [
    ITEM_REGISTRY_TABLE_FIELDS_CONFIG,
    hiddenColumnsForTables,
    columnsOrdersForTables
  ]);

  const onClear = useCallback(() => {
    setSearchParams({})
    setExtraSearchField({})
  }, [])

  const handleCloseItemBranchDialog = (dataAreChanged = false) => {
    onCloseItemTreeBranchDialog()
    if (dataAreChanged) {
      dataRefetch()
    }
  }

  const isLoading = data === undefined || filteringData.loading

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_WAREHOUSES}>
      <DefaultPageWrapper titleKey={pageName}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          columnSpacing={2}
          rowSpacing={2}
          style={{

            overflowX: "hidden"
          }}
        >
          <Grid item xs={10} marginY={1}>
            <UniversalToolBarWithDialogs
              pageName={pageName}
              tableConfig={TABLE_CONFIGS}
              filteringData={filteringData ? filteringData : []}
              refreshTable={dataRefetch}
              resetPageNumber={resetPageNumber}
              hiddenColumnsForTables={hiddenColumnsForTables}
              setHiddenColumnsForTables={setHiddenColumnsForTables}
              columnsOrdersForTables={columnsOrdersForTables}
              setColumnsOrdersForTables={setColumnsOrdersForTables}
              showMassActionButton={false}
              showOpenCloseFilterButton={false}
              showCleanfiltersButton={true}
              showMyFiltersButton={true}
              showSearchButton={false}
              cleanFiltersFn={onClear}
              showCreateButton={false}
              mdGridButton={2.75}

            />
          </Grid>

          <Grid item xs={2} marginY={1}>

            <TextFieldFormControl
              name="search_everywhere"
              label={t("page.items_registry_page.search_everywhere")}
              value={extraSearchField?.search_everywhere}
              onChange={onChange}
              showClearIcon = {true}
            />

          </Grid>

          <LoaderWrapper showLoader={isLoading}>
            <Grid item xs={12}>
              <FilterTable
                data={itemsData}
                onClickRow={() => undefined}
                countRecords={countRecords}
                resetPageNumber={resetPageNumber}
                page={itemPage}
                onPageChange={handleChangeItemPage}
                rowsPerPage={itemPageSize}
                onRowsPerPageChange={handleChangeItemPageSize}
                style={{ maxHeight: "70vh" }}
                showCleanFilterIcon={true}
                showContextMenu={false}
                showExportToFileButton={true}
                exportToFileUrl={itemExportXslxUrl}
                exportToFileSearchParams={searchParams}
                exportToFileHeaders={itemsExportHeaders}
                exportToFileFileName={`${t("page.items_registry_page.items").replace(" ", "_")}.xlsx`}
                headersConfig={TableService.getHeadersConfigForCollapsibleTable(
                  ITEM_REGISTRY_TABLE_FIELDS_CONFIG,
                  filteringData ? filteringData : [],
                  hiddenColumnsForTables ? hiddenColumnsForTables[ITEM_REGISTRY_TABLE_NAME] : [],
                  columnsOrdersForTables ? columnsOrdersForTables[ITEM_REGISTRY_TABLE_NAME] : []
                )}
                showDetailsIcon={false}
                showCounterRecords={false}
                showCheckbox={false}
              />
            </Grid>
          </LoaderWrapper>
        </Grid>
            {
                  openItemTreeBranchDialog &&
                  <PurchasingTreeItemDialog
                    branchId ={selectedItemId}
                    open={openItemTreeBranchDialog}
                    onClose={handleCloseItemBranchDialog}
                    showExtendForm = {true}
                  />
                }
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
ItemRegistryPage.propTypes = {
  style: PropTypes.object,
};

ItemRegistryPage.defaultProps = {

};


