import { Grid, Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import SelectField from "../../field/SelectField/";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useState, useEffect, useCallback } from "react";
import { getExposureTypesList, isEmptyValue } from "../../../helpers/methods";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import {
  centerVericalAlignIconStyle,
  overflowButtonStyle,
} from "../../../helpers/styles";

const errorStyle = {
  "& label.Mui-focused": {
    color: "red",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "red",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "red",
    },
    "&:hover fieldset": {
      borderColor: "red",
    },
    "&.Mui-focused fieldset": {
      borderColor: "red",
    },
  },
};

function MessageInputForm(props) {
  const textLimit = 5000;

  const snackbarAlert = useSnackbarAlert();

  const [helperTextStyle, setHelperTextStyle] = useState({ alignSelf: "end" });

  const [inputTextStyle, setInputTextStyle] = useState({});

  const [formValue, setFormValue] = useState({ ...props.inputMessage });

  const [disableSendButton, setDisableSendButton] = useState(
    props.errorText !== undefined
  );

  const handleFormChange = (e) => {
    let value = e.target.value;
    if (e.target.name === "message_content") {
      value = checkInputLengthAndCutAfterCharsLimit(value);
    }
    setFormValue((prev) => ({ ...prev, [e.target.name]: value }));
    setDisableSendButton(false);
  };

  const { t } = useTranslation();

  const handleSubmit = () => {
    setDisableSendButton(true);
    let messageContent = formValue.message_content.replace(/\s/g, "");
    if (!isEmptyValue(messageContent)) {
      props.onSubmit(formValue);
    } else {
      snackbarAlert.openWarningSnackbarAlert(
        t("snackbar_alert.input_message_content_to_send")
      );
    }
  };

  const onClickEnter = useCallback(
    (e) => {
      if (e.key === "Enter") {
        handleSubmit();
      }
    },
    [formValue]
  );

  useEffect(() => {
    if (props.isCorrectedSend) {
      var tempMessage = { ...props.inputMessage };
      setFormValue({ ...tempMessage });
      setDisableSendButton(false);
    }
  }, [props.isCorrectedSend]);

  const checkInputLengthAndCutAfterCharsLimit = (textMessage) => {
    if (textMessage.length <= textLimit) {
      setHelperTextStyle({ alignSelf: "end" });
      setInputTextStyle({});
      return textMessage;
    }
    let tempHelperTextStyle = { ...helperTextStyle };
    tempHelperTextStyle["color"] = "red";
    setHelperTextStyle(tempHelperTextStyle);
    setInputTextStyle(errorStyle);
    return textMessage.substring(0, textLimit);
  };

  return (
    <Grid
      container
      spacing={1}
      direction="row"
      alignItems="center"
      justifyContent={props.gridJustifyContent}
    >
      <Grid item xs={12} sm={6}>
        <TextFieldFormControl
          name={"message_content"}
          value={formValue.message_content}
          maxNumberOfCharacters={5000}
          multiline={true}
          label={props.label ? props.label : ""}
          rows={2}
          helperText={
            props.errorText ||
            `${t("form.message_input_form.max_amount_chars")} ${textLimit}`
          }
          helperTextStyle={helperTextStyle}
          placeholder={t("form.message_input_form.new_message")}
          onChange={handleFormChange}
          sx={inputTextStyle}
          isInvalid={props.errorText !== undefined}

          // onKeyDown = {onClickEnter}
        />
      </Grid>
      {props.showMessageType && (
        <Grid item xs={12} sm={2}>
          <SelectField
            name="message_type"
            label={t("form.message_input_form.message_type")}
            value={formValue.message_type}
            options={getExposureTypesList()}
            optionLabel={"name"}
            optionKey={"key"}
            addNewValue={false}
            onChange={handleFormChange}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={props.enterButtonGridSmGrid}>
        <Button
          variant="contained"
          color="primary"
          disabled={disableSendButton}
          onClick={() => handleSubmit()}
        >
          <SendIcon />
        </Button>
      </Grid>
      {props.showAttachmentsButton && (
        <Grid item xs={12} sm={props.showOnlyAttachmentsIcon ? 1.5 : 3}>
          <Button
            variant="contained"
            color="primary"
            onClick={props.onOpenEnclosuresDialog}
          >
            {props.showOnlyAttachmentsIcon
              ? ""
              : t("form.message_input_form.enclosures")}
            <AttachmentIcon
              sx={{
                marginLeft: props.showOnlyAttachmentsIcon ? "0px" : "10px",
              }}
            />
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

MessageInputForm.propTypes = {
  showMessageType: PropTypes.bool,
  onOpenEnclosuresDialog: PropTypes.func,
  showAttachmentsButton: PropTypes.bool,
  showOnlyAttachmentsIcon: PropTypes.bool,
  gridJustifyContent: PropTypes.string,
  enterButtonGridSmGrid: PropTypes.number,
  errorText: PropTypes.string,
};

MessageInputForm.defaultProps = {
  showMessageType: false,
  showAttachmentsButton: true,
  showOnlyAttachmentsIcon: false,
  gridJustifyContent: "center",
  enterButtonGridSmGrid: 1,
};

export default MessageInputForm;
