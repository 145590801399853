import { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useAsync } from "../../../hooks/useAsync";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import AutocompleteField from "../../field/AutocompleteField";
import { Button, Grid } from "@mui/material";
import useItemService from "../../../services/itemService";
import TextFieldFormControl from "../../field/TextFieldFormControl";

const SelectReferenceItemDialog = (props) => {
  const { t } = useTranslation();

  const [selectedReferenceItem, setSelectedReferenceItem] = useState();
  const { getItemsSelectList } = useItemService();

  const itemSelectListData = useAsync(() => getItemsSelectList(props.searchParams), [props.searchParams]);

  const onChangeAutocompleteFieldWithObjectOptions = (
    e,
    value_object,
    value_key,
    state_value_name
  ) => {
    const newFormValue = Array.isArray(value_object)
      ? value_object.map((option) =>
        typeof option === "object" ? option[value_key] : option
      )
      : value_object[value_key];
    setSelectedReferenceItem(newFormValue);
  };


  return (
    <BasicDialog
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
      titleAlign="center"
      maxWidth="xs"
      title={t("dialog.select_refernce_item_dialog.reference_items")}
    >
      <LoaderWrapper showLoader={itemSelectListData.loading} >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item xs={12}>
            <TextFieldFormControl
              label={t("dialog.select_refernce_item_dialog.item")}
              value={props.item1Name}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <AutocompleteField
              name="item_2"
              label={t("dialog.select_refernce_item_dialog.select_reference")}
              value={selectedReferenceItem}
              options={itemSelectListData ? itemSelectListData.value : []}
              isObjectOption={true}
              optionLabelKey={"indexed_item_name"}
              addNewValue={false}
              onChange={onChangeAutocompleteFieldWithObjectOptions}
              required
            />
          </Grid>
          <Button
            sx={{ marginTop: "20px" }}
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => props.onSubmit(selectedReferenceItem)}
            disabled={!selectedReferenceItem}
          >
            {t("confirm")}
          </Button>
          </Grid>
      </LoaderWrapper>
    </BasicDialog >
  );
};

SelectReferenceItemDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  item1Name: PropTypes.string,
  searchParams: PropTypes.object,
  onSubmit: PropTypes.func
};

SelectReferenceItemDialog.defaultProps = {
  item1Name: ""
};

export default SelectReferenceItemDialog;
