import { useState, useEffect, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Button } from "@mui/material";
import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import TableService from "../../services/tableService";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import {
  ICO_REGISTRY_TABLE_FIELDS_CONFIG,
  ICO_REGISTRY_TABLE_NAME,
} from "./PageTablesConfig";
import AgreementTable from "../../components/table/AgreementTable";
import { exportAgreementsToFileUrl, exportIcoToFileUrl } from "../../helpers/apiUrls";
import RelatedAgreementsBox from "../../components/box/RelatedAgreementsBox/RelatedAgreementsBox";
import FileUploadList from "../../components/other/FileUploadList";
import BaseBox from "../../components/base/BaseBox/baseBox";
import { NAVIGATION_DRAWER_TYPE_ICO } from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import useDocsData from "../../hooks/useDocsData";
import EditIcon from '@mui/icons-material/Edit';
import useIcoService from "../../services/icoService";
import IcoConfiguratorDialog from "../../components/dialog/IcoConfiguratorDialog";
import { DOCS_RESTRICTED_OWNER, DOCS_RESTRICTED_USER } from "../../helpers/constants";
import { centerVericalAlignIconStyle, overflowButtonStyle } from "../../helpers/styles";
import PreviewIcon from '@mui/icons-material/Preview';
import useDialogWithIcoData from "../../hooks/useDialogWithIcoData";
import UniversalToolBarWithDialogs from "../../components/bar/UniversalToolBarWithDialogs";

const TABLE_CONFIGS = [
  { name: ICO_REGISTRY_TABLE_NAME, config: ICO_REGISTRY_TABLE_FIELDS_CONFIG },
];

export default function IcoRegistryPage(props) {
  const { pageName } = props;

  const avaibleRestrictedAreas = [DOCS_RESTRICTED_OWNER, DOCS_RESTRICTED_USER]

  const { t } = useTranslation();
  // const [hasEditPermission] = useCheckPermission(EDIT_AGREEMENTS_PERMISSION)
  const [hasEditPermission] = [true]
  const [icoRegistryTableConfigLocal, setIcoRegistryTableConfigLocal] = useState(
    ICO_REGISTRY_TABLE_FIELDS_CONFIG
  );
  const [icosDataLocal, setIcosDataLocal] = useState([]);
  const [countIcoRecord, setCountIcoRecord] = useState();
  const [selectedIcoId, setSelectedIcoId] = useState();
  const [selectedIco, setSelectedIco] = useState();
  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState([]);
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();
  const [relatedIcosLocal, setRelatedIcosLocal] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [isMultipleChecked, setMultipleChecked] = useState(false);
  const [singleIcoChecked, setSingleIcoChecked] = useState(false);
  const [enabledConsolidationButton, setEnabledConsolidationButton] = useState(false);


  const [
    openAgreementDialog,
    dialogId,
    dialogParentId,
    dialogCopyId,
    dialogMode,
    consolidationList,
    onOpenIcoDialog,
    onCloseIcoDialog] =
    useDialogWithIcoData();

    
  const {
    resetPageNumber,
    searchParams
  } = usePaginationWithSearchParams();

  const {
    docs,
    isDocsLoading,
    updateDocs,
    hideDocs,
    onDownloadDoc,
    onPreviewDoc,
    refetchDocs,
  } = useDocsData(selectedIcoId, "ico");

 
  const { getIcoRegistryList, getIcoFilteringData, getRelatedIcos } = useIcoService();

  const icosData = useAsync(
    () => getIcoRegistryList(searchParams),
    [searchParams]
  );

  const icoFilteringData = useAsync(getIcoFilteringData);
  const getRelatedIcosFn = useAsyncFn(getRelatedIcos);


  const onRelatedIcos = (icoId) => {
    getRelatedIcosFn.execute(icoId).then((data) => {
      setRelatedIcosLocal(data);
    });
  };


  useEffect(() => {
    if (icosData.loading) {
      return;
    }
    setIcosDataLocal(icosData.value.results);
    setCountIcoRecord(icosData.value.count);
  }, [icosData.loading]);


  useEffect(() => {
    if (selectedIcoId) {
      handleClickCheck(selectedIcoId, true)
      setSelectedIco(icosDataLocal.find((ico) => ico.id === selectedIcoId))
    }
  }, [selectedIcoId]);

  useEffect(() => {
    if (isMultipleChecked) {
      let selectedRows = icosDataLocal.filter((ico) => ico.selected)
      setEnabledConsolidationButton(selectedRows.every(ico =>
        ico.lender === selectedRows[0].lender && ico.borrower === selectedRows[0].borrower && ico.allow_create_child_agreement
        && ico.currency === selectedRows[0].currency
      ) && isMultipleChecked)
    }
    else {
      setEnabledConsolidationButton(false)
    }
  }, [isMultipleChecked]);



  useEffect(() => {
    if (icosDataLocal.filter((ico) => ico.selected).length === 0) {
      setSingleIcoChecked(false)
      setSelectedIcoId(undefined)
      setMultipleChecked(false)
    } else if (icosDataLocal.filter((ico) => ico.selected).length === 1) {
      setSingleIcoChecked(true)
      setSelectedIcoId(icosDataLocal.find((ico) => ico.selected).id)
      setMultipleChecked(false)
    } else {
      setSingleIcoChecked(false)
      setMultipleChecked(true)
    }

  }, [icosDataLocal]);


  const handleClickIcoRow = (icoId) => {
    if (!icoId || icoId === selectedIcoId) {
      setSelectedIcoId(undefined);
      setRelatedIcosLocal([]);

    } else {
      setSelectedIcoId(icoId);
      onRelatedIcos(icoId);
      handleClickCheck(icoId)
    }
  };

  const handleOpenIcoDetails = useCallback(
    (e, icoId) => {
      e.stopPropagation();
      setSelectedIcoId(icoId);
      onOpenIcoDialog();
    },
    [selectedIcoId]
  );


  const handleOpenIco = useCallback((mode, parentId = null, consolidatedList = [], copyId = null) => {
    onOpenIcoDialog(["appendix", "consolidation", "new"].includes(mode) ? null : selectedIcoId, mode, parentId, consolidatedList, copyId);
  }, [selectedIcoId, icosDataLocal]);


  const handleClickCheckAll = useCallback(
    (value) => {
      let currValue = !checkedAll;
      setCheckedAll(currValue);

      setIcosDataLocal((prev) => {
        return prev.map((rowData) => ({
          ...rowData,
          selected: currValue,
        }));
      });
    },
    [checkedAll]
  );


  const handleClickCheck = useCallback((id, alwaysTrue = false) => {
    setIcosDataLocal((prevData) => {
      let selectedRow = { ...prevData.find((rowData) => rowData.id === id) };
      if (!alwaysTrue) {
        selectedRow.selected = !selectedRow.selected;
      } else {
        selectedRow.selected = true
      }
      if (selectedRow.selected == false && selectedIcoId) {
        setSelectedIcoId(undefined)
      }
      return prevData.map((rowData) => {
        if (rowData.id === id) return selectedRow;
        return rowData;
      });
    });
  }, [selectedIcoId]);

  const onRefetchDetails = () => {
    if (selectedIcoId) {
      onRelatedIcos(selectedIcoId);
      refetchDocs();
    }
  };

  const closeIcoDialog = useCallback(() => {
    setSelectedIcoId(undefined);
    setCheckedAll(false)
    onCloseIcoDialog();
    icosData.refetch()
  }, [selectedIcoId]);


  const isAgreementTableLoading =
    hiddenColumnsForTables === undefined ||
    columnsOrdersForTables === undefined ||
    icoFilteringData.loading ||
    icosDataLocal === undefined


  const isDetailsLoading = getRelatedIcosFn.loading;

  const agreementExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      ICO_REGISTRY_TABLE_FIELDS_CONFIG,
      hiddenColumnsForTables?.[ICO_REGISTRY_TABLE_NAME],
      columnsOrdersForTables?.[ICO_REGISTRY_TABLE_NAME]
    );
  }, [
    hiddenColumnsForTables?.[ICO_REGISTRY_TABLE_NAME],
    columnsOrdersForTables?.[ICO_REGISTRY_TABLE_NAME],
    ICO_REGISTRY_TABLE_FIELDS_CONFIG,
  ]);

  const extraButtonList = useMemo(() => {
    let buttonList = [
      <Button
        fullWidth
        variant="contained"
        size="small"
        startIcon={<PreviewIcon sx={centerVericalAlignIconStyle} />}
        disabled={!singleIcoChecked}
        onClick={() => handleOpenIco("preview")}
        sx={overflowButtonStyle}
      >
        {t("bar.tool_bar.preview_agreement")}
      </Button>
    ]
    let editPermissionExtraList = [
      <Button
        fullWidth
        variant="contained"
        size="small"
        startIcon={<EditIcon sx={centerVericalAlignIconStyle} />}
       // disabled={!singleIcoChecked || !selectedIco?.allow_create_child_agreement}
        disabled
        onClick={() => handleOpenIco("appendix", selectedIcoId)}
        sx={overflowButtonStyle}
      >
        {t("bar.tool_bar.change_condition_by_appendix")}
      </Button>,
      <Button
        fullWidth
        variant="contained"
        size="small"
        startIcon={<EditIcon sx={centerVericalAlignIconStyle} />}
        disabled={!singleIcoChecked  || selectedIco?.allow_only_preview }
        onClick={() => handleOpenIco("changes_wo_appendix")}
        sx={overflowButtonStyle}
      >
        {t("bar.tool_bar.change_condition_without_appendix")}
      </Button>,
      <Button
        fullWidth
        variant="contained"
        size="small"
        startIcon={<EditIcon sx={centerVericalAlignIconStyle} />}
        disabled={!singleIcoChecked  || selectedIco?.allow_only_preview }
        onClick={() => handleOpenIco("side_changes")}
        sx={overflowButtonStyle}
      >
        {t("bar.tool_bar.change_condition_agreement_sides")}
      </Button>,

      <Button
        fullWidth
        variant="contained"
        size="small"
        startIcon={<EditIcon sx={centerVericalAlignIconStyle} />}
        // disabled={!enabledConsolidationButton || selectedIco?.allow_only_preview }
        disabled
        onClick={() => handleOpenIco("consolidation", null, getSelectedRows())}
        sx={overflowButtonStyle}
      >
        {t("bar.tool_bar.consolidation")}
      </Button>]

    if (hasEditPermission) {
      return [...buttonList, ...editPermissionExtraList]
    }
    return buttonList
  }, [singleIcoChecked, enabledConsolidationButton, selectedIco])

  const getDialogMode = useCallback(() => {
    if (selectedIco && selectedIco?.is_rejected_agreement){
      handleOpenIco("new", undefined, [], selectedIcoId)
    } else if (selectedIco?.allow_only_preview){
      handleOpenIco("new")
    }
    else if (selectedIco ){
      handleOpenIco("edit")
    }
    else{
      handleOpenIco("new")
    }
  }, [selectedIcoId, selectedIco])

  const getSelectedRows = useCallback(() => {
    return icosDataLocal.filter((ico) => ico.selected).map((ico) => ico.id)
  }, [icosDataLocal])

  const getSelectedAgreementDetails = () => {
    return (
      <LoaderWrapper showLoader={isDetailsLoading}>
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <RelatedAgreementsBox
              relatedAgreements={relatedIcosLocal}
              agreementType={"ico"}
            />
          </Grid>

          <Grid item xs={4}>
            <BaseBox>
              <FileUploadList
                addEnclosureButtonProps={{ size: "mini" }}
                enclosures={docs}
                fileType={"docs"}
                onPreviewEnclosure={onPreviewDoc}
                onDownloadEnclosure={onDownloadDoc}
                onDeleteEnclosure={hideDocs}
                canRemoveEnclosures={true}
                readOnly={isDocsLoading || !hasEditPermission}
                onUpdateEnclosure={updateDocs}
                ico={selectedIcoId}
                refetchDocs={refetchDocs}
                availableDocsRestrictedTypes={avaibleRestrictedAreas}
                multiple={true}
              />
            </BaseBox>
          </Grid>
        </Grid>
      </LoaderWrapper>
    );
  };
  return (
    <NavigationDrawer
      drawerType={NAVIGATION_DRAWER_TYPE_ICO}
      pageName={pageName}
    >
      <DefaultPageWrapper titleKey={"icos"}>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
        >
          <Grid item xs={12} marginY={1}>
            <UniversalToolBarWithDialogs
              pageName={pageName}
              tableConfig={TABLE_CONFIGS}
              filteringData={icoFilteringData ? icoFilteringData : []}
              refreshTable={icosData.refetch}
              resetPageNumber={resetPageNumber}
              hiddenColumnsForTables={hiddenColumnsForTables}
              setHiddenColumnsForTables={setHiddenColumnsForTables}
              columnsOrdersForTables={columnsOrdersForTables}
              setColumnsOrdersForTables={setColumnsOrdersForTables}
              showMassActionButton={false}
              showOpenCloseFilterButton={false}
              showCleanfiltersButton={true}
              showMyFiltersButton={true}
              showSearchButton={true}
              itemType={"ico"}
              style={{ marginBlock: "5px" }}
              onClickCreateItem={() => getDialogMode()}
              singleIcoChecked={singleIcoChecked}
              showCreateButton={hasEditPermission}
              extraEditButtonList={extraButtonList}
              mdGridButton={hasEditPermission ? 1.25 : 2.75}
              allow_only_preview = {selectedIco?.allow_only_preview}
              isRejectedAgreement = {selectedIco?.is_rejected_agreement}
            />
          </Grid>
          <Grid item xs={12}>
            <LoaderWrapper showLoader={isAgreementTableLoading}>
              <AgreementTable
                data={icosDataLocal}
                onClickRow={handleClickIcoRow}
                countRecords={countIcoRecord}
                resetPageNumber={resetPageNumber}
                filteringData={icoFilteringData ? icoFilteringData : []}
                hiddenColumns={hiddenColumnsForTables ? hiddenColumnsForTables[ICO_REGISTRY_TABLE_NAME] : []}
                columnsOrders={columnsOrdersForTables ? columnsOrdersForTables[ICO_REGISTRY_TABLE_NAME] : undefined}
                selectedAgreementId={selectedIcoId}
                style={{ maxHeight: "40vh" }}
                tableConfig={icoRegistryTableConfigLocal}
                showCleanFilterIcon={true}
                showContextMenu={false}
                showExportToFileButton={true}
                exportToFileUrl={exportIcoToFileUrl}
                exportToFileSearchParams={searchParams}
                exportToFileHeaders={agreementExportHeaders}
                exportToFileFileName={`${t("page.ico_regitry_page.loans_and_bonds").replace(" ", "_")}.xlsx`}
                showDetailsIcon={false}
                onClickEdit={handleOpenIcoDetails}
                hasPermission={hasEditPermission}
                showCheckbox={true}
                checkedAll={checkedAll}
                onClickCheckAll={handleClickCheckAll}
                onClickCheck={handleClickCheck}
              />
            </LoaderWrapper>
          </Grid>
          <Grid item xs={12}>
            {selectedIcoId ? (
              getSelectedAgreementDetails()
            ) : (
              <Typography
                textAlign="center"
                variant="overline"
                display="block"
                gutterBottom
                style={{ margin: 0, fontSize: "20px", color: "var(--primary)" }}
              >
                {t("page.ico_regitry_page.choose_agreement_to_show_details")}
              </Typography>
            )}
          </Grid>
        </Grid> 
        {openAgreementDialog &&
          <IcoConfiguratorDialog
            open={openAgreementDialog}
            onClose={closeIcoDialog}
            filteringData={icoFilteringData}
            onRefetchData={icosData.refetch}
            onRefetchDetails={onRefetchDetails}
            dialogMode={dialogMode}
            consolidationList={consolidationList}
            dialogParentId={dialogParentId}
            dialogCopyId = {dialogCopyId}
            icoId={dialogId}
            readOnly={false}
          />
        }
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
