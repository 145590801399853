import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { Grid, IconButton, Tooltip } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import MeterPassportForm from "../../components/form/MeterPassportForm";
import TitleTypography from "../../components/base/TitleTypography";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

import useDialog from "../../hooks/useDialog";

function CounterPassportPage(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { equipmentId } = useParams();

  const tagTitle = `MySolvy | ${t("headers.counter_passport")}`;

  const [
    openAssignDataMeterDialog,
    onOpenMeterAssignDataDialog,
    onCloseMeterAssignDataDialog,
  ] = useDialog();

  const [isReadOnly, setIsReadOnly] = useState(true);

  return (
    <>
      <Helmet>
        <title>{tagTitle}</title>
      </Helmet>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        rowSpacing={1}
        columnSpacing={1}
        alignItems="stretch"
      >
        <Grid item xs={12} marginBottom={{ xs: 2, md: 0 }}>
          <div style={{ position: "absolute", marginTop: 5 }}>
            <IconButton
              color="primary"
              onClick={() => navigate("/counter_repository/")}
            >
              <Tooltip title={t("headers.counter_repository")}>
                <KeyboardBackspaceIcon fontSize="large" />
              </Tooltip>
            </IconButton>
          </div>
          <TitleTypography titleKey={"counter_passport"} color={"primary"} />
          <div
            style={{ position: "absolute", marginTop: 5, right: 10, top: 20 }}
          >
            {!isReadOnly && (
              <IconButton color="primary" onClick={onOpenMeterAssignDataDialog}>
                <Tooltip title={t("edit")}>
                  <ModeEditIcon fontSize="large" />
                </Tooltip>
              </IconButton>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <MeterPassportForm
            equipmentId={equipmentId}
            openAssignDataMeterDialog={openAssignDataMeterDialog}
            onCloseMeterAssignDataDialog={onCloseMeterAssignDataDialog}
            setIsReadOnly={setIsReadOnly}
          />
        </Grid>
      </Grid>
    </>
  );
}

CounterPassportPage.propTypes = {};

export default CounterPassportPage;
