import i18n from "../../../i18n";
import DeleteIcon from '@mui/icons-material/Delete';


export const ITEM_REFERENCE_TABLE_FIELDS_CONFIG = [
  {
    name: "item_1_indexed_name",
    getValueCallback: (rowData) => rowData.item_1_indexed_name,
    label: i18n.t("table.item_reference_table.reference_item_1"),
  },
  {
    name: "item_2_indexed_name",
    getValueCallback: (rowData) => rowData.item_2_indexed_name,
    label: i18n.t("table.item_reference_table.reference_item_2"),
  },{
    name: "remove",
    getValueCallback: (rowData, onClickRemove) => {
      return (
        <DeleteIcon
          onClick={(e) => onClickRemove(rowData.id)}
        />
      );
    },
    label: i18n.t("table.item_reference_table.remove"),
  }
];