import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import UserTenantDetailsForm from "../../form/UserTenantDetailsForm";

import { useForm } from "../../../hooks/useForm";

import useUserService from "../../../services/userService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useSnackbarAlert } from "../../../context/snackbarAlert";

import {
  getErrorMsg,
  isEmptyArray,
  isEmptyValue,
} from "../../../helpers/methods";

import {
  DIALOG_PREVIEW_MODE,
  DIALOG_EDIT_MODE,
  DIALOG_CREATE_MODE,
  AAD_LOGIN_SYSTEM,
} from "../../../helpers/constants";

import { EDIT_MODE } from "../../base/FilterDialog/FilterDialog";

import useFieldValidation from "../../../hooks/useFieldValidation";
import useField from "../../../hooks/useField";

const USER_TENANT_FIELDS = [
  "user__id",
  "user__first_name",
  "user__last_name",
  "user__mail",
  "user__phone",
  "user__roles",
  "user__object_id",
  "user__user_external_system",
  "user__user_external_identificator",
  "tenant",
  "band",
  "uste_band_tenant",
  "uste_note",
  "user__is_active",
  "responsibilities",
  "uste_rr_visibility",
];

const DEFAULT_HIDDEN_FIELDS = [
  "user__user_external_system",
  "user__user_external_identificator",
];

const DEFAULT_READ_ONLY_FILEDS = ["user__roles", "user__object_id"];

const DEFAULT_REQUIRED_FIELDS = [
  "user__first_name",
  "user__last_name",
  "user__mail",
  "user__phone",
  "user__roles",
  "user__user_external_system",
  "uste_rr_visibility",  
  "user__is_active",
  // "user__user_external_identificator",
  "tenant",
];

const FIELDS_ONLY_FOR_MY_OLIVIA_SYSTEM = [
  "band",
  "uste_band_tenant",
  // "user__is_active",
  "responsibilities",
];

const USER_DATA_PREFIX = "user__";
const USER_DATA_OBJECT_TO_SAVE_NAME = "user";

const UserTenantDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeAutocompleteFieldWithObjectOptions,
  } = useForm();

  const dialogTitle = useMemo(() => {
    if (props.title) return props.title;

    return props.dialogMode === DIALOG_CREATE_MODE
      ? props.isForRR
        ? t("dialog.user_tenant.add_rr_user_tenant")
        : t("dialog.user_tenant.add_user_tenant")
      : props.dialogMode === EDIT_MODE
      ? props.isForRR
        ? t("dialog.user_tenant.edit_rr_user_tenant")
        : t("dialog.user_tenant.edit_user_tenant")
      : props.isForRR
      ? t("dialog.user_tenant.rr_user_tenant_details")
      : t("dialog.user_tenant.user_tenant_details");
  }, [props.dialogMode, props.title]);

  const isReadOnly = useMemo(
    () => props.readOnly || props.dialogMode === DIALOG_PREVIEW_MODE,
    [props.readOnly, props.dialogMode]
  );

  const readOnlyFields = useMemo(
    () => props.readOnlyFields || DEFAULT_READ_ONLY_FILEDS,
    [props.readOnlyFields]
  );

  const fieldsToSave = useMemo(
    () => props.fieldsToSave || USER_TENANT_FIELDS,
    [props.fieldsToSave]
  );

  const isAADExternalSystemSelected = useMemo(() => {
    return (
      formValue &&
      formValue?.user__user_external_system &&
      formValue?.user__user_external_system === AAD_LOGIN_SYSTEM
    );
  }, [formValue?.user__user_external_system]);

  const hiddenFields = useMemo(() => {
    let hiddenFieldsTemp = props.hiddenFields || DEFAULT_HIDDEN_FIELDS;
    if (isAADExternalSystemSelected) {
      hiddenFieldsTemp = [
        ...hiddenFieldsTemp,
        ...FIELDS_ONLY_FOR_MY_OLIVIA_SYSTEM,
      ];
    }
    return hiddenFieldsTemp;
  }, [props.hiddenFields, isAADExternalSystemSelected]);

  const requiredFields = useMemo(() => {
    let requiredFieldsTemp = props.requiredFields || DEFAULT_REQUIRED_FIELDS;
    if (!isAADExternalSystemSelected) {
      requiredFieldsTemp = [
        ...requiredFieldsTemp,
        ...FIELDS_ONLY_FOR_MY_OLIVIA_SYSTEM,
      ];
    }
    return requiredFieldsTemp;
  }, [props.requiredFields, isAADExternalSystemSelected]);

  const [unfillRequiredFields, setUnfillRequiredFields] = useState([]);

  const { getUnfillRequiredFields } = useFieldValidation();

  const { setFocusForFieldById } = useField();

  const checkIfRequiredFieldsAreFill = () => {
    const unfillFieldsTemp = getUnfillRequiredFields(
      requiredFields,
      formValue,
      ["responsibilities", "user__roles"]
    );

    setUnfillRequiredFields(unfillFieldsTemp);
    if (unfillFieldsTemp.length > 0) {
      setFocusForFieldById(unfillFieldsTemp[0]);
      return false;
    }
    return true;
  };

  const {
    getUserTenantDetails,
    getNewUserTenantInitialData,
    getUserTenantFilteringData,
    updateUserTenant,
    addUserTenant,
  } = useUserService();

  const userTenantFilteringData = useAsync(() => {
    let searchParams = { include_objects_level_4: true };
    // if (props.isForRR) {
    //   searchParams["tenant_rr_visibility"] = true;
    // }
    return getUserTenantFilteringData(searchParams);
  });

  const userTenantInitialData = useAsync(() => {
    if (props.dialogMode === DIALOG_CREATE_MODE) {
      return getNewUserTenantInitialData();
    }

    return getUserTenantDetails(props.userTenantId);
  }, [props.userTenantId, props.dialogMode]);

  useEffect(() => {
    setFormValue(userTenantInitialData.value);
  }, [userTenantInitialData.loading]);

  const prepareDataToSend = () => {
    const dataToSend = {};
    for (const [key, value] of Object.entries(formValue)) {
      if (fieldsToSave.includes(key)) {
        if (key.includes(USER_DATA_PREFIX)) {
          dataToSend[USER_DATA_OBJECT_TO_SAVE_NAME] = {
            ...dataToSend[USER_DATA_OBJECT_TO_SAVE_NAME],
            [key.replace(USER_DATA_PREFIX, "")]: value,
          };
        } else {
          dataToSend[key] = value;
        }
      }
    }
    return dataToSend;
  };

  const updateUserTenantFn = useAsyncFn(updateUserTenant);
  const addUserTenantFn = useAsyncFn(addUserTenant);
  const submitFn = useMemo(
    () =>
      props.dialogMode === DIALOG_CREATE_MODE
        ? addUserTenantFn
        : updateUserTenantFn,
    [props.dialogMode]
  );
  const handleSubmit = () => {
    if (!checkIfRequiredFieldsAreFill()) {
      return;
    }

    submitFn
      .execute(prepareDataToSend(), props.userTenantId)
      .then((res) => {
        const successMsg =
          props.dialogMode === DIALOG_CREATE_MODE
            ? props.isForRR
              ? t("snackbar_alert.rr_user_tenant_added")
              : t("snackbar_alert.user_tenant_added")
            : props.isForRR
            ? t("snackbar_alert.rr_user_tenant_updated")
            : t("snackbar_alert.user_tenant_updated");
        snackbarAlert.openSuccessSnackbarAlert(successMsg);
        if (props.onSubmitCallback) {
          props.onSubmitCallback(res);
        }
        props.onClose();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error.data,
            t("snackbar_alert.occurred_error_during_saving_changes")
          )
        );
      });
  };

  const isLoading =
    userTenantFilteringData.loading ||
    userTenantFilteringData.value === undefined ||
    formValue === undefined;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowGap={1}
      >
        <UserTenantDetailsForm
          formValue={formValue}
          filteringData={userTenantFilteringData.value}
          readOnly={isReadOnly}
          hiddenFields={hiddenFields}
          readOnlyFields={readOnlyFields}
          onChange={onChange}
          onChangeAutocompleteFieldWithObjectOptions={
            onChangeAutocompleteFieldWithObjectOptions
          }
          requiredFields={requiredFields}
          unfillRequiredFields={unfillRequiredFields}
        />
        {!isReadOnly && (
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              loading={submitFn.loading}
              onClick={handleSubmit}
            >
              {t("save")}
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={dialogTitle}
      maxWidth="sm"
      showDialogActions
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

UserTenantDialog.propTypes = {
  dialogMode: PropTypes.oneOf([
    DIALOG_CREATE_MODE,
    DIALOG_EDIT_MODE,
    DIALOG_PREVIEW_MODE,
  ]),
  readOnly: PropTypes.bool,
  userTenantId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  onSubmitCallback: PropTypes.func,
  fieldsToSave: PropTypes.array,
  hiddenFields: PropTypes.array,
  readOnlyFields: PropTypes.array,
  requiredFields: PropTypes.array,
  isForRR: PropTypes.bool,
};

UserTenantDialog.defaultProps = {
  open: false,
  isForRR: true,
};

export default UserTenantDialog;
