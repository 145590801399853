import i18n from "../../i18n";

import {
  AUTOCOMPLETE_FIELD_TYPE,
  TEXT_FIELD_TYPE,
  BOOLEAN_FIELD_TYPE,
  NUMERIC_RANGE_FIELD_TYPE,
} from "../../helpers/constants";

import { Typography } from "@mui/material";

import SelectFieldService from "../../services/selectFieldService";
import SellIcon from "@mui/icons-material/Sell";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";

import useItemService from "../../services/itemService";
import { useAsync } from "../../hooks/useAsync";

import WarningAlertIcon from "../../components/other/WarningAlertIcon";
import PrefixSuffixFieldTableWrapper from "../../components/wrapper/PrefixSuffixFieldTableWrapper";

import WindowService from "../../services/windowService";

export const counter_repository_TABLE_NAME = "counter_repository";

export const PARAMETERS_ARRAY_NAME = "parameters";

const PARAMETER_FIELD_PREFIX = "parameter__";

export const COUNTERS_TABLE_CONFIG = [
  {
    name: "passport",
    getValueCallback: (
      rowData,
      onClickMultipleLocationAlert,
      onClickAssignData
    ) =>
      rowData.equipment_is_mounted ? (
        <SellIcon onClick={(e) => onClickAssignData(rowData.id)} />
      ) : (
        <FeedOutlinedIcon
          onClick={(e) =>
            WindowService.openInNewTab(`/counter_passport/${rowData.id}`)
          }
        />
      ),
    label: i18n.t("passport"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    excludedFromExport: true,
  },
  {
    name: "active_tickets",
    sortable: true,
    getValueCallback: (rowData) => {
      return rowData?.active_tickets ? (
        <div style={{ display: "flex" }}>
          {rowData.active_tickets.map((ticket) => (
            <Typography
              onClick={() =>
                WindowService.openInNewTab(`/ticket_details/${ticket.id}`)
              }
              style={{ margin: 0, color: "var(--primary)" }}
            >
              {ticket.ticket_descriptive_nr}{" "}
            </Typography>
          ))}
        </div>
      ) : null;
    },
    label: i18n.t("table.ticket.ticket"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.active_tickets,
      isObjectOption: true,
      optionLabelKey: "ticket_nr",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "equipment",
    getValueCallback: (rowData) => rowData.equipment_nr,
    label: i18n.t("table.counter_repository.equipment"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.equipment,
      isObjectOption: true,
      optionLabelKey: "equipment_nr",
      addNewValue: false,
      multiple: true,
    }),
    other_export_field_name: "equipment_nr",
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equipment_parent",
    getValueCallback: (rowData) => rowData.equipment_parent,
    label: i18n.t("table.counter_repository.equipment_parent"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.equipment_parent,
      isObjectOption: true,
      optionLabelKey: "equipment_nr",
      addNewValue: false,
      multiple: true,
    }),
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "objects_level_1_name",
    getValueCallback: (rowData) =>
      rowData.instalation_location.level_1?.object_name,
    label: i18n.t("table.counter_repository.instalation_place_level_1_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_1_name,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "objects_level_2_name",
    getValueCallback: (rowData) =>
      rowData.instalation_location.level_2?.object_name,
    label: i18n.t("table.counter_repository.instalation_place_level_2_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_2_name,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "objects_level_3_name",
    getValueCallback: (rowData) =>
      rowData.instalation_location.level_3?.object_name,
    label: i18n.t("table.counter_repository.instalation_place_level_3_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_3_name,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "objects_level_4_name",
    getValueCallback: (rowData) =>
      rowData.instalation_location.level_4?.object_name,
    label: i18n.t("table.counter_repository.instalation_place_level_4_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_4_name,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "objects_level_5_code",
    getValueCallback: (rowData) =>
      rowData.instalation_location.level_5?.object_code,
    label: i18n.t("table.counter_repository.instalation_place_level_5_code"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_5_code,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "objects_level_5_name",
    getValueCallback: (rowData) =>
      rowData.instalation_location.level_5?.object_name,
    label: i18n.t("table.counter_repository.instalation_place_level_5_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_5_name,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "objects_level_1_name__measuring_location",
    getValueCallback: (rowData, onClickMultipleLocationAlert) => {
      return rowData.is_multiple_measuring_location ? (
        <PrefixSuffixFieldTableWrapper
          prefix={
            <WarningAlertIcon
              style={{ cursor: "pointer" }}
              onClick={(e) => onClickMultipleLocationAlert(e, rowData.id)}
            />
          }
        >
          {rowData.measuring_location[0]?.level_1?.object_name}
        </PrefixSuffixFieldTableWrapper>
      ) : (
        rowData.measuring_location[0]?.level_1?.object_name
      );
    },
    label: i18n.t("table.counter_repository.measuring_place_level_1_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_1_name,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
    headerSx: { whiteSpace: "break-spaces" },
  },

  {
    name: "objects_level_2_name__measuring_location",
    getValueCallback: (rowData) =>
      rowData.measuring_location[0]?.level_2?.object_name,
    label: i18n.t("table.counter_repository.measuring_place_level_2_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_2_name,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "objects_level_3_name__measuring_location",
    getValueCallback: (rowData) =>
      rowData.measuring_location[0]?.level_3?.object_name,
    label: i18n.t("table.counter_repository.measuring_place_level_3_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_3_name,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "objects_level_4_name__measuring_location",
    getValueCallback: (rowData) =>
      rowData.measuring_location[0]?.level_4?.object_name,
    label: i18n.t("table.counter_repository.measuring_place_level_4_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_4_name,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "objects_level_5_name__measuring_location",
    getValueCallback: (rowData) =>
      rowData.measuring_location[0]?.level_5?.object_name,
    label: i18n.t("table.counter_repository.measuring_place_level_5_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_5_name,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equipment_responsible_user",
    getValueCallback: (rowData) => rowData.equipment_responsible_user,
    label: i18n.t("table.counter_repository.equipment_responsible_user"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.equipment_responsible_user,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
    }),
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "tenant",
    getValueCallback: (rowData) => rowData.tenant,
    label: i18n.t("table.counter_repository.tenant"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.tenant,
      isObjectOption: true,
      optionLabelKey: "tenant_short_name",
      addNewValue: false,
      multiple: true,
    }),
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equipment_category",
    getValueCallback: (rowData) => rowData.equipment_category,
    label: i18n.t("table.counter_repository.equipment_category"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.equipment_category,
      isObjectOption: true,
      optionLabelKey: "equcat_name",
      addNewValue: false,
      multiple: true,
    }),
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equipment_media_type",
    getValueCallback: (rowData) => rowData.equipment_media_type,
    label: i18n.t("table.counter_repository.equipment_media_type"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.equipment_media_type,
      isObjectOption: true,
      optionLabelKey: "equmedtyp_name",
      addNewValue: false,
      multiple: true,
    }),
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equipment_note",
    getValueCallback: (rowData) => rowData.equipment_note,
    label: i18n.t("table.counter_repository.equipment_note"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },

  {
    name: "equipment_note_bms",
    getValueCallback: (rowData) => rowData.equipment_note_bms,
    label: i18n.t("table.counter_repository.equipment_note_bms"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equipment_note_settlement",
    getValueCallback: (rowData) => rowData.equipment_note_settlement,
    label: i18n.t("table.counter_repository.equipment_note_settlement"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equipinstal_desc",
    getValueCallback: (rowData) => rowData.equipinstal_desc,
    label: i18n.t("table.counter_repository.equipinstal_desc"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equstate_is_in_bms",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.equstate_is_in_bms),
    label: i18n.t("table.counter_repository.equstate_is_in_bms"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equstate_is_in_scheme",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(
        rowData.equstate_is_in_scheme
      ),
    label: i18n.t("table.counter_repository.equstate_is_in_scheme"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },

  {
    name: "equstate_is_temporary",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(
        rowData.equstate_is_temporary
      ),
    label: i18n.t("table.counter_repository.equstate_is_temporary"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equstate_is_server_room",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(
        rowData.equstate_is_server_room
      ),
    label: i18n.t("table.counter_repository.equstate_is_server_room"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equstate_is_building",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(
        rowData.equstate_is_building
      ),
    label: i18n.t("table.counter_repository.equstate_is_building"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equstate_is_floor",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.equstate_is_floor),
    label: i18n.t("table.counter_repository.equstate_is_floor"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equstate_is_damaged",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(
        rowData.equstate_is_damaged
      ),
    label: i18n.t("table.counter_repository.equstate_is_damaged"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equstate_is_in_settlement",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(
        rowData.equstate_is_in_settlement
      ),
    label: i18n.t("table.counter_repository.equstate_is_in_settlement"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equstate_is_subcounter",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(
        rowData.equstate_is_subcounter
      ),
    label: i18n.t("table.counter_repository.equstate_is_subcounter"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equstate_is_empty_location",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(
        rowData.equstate_is_empty_location
      ),
    label: i18n.t("table.counter_repository.equstate_is_empty_location"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equipment_status",
    getValueCallback: (rowData) => rowData.equipment_status,
    label: i18n.t("table.counter_repository.equipment_status"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.equipment_status,
      isObjectOption: true,
      optionLabelKey: "equstat_name",
      addNewValue: false,
      multiple: true,
    }),
  },
];

export const useMeterInstalationConfig = (pageName = "counter_repository") => {
  const { getParametersListForMeters } = useItemService();
  const parameters = useAsync(() => getParametersListForMeters());

  const getParametersForTableConfig = () => {
    return parameters.value.map((parameter) => ({
      name: `${PARAMETER_FIELD_PREFIX}${parameter.id}${
        parameter.param_is_text ? "_icontains" : ""
      }`,
      getValueCallback: (rowData) => {
        return rowData[PARAMETERS_ARRAY_NAME][parameter.id];
      },
      label: parameter.param_name,
      filterType: parameter.param_is_text
        ? TEXT_FIELD_TYPE
        : NUMERIC_RANGE_FIELD_TYPE,
      getFilterFieldConfigCallback: (filteringData) => ({
        style: { minWidth: "100px" },
      }),
    }));
  };

  const getTableProps = () => {
    const tableName = counter_repository_TABLE_NAME;
    const tablePrefix = undefined;
    const columnConfig =
      pageName === "equipment_waiting_room"
        ? COUNTERS_TABLE_CONFIG.filter(
            (config) => config.name !== "active_tickets"
          )
        : COUNTERS_TABLE_CONFIG;
    const tableConfig = [...columnConfig, ...getParametersForTableConfig()];

    return {
      tableName: tableName,
      tableConfig: tableConfig,
      tableFilterPrefix: tablePrefix,
      configForUserPreferenceDialog: [
        {
          name: tableName,
          config: tableConfig,
        },
      ],
    };
  };

  return {
    fetchingFromBackend: parameters.loading || parameters.value === undefined,
    getTableProps,
  };
};
