import PropTypes from "prop-types";

import { Grid } from "@mui/material";

import BoxWithLabel from "../../box/BoxWithLabel";
import LocationRecords from "../../other/LocationRecords";

import DateField from "../../field/DateField/DateField";

import { useTranslation } from "react-i18next";

const CounterMeasuringLocationForm = (props) => {
  const { t } = useTranslation();

  const formContent = (
    <Grid
      container
      rowSpacing={2}
      columnSpacing={1}
      justifyContent={"flex-end"}
    >
      <Grid item xs={12}>
        <LocationRecords
          locations={props.locations}
          onChangeLocations={props.setLocations}
          readOnly={props.readOnly}
          factors={props.locationFactors}
          includeFactors={true}
          onChangeFactor={props.onChangeLocationFactors}
          setMeasuringLocationFactors={props.setMeasuringLocationFactors}
          factorName="eio_factor"
          factorLabel={t("form.meter_passport.eio_factor")}
          emptyFactorObject={props.emptyFactorObject}
          factorsAreInvalid={props.factorsAreInvalid}
          factorsHelperText={
            props.factorsAreInvalid && props.factorsInvalidHelperText
          }
          ref={props.measuringLocationRef}
          showAllObjectsAndLevels
          showSelectObjectLevelButton
        />
      </Grid>
      {props.showStartSettlement && (
        <Grid item xs={2}>
          <DateField
            name={"eios_start_settlement"}
            label={t("form.meter_passport.eios_start_settlement")}
            value={props.startSettlement}
            maxDate={props.endSettlement ? props.endSettlement : null}
            onChange={props.onChangeDate}
            showClearIcon={false}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("eios_start_settlement")
            }
            required
            helperText={
              props.unfillRequiredFields.includes("eios_start_settlement") &&
              t("field_required")
            }
            error={props.unfillRequiredFields.includes("eios_start_settlement")}
          />
        </Grid>
      )}
      {props.showEndSettlement && (
        <Grid item xs={2}>
          <DateField
            name={"eios_end_settlement"}
            label={t("form.meter_passport.eios_end_settlement")}
            value={props.endSettlement}
            onChange={props.onChangeDate}
            showClearIcon={false}
            minDate={props.startSettlement ? props.startSettlement : null}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("eios_end_settlement")
            }
            required
            helperText={
              props.unfillRequiredFields.includes("eios_end_settlement") &&
              t("field_required")
            }
            error={props.unfillRequiredFields.includes("eios_end_settlement")}
          />
        </Grid>
      )}
    </Grid>
  );

  return props.inBox ? (
    <BoxWithLabel label={t("form.meter_passport.measuring_locations")}>
      {formContent}
    </BoxWithLabel>
  ) : (
    formContent
  );
};

CounterMeasuringLocationForm.propTypes = {
  locations: PropTypes.object,
  setLocations: PropTypes.func,
  locationFactors: PropTypes.array,
  onChangeLocationFactors: PropTypes.func,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.array,
  setMeasuringLocationFactors: PropTypes.func,
  emptyFactorObject: PropTypes.object,
  unfillRequiredFields: PropTypes.array,
  showStartSettlement: PropTypes.bool,
  showEndSettlement: PropTypes.bool,
};

CounterMeasuringLocationForm.defaultProps = {
  readOnly: false,
  readOnlyFields: [],
  showStartSettlement: true,
  showEndSettlement: false,
  inBox: true,
};

export default CounterMeasuringLocationForm;
