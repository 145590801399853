import i18n from "../../../i18n";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import PostAddIcon from "@mui/icons-material/PostAdd";

export const EQUIPMENT_BMS_ORDER_TABLE_CONFIG = [
  {
    name: "equipment_nr",
    getValueCallback: (rowData) => rowData.equipment_nr,
    label: i18n.t("table.counter_repository.equipment"),
    filterType: null,
  },
  {
    name: "level_3",
    getValueCallback: (rowData) => rowData.level_3,
    label: i18n.t("table.counter_repository.level_3"),
    filterType: null,
  },
  {
    name: "level_4",
    getValueCallback: (rowData) => rowData.level_4,
    label: i18n.t("table.counter_repository.level_4"),
    filterType: null,
  },
  {
    name: "tenant",
    getValueCallback: (rowData) => rowData.tenant,
    label: i18n.t("table.counter_repository.tenant"),
    filterType: null,
  },
  {
    name: "equipment_media_type",
    getValueCallback: (rowData) => rowData.equipment_media_type,
    label: i18n.t("table.counter_repository.equipment_media_type"),
    filterType: null,
  },
  {
    name: "equipment_order_read",
    label: i18n.t("table.counter_repository.equipment_order_read"),
    filterType: null,
    getValueCallback: (rowData, onOpenEquipmentBmsDialog) =>
      rowData.equipment_order_read === null ? (
        <PostAddIcon onClick={() => onOpenEquipmentBmsDialog(rowData.id)} />
      ) : rowData.equipment_order_read === true ? (
        <CheckCircleIcon color="success" />
      ) : (
        <ErrorIcon color="error" />
      ),
  },
];
