import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import AutocompleteField from "../../field/AutocompleteField";
import FloatField from "../../base/FloatField/FloatField";
import { getTranslatedList } from "../../../helpers/methods";
import React from "react";
import TrueFalseSelectField from "../../field/TrueFalseSelectField";


const IcoFinanceConditionsForm = React.memo(({
  readOnly,
  icoData,
  onChange,
  onChangeAutocomplete,
  onChangeCustomAutocomplete,
  filteringData,
  selectedCurrency
}) => {

  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item xs={2.4}>
        <AutocompleteField
          name={"currency"}
          label={t("form.ico_finance_conditions_form.currency")}
          value={icoData?.currency}
          options={filteringData?.currencies}
          isObjectOption={true}
          optionLabelKey={"currency_name"}
          onChange={onChangeCustomAutocomplete}
          addNewValue={false}
          readOnly={readOnly}
          multiple={false}
          required
        />
      </Grid>
      <Grid item xs={2.4}>
        <FloatField
          name={"condition_value"}
          label={t("form.ico_finance_conditions_form.condition_value")}
          value={icoData?.condition_value}
          readOnly={readOnly}
          onChange={onChange}
          decimalPlaces={2}
          suffix={selectedCurrency}
          showNumberSeparator={true}
          required
        />
      </Grid>
      <Grid item xs={2.4}>
        <FloatField
          name={"condition_value_pln"}
          label={t("form.ico_finance_conditions_form.condition_value_pln")}
          value={icoData?.condition_value_pln}
          readOnly={true}
          decimalPlaces={2}
          showNumberSeparator={true}
          suffix={"PLN"}
        />
      </Grid>
      <Grid item xs={2.4}>
        <FloatField
          name={"ico_current_capital_original"}
          label={t("form.ico_finance_conditions_form.current_capital_original")}
          value={icoData?.ico_current_capital_original}
          readOnly={true}
          decimalPlaces={2}
          showNumberSeparator={true}
          suffix={selectedCurrency}
        />
      </Grid>
      <Grid item xs={2.4}>
        <FloatField
          name={"ico_current_value_interest_original"}
          label={t("form.ico_finance_conditions_form.ico_current_value_interest_original")}
          value={icoData?.ico_current_value_interest_original}
          readOnly={true}
          decimalPlaces={2}
          showNumberSeparator={true}
          suffix={selectedCurrency}
        />
      </Grid>

      <Grid item xs={2}>
        <AutocompleteField
          name={"interest"}
          label={t("form.ico_finance_conditions_form.interest")}
          value={icoData?.interest}
          options={filteringData?.interests}
          isObjectOption={true}
          optionLabelKey={"interest_name"}
          onChange={onChangeAutocomplete}
          addNewValue={false}
          multiple={false}
          readOnly={readOnly}
          required={true}
        />
      </Grid>
      <Grid item xs={1.5}>
        <FloatField
          name={"condition_margin"}
          label={t("form.ico_finance_conditions_form.margin")}
          value={icoData?.condition_margin}
          onChange={onChange}
          decimalPlaces={2}
          readOnly={readOnly}
          suffix={"%"}
          required
        />
      </Grid>
      <Grid item xs={2}>
        <AutocompleteField
          name={"condition_disburement"}
          label={t("form.ico_finance_conditions_form.condition_disburement")}
          value={icoData?.condition_disburement}
          options={getTranslatedList(filteringData?.disburements, "form.ico_finance_conditions_form.")}
          isObjectOption={true}
          optionLabelKey={"name"}
          onChange={onChangeCustomAutocomplete}
          addNewValue={false}
          multiple={false}
          readOnly={readOnly}
          required
        />
      </Grid>
      <Grid item xs={1.5}>
        <FloatField
          name={"disburement_amount"}
          label={t("form.ico_finance_conditions_form.amount")}
          value={icoData?.disburement_amount}
          onChange={onChange}
          decimalPlaces={0}
          allowZero={false}
          readOnly={icoData?.condition_disburement === undefined || icoData?.disburement_amount === 1 || readOnly}
          required={icoData?.condition_disburement && icoData?.disburement_amount !== 1}
        />
      </Grid>
      <Grid item xs={2}>
        <AutocompleteField
          name={"condition_settlement"}
          label={t("form.ico_finance_conditions_form.condition_settlement")}
          value={icoData?.condition_settlement}
          options={getTranslatedList(filteringData?.condition_settlements, "form.ico_finance_conditions_form.")}
          isObjectOption={true}
          optionLabelKey={"name"}
          onChange={onChangeCustomAutocomplete}
          addNewValue={false}
          multiple={false}
          readOnly={readOnly}
          required
        />
      </Grid>
      <Grid item xs={1.5}>
        <FloatField
          name={"settlement_amount"}
          label={t("form.ico_finance_conditions_form.amount")}
          value={icoData?.settlement_amount}
          onChange={onChange}
          decimalPlaces={0}
          allowZero={false}
          readOnly={icoData?.condition_settlement === undefined || icoData?.settlement_amount === 1 || readOnly}
          required={icoData?.condition_settlement && icoData?.settlement_amount !== 1}
        />
      </Grid>
      <Grid item xs={1.5}>
        <AutocompleteField
          name={"condition_payment"}
          label={t("form.ico_finance_conditions_form.condition_payment")}
          value={icoData?.condition_payment}
          options={getTranslatedList(filteringData?.condition_payments, "form.ico_finance_conditions_form.")}
          isObjectOption={true}
          optionLabelKey={"name"}
          readOnly={readOnly}
          onChange={onChangeAutocomplete}
          addNewValue={false}
          multiple={false}
          required
        />
      </Grid>
      {/* <Grid item xs={1}>
        <TrueFalseSelectField
          name="condition_include_leap_years"
          label={t("form.ico_finance_conditions_form.count_leap_years")}
          value={icoData?.condition_include_leap_years}
          valuesAsBool
          addEmptyOptions={false}
          onChange={onChange}
        />
      </Grid> */}
    </Grid>
  );
});


IcoFinanceConditionsForm.propTypes = {
  readOnly: PropTypes.bool,
  icoData: PropTypes.object,
  icoId: PropTypes.string,
  onChange: PropTypes.func,
  onChangeDate: PropTypes.func,
  onChangeAutocomplete: PropTypes.func,
  onChangeCurrency: PropTypes.func,
  filteringData: PropTypes.object
};

IcoFinanceConditionsForm.defaultProps = {
  readOnly: false,
  filteringData: { value: [] },
};

export default IcoFinanceConditionsForm;