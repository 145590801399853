import { AUTOCOMPLETE_FIELD_TYPE, BOOLEAN_FIELD_TYPE, NUMERIC_RANGE_FIELD_TYPE, TEXT_FIELD_TYPE } from "../../helpers/constants";
import i18n from "../../i18n";
import SelectFieldService from "../../services/selectFieldService";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";


export const ITEM_REGISTRY_TABLE_NAME = "item_registry"

export const ITEM_REGISTRY_TABLE_FIELDS_CONFIG = [
  {
    name: "item_parent",
    getValueCallback: (rowData) => rowData.item_parent,
    label: i18n.t("table.item_registry_table.item_parent"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "item_number",
    getValueCallback: (rowData) => rowData.item_number,
    label: i18n.t("table.item_registry_table.item_number"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "item_name",
    getValueCallback: (rowData) => rowData.item_name,
    label: i18n.t("table.item_registry_table.item_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "item_description",
    getValueCallback: (rowData) => rowData.item_description,
    label: i18n.t("table.item_registry_table.item_description"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "item_procurement_category",
    getValueCallback: (rowData) => rowData.item_procurement_category,
    label: i18n.t("table.item_registry_table.procurement_category"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.procurement_categories,
      isObjectOption: true,
      optionLabelKey: "proccat_name",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "gtu",
    getValueCallback: (rowData) => rowData.gtu,
    label: i18n.t("table.item_registry_table.gtu"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.gtus,
      isObjectOption: true,
      optionLabelKey: "gtu_name",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "knr",
    getValueCallback: (rowData) => rowData.knr,
    label: i18n.t("table.item_registry_table.knr"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.knrs,
      isObjectOption: true,
      optionLabelKey: "knr_indexed_name",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "item_procurement_keeper",
    getValueCallback: (rowData) => rowData.item_procurement_keeper,
    label: i18n.t("table.item_registry_table.procurement_keeper"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.users,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "item_procurement_criticaly",
    getValueCallback: (rowData) => rowData.item_procurement_criticaly,
    label: i18n.t("table.item_registry_table.procurement_criticaly"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "item_revit_family",
    getValueCallback: (rowData) => rowData.item_revit_family,
    label: i18n.t("table.item_registry_table.revit_family"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "revit_family_description",
    getValueCallback: (rowData) => rowData.revit_family_description,
    label: i18n.t("table.item_registry_table.revit_family_description"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "references",
    getValueCallback: (rowData) => rowData.references,
    label: i18n.t("table.item_registry_table.references"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.items,
      isObjectOption: true,
      optionLabelKey: "indexed_item_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "item_active",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.item_active),
    label: i18n.t("table.item_registry_table.item_active"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "edit",
    getValueCallback: (rowData, onClickEdit, hasPermission) => {
      const content = (
          <ModeEditOutlineOutlined
            onClick={(e) => onClickEdit(e, rowData.id)}
          />
      );
      return  hasPermission && rowData.item_active ? content : "";
    },
    filterType: null,
    excludedFromExport: true,

  }
];
