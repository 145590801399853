import { useMemo, useState, useEffect, useRef, useCallback } from "react";

import { useTranslation } from "react-i18next";

import { Grid, Button } from "@mui/material";

import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";

import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";

import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";

import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import useEquipmentService from "../../services/equipmentService";

import UniversalToolBarWithDialogs from "../../components/bar/UniversalToolBarWithDialogs";

import useDialogWithId from "../../hooks/useDialogWithId";

import { isSearchParamsForFilteringEmpty } from "../../helpers/methods";
import { NAVIGATION_DRAWER_TYPE_METERS } from "../../components/drawer/NavigationDrawer/NavigationDrawer";

import { useMeterInstalationConfig } from "../CountersRepositoryPage/TableConfig";
import EquipmentTable from "../../components/table/EquipmentTable/EquipmentTable";

import TableService from "../../services/tableService";
import { exportEquipmentForMetersToFileUrl } from "../../helpers/apiUrls";

import CounterAssignDataDialog from "../../components/dialog/CounterAssignDataDialog";

import useDialog from "../../hooks/useDialog";
import MultipleLocalizationDialog from "../../components/dialog/MutlipleLocalizationDialog";

import { useSnackbarAlert } from "../../context/snackbarAlert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { centerVericalAlignIconStyle } from "../../helpers/styles";
import { overflowButtonStyle } from "../../helpers/styles";

import CreateEquipmentTicketButton from "../../components/button/CreateEquipmentTicketButton/CreateEquipmentTicketButton";

export default function EquipmentWaitingRoomPage(props) {
  const { pageName } = props;

  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const [dataLocaly, setDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams();

  const waitingRoomSearchParams = useMemo(() => {
    let tempSearchParams = { ...searchParams };
    tempSearchParams.equipment_is_in_waiting_room = true;
    return tempSearchParams;
  }, [searchParams]);

  const {
    getEquipmentForMeters,
    getEquipmentForMetersFilteringData,
    deleteEquipmentsFromWaitingRoom,
  } = useEquipmentService();

  const { getTableProps, fetchingFromBackend } = useMeterInstalationConfig(
    props.pageName
  );

  const tableProps = useMemo(() => {
    if (fetchingFromBackend) {
      return;
    }
    return getTableProps();
  }, [fetchingFromBackend]);

  const equipmentFilteringData = useAsync(getEquipmentForMetersFilteringData);

  const equipmentData = useAsync(() => {
    return getEquipmentForMeters(waitingRoomSearchParams);
  }, [waitingRoomSearchParams]);

  useEffect(() => {
    if (equipmentData.loading) {
      return;
    }
    setDataLocaly(equipmentData.value.results);
    setCountRecords(equipmentData.value.count);
  }, [equipmentData.loading]);

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();

  const refreshTable = () => {
    setDataLocaly(undefined);
    equipmentData.refetch();
  };

  const onCleanFlitersInFilterDialog = () => {
    setDataLocaly(undefined);
    setCountRecords(undefined);
  };

  const [
    openAssignDataMeterDialog,
    equipmentIdForAssignData,
    onOpenMeterAssignDataDialog,
    onCloseMeterAssignDataDialog,
  ] = useDialogWithId();

  const handleEquipmentChanges = (existsChanges) => {
    if (existsChanges) {
      if (!isSearchParamsForFilteringEmpty(searchParams)) {
        setSearchParams({});
      } else {
        equipmentData.refetch();
      }
    }
  };

  const [
    openMultipleLocalizationDialog,
    onOpenMultipleLocalizationDialog,
    onCloseMultipleLocalizationDialog,
  ] = useDialog();

  const clickedOrderMultipleLocalization = useRef();
  const handleOpenMultipleLocalizationDialog = useCallback(
    (e, equipmentId) => {
      e.stopPropagation();
      clickedOrderMultipleLocalization.current = dataLocaly.find(
        (equipment) => equipment.id === equipmentId
      ).measuring_location;
      onOpenMultipleLocalizationDialog();
    },
    [onOpenMultipleLocalizationDialog, dataLocaly]
  );

  const handleCloseMultipleLocalizationDialog = () => {
    clickedOrderMultipleLocalization.current = null;
    onCloseMultipleLocalizationDialog();
  };

  const exportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      tableProps?.tableConfig,
      hiddenColumnsForTables?.[tableProps?.tableName],
      columnsOrdersForTables?.[tableProps?.tableName]
    );
  }, [
    fetchingFromBackend,
    tableProps?.tableConfig,
    hiddenColumnsForTables?.[tableProps?.tableName],
    columnsOrdersForTables?.[tableProps?.tableName],
  ]);

  const handleClickCheck = useCallback((id) => {
    setDataLocaly((prevData) => {
      let selectedRow = { ...prevData.find((rowData) => rowData.id === id) };
      selectedRow.selected = !selectedRow.selected;
      return prevData.map((rowData) => {
        if (rowData.id === id) return selectedRow;
        return rowData;
      });
    });
  }, []);

  const [checkedAll, setCheckedAll] = useState(false);
  const handleClickCheckAll = useCallback(
    (value) => {
      let currValue = !checkedAll;
      setCheckedAll(currValue);

      setDataLocaly((prev) => {
        return prev.map((rowData) => ({
          ...rowData,
          selected: currValue,
        }));
      });
    },
    [checkedAll]
  );

  const getSeletedEquipmentIds = (excludeClaims = false) =>
    dataLocaly
      .filter(
        (equipment) =>
          equipment.selected && (!excludeClaims || equipment.is_claim === false)
      )
      .map((equipment) => equipment.id);

  const deleteEquipemntsFromWaitingRoomFn = useAsyncFn(
    deleteEquipmentsFromWaitingRoom
  );

  const handleDeleteEquipmentFromWaitingRoom = () => {
    let equipmentIds = getSeletedEquipmentIds();
    if (equipmentIds.length === 0) return;

    deleteEquipemntsFromWaitingRoomFn
      .execute(equipmentIds)
      .then((res) => {
        setDataLocaly(undefined);
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.equipment_from_waiting_room_deleted")
        );
        equipmentData.refetch();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t(
            "snackbar_alert.occurred_error_during_deleting_equipment_from_waiting_room"
          )
        );
      });
  };

  const getExtraButtonListForToolBar = () => {
    let extraButtonList = [];

    if (!props.readOnly) {
      extraButtonList.push(
        <Button
          color="error"
          onClick={handleDeleteEquipmentFromWaitingRoom}
          variant="contained"
          size="small"
          startIcon={<DeleteOutlineIcon sx={centerVericalAlignIconStyle} />}
          sx={overflowButtonStyle}
          fullWidth
        >
          {t("delete_from_waiting_room")}
        </Button>
      );
      extraButtonList.push(
        <CreateEquipmentTicketButton
          selectedEquipmentIds={getSeletedEquipmentIds()}
          onSubmitCreateTicket={refreshTable}
        />
      );
    }

    return extraButtonList;
  };

  const isLoading =
    tableProps === undefined ||
    fetchingFromBackend ||
    dataLocaly === undefined ||
    equipmentFilteringData.loading;

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_METERS}
    >
      <DefaultPageWrapper titleKey={"equipment_waiting_room"}>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <UniversalToolBarWithDialogs
              pageName={props.pageName}
              tableConfig={tableProps.configForUserPreferenceDialog}
              filteringData={equipmentFilteringData}
              refreshTable={refreshTable}
              onCleanFlitersInFilterDialog={onCleanFlitersInFilterDialog}
              resetPageNumber={resetPageNumber}
              hiddenColumnsForTables={hiddenColumnsForTables}
              setHiddenColumnsForTables={setHiddenColumnsForTables}
              columnsOrdersForTables={columnsOrdersForTables}
              setColumnsOrdersForTables={setColumnsOrdersForTables}
              showOpenCloseFilterButton={false}
              showCreateButton={false}
              extraButtonList={getExtraButtonListForToolBar()}
            />
          </Grid>
          <Grid item xs={12}>
            <LoaderWrapper showLoader={isLoading}>
              {hiddenColumnsForTables && columnsOrdersForTables && (
                <EquipmentTable
                  data={dataLocaly}
                  showCheckbox={true}
                  checkedAll={checkedAll}
                  onClickCheckAll={handleClickCheckAll}
                  onClickCheck={handleClickCheck}
                  showCleanFilterIcon={false}
                  countRecords={countRecords}
                  page={page}
                  onPageChange={handleChangePageWithSearchParams}
                  rowsPerPage={pageSize}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  resetPageNumber={resetPageNumber}
                  filteringData={equipmentFilteringData}
                  style={{ maxHeight: "75vh" }}
                  tableConfig={tableProps.tableConfig}
                  hiddenColumns={hiddenColumnsForTables[tableProps.tableName]}
                  columnsOrders={columnsOrdersForTables[tableProps.tableName]}
                  onClickMultipleLocationAlert={
                    handleOpenMultipleLocalizationDialog
                  }
                  onClickAssignData={onOpenMeterAssignDataDialog}
                  showExportToFileButton={true}
                  exportToFileUrl={exportEquipmentForMetersToFileUrl}
                  exportToFileSearchParams={waitingRoomSearchParams}
                  exportToFileHeaders={exportHeaders}
                  exportToFileFileName={`${t(
                    "headers.counter_repository"
                  )}.xlsx`}
                />
              )}

              {openAssignDataMeterDialog && (
                <CounterAssignDataDialog
                  open={openAssignDataMeterDialog}
                  onClose={onCloseMeterAssignDataDialog}
                  onSubmitCallback={handleEquipmentChanges}
                  equipmentId={equipmentIdForAssignData}
                  isEquiipmentMounted={true}
                />
              )}
              {openMultipleLocalizationDialog &&
                clickedOrderMultipleLocalization.current && (
                  <MultipleLocalizationDialog
                    open={openMultipleLocalizationDialog}
                    onClose={handleCloseMultipleLocalizationDialog}
                    localizationData={clickedOrderMultipleLocalization.current}
                    isLevelObjectData
                    itemType={"scheme"}
                  />
                )}
            </LoaderWrapper>
          </Grid>
        </Grid>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
