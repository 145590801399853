import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useForm } from "../../../hooks/useForm";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import IcoMainDataForm from "../../form/IcoMainDataForm";
import { DOCS_RESTRICTED_OWNER, DOCS_RESTRICTED_ROLE, DOCS_RESTRICTED_USER, ICO_AGREEMENT_AGREEMENT, ICO_AGREEMENT_APPENDIX, ICO_AGREEMENT_CONTRACT, ICO_CANCEL_ACTION, ICO_CONSOLIDATION_ACTION, ICO_SEND_TO_ACCEPT_ACTION } from "../../../helpers/constants";
import BaseBox from "../../base/BaseBox/baseBox";
import useIcoService from "../../../services/icoService";
import BoxWithTypography from "../../box/BoxWithTypography/BoxWithTypography";
import IcoFinanceConditionsForm from "../../form/IcoFinanceConditionsForm";
import IsTestingAppWrapper from "../../wrapper/IsTestingAppWrapper/IsTestingAppWrapper";
import FunctionsDrawer, { FUNCTION_DRAWER_TYPE_ICOS } from "../../drawer/FunctionsDrawer/FunctionsDrawer";
import { openMiniDrawerWidth } from "../../drawer/MiniDrawer/MiniDrawer";
import FileUploadList from "../../other/FileUploadList";
import useDocsData from "../../../hooks/useDocsData";
import useFileOnMemoryData from "../../../hooks/useFileOnMemoryData";
import { useAuth } from "../../../context/auth";
import IcoAcceptanceForm from "../../form/IcoAcceptanceForm";
import DateService from "../../../services/dateService";
import SelectUserDialog from "../SelectUserDialog/SelectUserDialog";
import useDialog from "../../../hooks/useDialog";
import IcoTransfersForm from "../../form/IcoTransfersForm";
import IcoScheduleForm from "../../form/IcoScheduleForm/IcoScheduleForm";
import useIcoScheduleCalculationService from "../../../services/icoScheduleCalculationService";
import { getErrorMsg, getFieldsFromObject, isEmptyObject, isEmptyValue } from "../../../helpers/methods";
import { CHANGE_AGREEMENT_SIDES, CHANGE_COND_WO_APPENDIX, EDIT_MODE_FIELDS, PREVIEW_MODE_FIELDS } from "./editableFieldsDicts";
import BaseTable from "../../base/BaseTable/BaseTable";
import { ICO_REGISTRY_TABLE_FIELDS_CONFIG } from "../../../page/IcoRegistryPage/PageTablesConfig";
import TableService from "../../../services/tableService";
import { ICO_PREBO_TABLE_FIELDS_CONFIG } from "./DialogTablesConfig";


const IcoConfiguratorDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const selectedParticipiantKind = useRef();
  const selectedBorrower = useRef();
  const selectedLender = useRef();
  const conditionValue = useRef();

  const { user } = useAuth()

  const [
    openUserDialog,
    handleOpenUserDialog,
    handleCloseUserDialog,
  ] = useDialog();


  const [selectedCurrency, setSelectedCurrency] = useState();
  const [interestRateValue, setInterestRateValue] = useState();
  const [parentAgreementDisabled, setParentAgreementDisabled] = useState(true)
  const [icoParticipiants, setParticipiants] = useState({ 'accept': [], 'informed': [], 'doer': [] })
  const [icoSchedules, setSchedules] = useState({ 'tranche': [], 'installment': [], 'interest': [] })

  const [areTranchesChangedByUser, setTranchesChangedByUser] = useState(false)
  const [areInstallmentsChangedByUser, setInstallmentsChangedByUser] = useState(false)
  const [areInterestsChangedByUser, setInterestsChangedByUser] = useState(false)

  const [transfers, setTransfers] = useState([])
  const [filteringDataLocal, setFilteringDataLocal] = useState()

  const [isScheduleUpdated, setScheduleUpdated] = useState(true)
  const [allowRecalulate, setAllowRecalulate] = useState(false)

  const [isTanchesSumTableValid, setTranchesSumTableValid] = useState(true)
  const [isTanchesDatesTableValid, setTranchesDatesTableValid] = useState(true)

  const [isInstallmentSumTableValid, setInstallmentSumTableValid] = useState(true)
  const [isInstallmentDatesTableValid, setInstallmentDatesTableValid] = useState(true)
  const [isInterestDatesTableValid, setInteresDatesTableValid] = useState(true)

  const [isFirstInstalmmentDateAfterActivationDateValid, setFirstInstalmmentDateAfterActivationDateValid] = useState(true)
  const [isFirstInterestDateAfterActivationDateValid, setFirstInterestDateAfterActivationDateValid] = useState(true)
  const [isActivationDateAfterMinInterestDate, setIsActivationDateAfterMinInterestDate] = useState(true)

  const [minRepaymentDate, setMinRepaymentDate] = useState()
  const [maxActivationDate, setMaxActivationDate] = useState()
  const [minActivationDate, setMinActivationDate] = useState(new Date("1900-01-01"))
  const [prepareData, setPrepareData] = useState(false)
  const [checkPartialInstallmentSumIsValid, setCheckPartialInstallmentSumIsValid] = useState(true)
  const [isSavingData, setIsSavingData] = useState(false);

  const [areIcoConditionChanged, setIcoConditionChanged] = useState(false)
  const [areIcoSchedulesChanged, setIcoSchedulesChanged] = useState(false)
  const [participiantsToRemove, setParticipiantsToRemove] = useState([])

  const [localIcoId, setLocalIcoId] = useState(props.icoId);

  const [dialogMode, setDialogMode] = useState(props.dialogMode)
  const isPreviewMode = dialogMode === "preview"
  const [isFullEditable, setFullEditable] = useState(!localIcoId && !isPreviewMode)
  const [allowCancelIco, setAllowCancelIco] = useState(localIcoId !== undefined)
  const [editableFieldsByMode, setEditableFieldsByMode] = useState([])
  const [localConsolidatedIcos, setLocalConsolidatedIcos] = useState([])
  const [localIcoPreBos, setLocalIcoPreBos] = useState([])

  const availableDocsRestrictedTypes = [
    DOCS_RESTRICTED_USER,
    DOCS_RESTRICTED_OWNER,
    DOCS_RESTRICTED_ROLE,
  ]
  


  const requierdsFieldsToSendForAccept = React.useMemo(() => {
    return [
      "ico_product",
      "ico_name",
      "borrower",
      "lender",
      "ico_date_activation",
      "ico_date_repayment",
      "condition_value",
      "interest",
      "condition_margin",
      "disburement_amount",
      "condition_payment",
      "settlement_amount",
      "condition_settlement",
      "condition_disburement"
    ]
  }, [])

  const fieldsDependsOnSchedule = React.useMemo(() => {
    return [
      "ico_date_activation",
      "ico_date_repayment",
      "condition_value",
      "interest",
      "condition_margin",
      "disburement_amount",
      "condition_payment",
      "settlement_amount",
      "condition_settlement",
      "condition_disburement",
      "condition_include_leap_years"
    ]
  }, [])

  const fieldsDependsOnResetManualTranchesSchedule = React.useMemo(() => {
    return [
      "ico_date_activation",
      "ico_date_repayment",
      "condition_value",
      "disburement_amount",
      "condition_disburement"
    ]
  }, [])

  const fieldsDependsOnResetManualInstallmentSchedule = React.useMemo(() => {
    return [
      "ico_date_activation",
      "ico_date_repayment",
      "condition_value",
      "settlement_amount",
      "condition_settlement",
    ]
  }, [])

  const fieldsDependsOnResetManualInterestsSchedule = React.useMemo(() => {
    return [
      "ico_date_activation",
      "ico_date_repayment",
      "condition_payment",
    ]
  }, [])

  const fieldsToSaveInIco = React.useMemo(() => {
    return [
      "ico_name",
      "ico_product",
      "ico_agreement",
      "ico_agreement_reason",
      "ico_agreement_parent",
      "draft",
      "lender",
      "borrower",
      "applicant",
      "ico_date_input",
      "ico_date_agreement",
      "ico_date_activation",
      "ico_date_repayment",
      "ico_sign_form",
      "ico_note_finance",
      "ico_note_legal",
      "ico_location"
    ]
  }, [])

  const fieldsToSaveInIcoCondition = React.useMemo(() => {
    return [
      "currency",
      "condition_value",
      "interest",
      "condition_margin",
      "condition_disburement",
      "condition_payment",
      "condition_settlement",
      "condition_include_leap_years"
    ]
  }, [])


  const getNewParticipiantData = (user) => {
    return {
      "icopart_person": user.id,
      "full_name": user.full_name,
      "icopart_is_default_participant": false,
      "icopart_active": true,
      "participiant_kind": selectedParticipiantKind.current
    }
  }


  const today = DateService.convertDateToFormatYYYYMMDD(new Date())
  const {
    formValue,
    setFormValue,
    onChange,
    onChangeDate,
    onChangeAutocompleteFieldWithObjectOptions,
  } = useForm({ ico_agreement  : "contract", "applicant": user.user_id, "ico_date_agreement": today , condition_include_leap_years : true});




  const onChangeLocal = useCallback((e) => {
    onChange(e)
    const { name } = e.target
    changeSchedulesStates(name)


  }, []);

  const onChangeDateLocal = useCallback((e) => {
    onChangeDate(e)
    const { name } = e.target
    changeSchedulesStates(name)
  }, []);

  const onChangeAutocompleteLocal = useCallback(
    (e, value_object, value_key, state_value_name) => {
      changeSchedulesStates(state_value_name)
      return onChangeAutocompleteFieldWithObjectOptions(e, value_object, value_key, state_value_name)
    },
    []
  );



  const changeSchedulesStates = useCallback((name) => {
    if (fieldsDependsOnSchedule.includes(name)) {
      setScheduleUpdated(false)
    }
    if (fieldsDependsOnResetManualTranchesSchedule.includes(name)) {
      setTranchesChangedByUser(false)
      setIcoConditionChanged(true)
    }
    if (fieldsDependsOnResetManualInstallmentSchedule.includes(name)) {
      setInstallmentsChangedByUser(false)
      setIcoConditionChanged(true)
    }
    if (fieldsDependsOnResetManualInterestsSchedule.includes(name)) {
      setInterestsChangedByUser(false)
      setIcoConditionChanged(true)
    }
    if (fieldsToSaveInIcoCondition.includes(name)) {
      setIcoConditionChanged(true)
    }
  })


  const mainData = React.useMemo(() => {
    return ({
      ico_nr: formValue.ico_nr,
      ico_product: formValue.ico_product,
      ico_agreement: formValue.ico_agreement,
      draft: formValue.draft,
      ico_sign_form: formValue.ico_sign_form,
      ico_name: formValue.ico_name,
      ico_agreement_parent: formValue.ico_agreement_parent,
      borrower: formValue.borrower,
      lender: formValue.lender,
      ico_date_agreement: formValue.ico_date_agreement,
      ico_date_activation: formValue.ico_date_activation,
      ico_date_repayment: formValue.ico_date_repayment,
      ico_agreement_reason: formValue.ico_agreement_reason,
      ico_note_finance: formValue.ico_note_finance,
      ico_note_legal: formValue.ico_note_legal,
      ico_status: formValue.ico_status,
      ico_location: formValue.ico_location,
      ownersAreReadOnly: transfers.length > 0,
      maxActivationDate: maxActivationDate,
      minRepaymentDate: minRepaymentDate,
      minActivationDate: minActivationDate
    })
  }, [formValue.ico_nr, formValue.ico_product, formValue.ico_agreement, formValue.ico_sign_form, formValue.draft, formValue.ico_agreement_parent, formValue.ico_name,
  formValue.borrower, formValue.lender, formValue.ico_date_agreement, formValue.ico_date_activation, formValue.ico_date_repayment, formValue.ico_agreement_reason,
  formValue.ico_note_finance, formValue.ico_sign_form, formValue.ico_note_legal, formValue.ico_status, maxActivationDate, minRepaymentDate, minActivationDate, formValue.ico_location
  ])

  const conditionData = React.useMemo(() => {
    return ({
      currency: formValue.currency,
      condition_value: formValue.condition_value,
      ico_current_capital_original : formValue.ico_current_capital_original,
      ico_current_value_interest_original : formValue.ico_current_value_interest_original,
      interest: formValue.interest,
      condition_margin: formValue.condition_margin,
      condition_disburement: formValue.condition_disburement,
      disburement_amount: formValue.disburement_amount,
      condition_payment: formValue.condition_payment,
      condition_settlement: formValue.condition_settlement,
      settlement_amount: formValue.settlement_amount,
      condition_value_pln: formValue.condition_value_pln,
      condition_include_leap_years: formValue.condition_include_leap_years
    })
  }, [formValue.currency, formValue.condition_value, formValue.ico_current_capital_original,
  formValue.ico_current_value_interest_original, formValue.interest, formValue.condition_margin,
  formValue.condition_disburement, formValue.disburement_amount, formValue.condition_payment,
  formValue.condition_settlement, formValue.settlement_amount, formValue.condition_value_pln,
  formValue.condition_include_leap_years
  ])

  const isScheduleFormValid = formValue ? fieldsDependsOnSchedule.every(
    (fieldName) => !isEmptyValue(formValue[fieldName])
  ) && isInstallmentSumTableValid &&
    isTanchesSumTableValid &&
    isTanchesDatesTableValid &&
    checkPartialInstallmentSumIsValid
    && isInstallmentDatesTableValid &&
    isInterestDatesTableValid &&
    isFirstInstalmmentDateAfterActivationDateValid && isFirstInterestDateAfterActivationDateValid
    && maxActivationDate > minRepaymentDate &&
    new Date(formValue.ico_date_activation).getTime() >= minActivationDate.getTime()
    : false

  const allowSendToAccept = formValue ? requierdsFieldsToSendForAccept.every(
    (fieldName) => !isEmptyValue(formValue[fieldName]))
    && icoParticipiants['accept'].length > 0
    && localIcoId
    // && (!parentAgreementDisabled ? !isEmptyValue(formValue["ico_agreement_parent"]) : true)
    : false


  const acceptanceData = React.useMemo(() => {
    return ({
      applicant: formValue.applicant,
      ico_date_input: formValue.ico_date_input,
    })
  }, [formValue.applicant, formValue.ico_date_input])

  const [
    memoryIcoDocs,
    onAddMemoryIcoDocs,
    onUpdateMemoryIcoDocs,
    onDeleteMemoryIcoDocs,
    prepareDocsIcosToSend,
    onDownloadOnMemoryFile
  ] = useFileOnMemoryData("docs");

  const {
    docs,
    isDocsLoading,
    hideDocs,
    onDownloadDoc,
    onPreviewDoc,
    refetchDocs,
  } = useDocsData(localIcoId, "ico");

  const { 
    getIcoRegistryFlatList,
    getIcoSelectData,
    getIcoFilteringData,
    getDefaultIcoProductData,
    getDefaultUsersForBorrowerAndLender,
    calculateIcoSchedule,
    getEarliestInterestDate,
    createNewIco,
    getIcoDetails,
    updateIco,
    getInitConsolidationData } = useIcoService();

  const {
    calculateNewSchedule,
    calculateNewConditionValueInPLN,
    checkGroupSumIsEqualConditionValue,
    checkGroupDatesRanges,
    checkFirstDateIsAfterActivationValid,
    checkPartialInstallmentRatesAreLowerThanPartialTranchesDatesIsValid,
    checkFirstInterestDateIsAfterActivationValid
  } = useIcoScheduleCalculationService();


  const createNewIcoFn = useAsyncFn(createNewIco);
  const updateIcoFn = useAsyncFn(updateIco)

  const getCalculatedDataFromBackend = useAsync(() => {

    if (isScheduleUpdated && isScheduleFormValid && allowRecalulate) {
      return calculateIcoSchedule(onPrepareScheduleToBackend(conditionData, formValue.ico_date_activation, formValue.ico_date_repayment, icoSchedules)).then(

        setIcoSchedulesChanged(true)
      ).catch(err => {
        setPrepareData(false)
        setSchedules({ 'tranche': [], 'installment': [], 'interest': [] })
        setScheduleUpdated(false)
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(err.data),
          t("snackbar_alert.an_unknown_error_occurred")
        );
      });
    }
    return Promise.resolve([])
  }, [isScheduleUpdated, isScheduleFormValid, allowRecalulate]);

  const earliestInterestDate = useAsync(() => {
    if (!isEmptyValue(conditionData.interest)) {
      return getEarliestInterestDate(conditionData.interest)
        .then(resp => {
          setMinActivationDate(new Date(resp))
        })
        .catch(err => {
          snackbarAlert.openErrorSnackbarAlert(
            getErrorMsg(err.data),
            t("snackbar_alert.an_unknown_error_occurred")
          );
        });
    }
    return Promise.resolve({})
  }, [conditionData.interest]);


  const onCreateIco = (data) => {
    setIsSavingData(true);
    createNewIcoFn
      .execute(data)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.created_new_request")
        );
        setDialogMode("edit")
        setIsSavingData(false);
        setLocalIcoId(res.id)
      })
      .catch((error) => {
        setIsSavingData(false);
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(error.data),
          t("snackbar_alert.occurred_error_during_creating_new_request")
        );
      });

  };

  const onUpdateIco = (data, action = undefined) => {
    setIsSavingData(true);
    updateIcoFn
      .execute(localIcoId, data)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.update_request")
        );
        setIsSavingData(false);
        if (action === ICO_CANCEL_ACTION) {
          props.onClose()
        }
        icoDetailsData.refetch()
      })
      .catch((error) => {
        setIsSavingData(false);
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(error.data),
          t("snackbar_alert.occurred_error_during_updating_request")
        );
      });
  };

  const consolidatedIcosData = useAsync(
    () => {
      if (props.consolidationList.length > 0) {
        return  getIcoRegistryFlatList({ico : props.consolidationList})
      }
      return Promise.resolve([])
    },
    [props.consolidationList]);



  useEffect(() => {
    if (consolidatedIcosData.loading) {
      return;
    }
    setLocalConsolidatedIcos(consolidatedIcosData.value)

  }, [consolidatedIcosData.loading]); 


  useEffect(() => {
    if (getCalculatedDataFromBackend.loading) {
      return;
    }
    if (getCalculatedDataFromBackend.value && !isEmptyObject(getCalculatedDataFromBackend.value)) {
      setSchedules((prev) => ({ ...prev, 'interest': getCalculatedDataFromBackend.value.map((interest) => ({ ...interest, editDate: Date.now() })) }))
      setPrepareData(false)
    }
  }, [getCalculatedDataFromBackend.loading]); 


  useEffect(() => {

    if (isEmptyValue(conditionData.interest)) {
      setMinActivationDate(new Date("1900-01-01"))
    }
  }, [conditionData.interest]);


  useEffect(() => {
    if (!isEmptyValue(mainData.ico_date_activation)) {
   setIsActivationDateAfterMinInterestDate(new Date(mainData.ico_date_activation) >= minActivationDate)
    }else{
   setIsActivationDateAfterMinInterestDate(true)
    }
  }, [mainData.ico_date_activation,  minActivationDate]);

  const defaultParticipiantsFn = useAsyncFn(getDefaultUsersForBorrowerAndLender)


  const onChangeCustomMainFormAutocomplete = useCallback((e, value_object, value_key, state_value_name) => {
    const { name, value, index } = onChangeAutocompleteLocal(e, value_object, value_key, state_value_name);
    if (value === ICO_AGREEMENT_CONTRACT) {
      setFormValue((prev) => ({ ...prev, "ico_agreement_reason": undefined, "ico_agreement_parent": null }));
      setParentAgreementDisabled(true)
    } else {
      setParentAgreementDisabled(false)
    }
    setFormValue((prev) => ({ ...prev, [name]: value }));
    let selectedBorrowerObj = selectedBorrower.current
    let selectedLenderObj = selectedLender.current
    let ownersFilterData = filteringDataLocal.owners
    if (name === "borrower" || name === "lender") {
      if (name === "borrower") {
        selectedBorrower.current = ownersFilterData.find((o) => o.id === value)
        selectedBorrowerObj = ownersFilterData.find((o) => o.id === value)

      } else {
        selectedLender.current = ownersFilterData.find((o) => o.id === value)
        selectedLenderObj = ownersFilterData.find((o) => o.id === value)
      }
      if (isFullEditable) {
        getDefaultAcceptaptorsFn(selectedBorrowerObj?.id, selectedLenderObj?.id, getFlatParticipinatsList())
      }
    }

  }, [filteringDataLocal, onChangeAutocompleteLocal, selectedBorrower.current, selectedLender.current, icoParticipiants]);

const getDefaultAcceptaptorsFn = useCallback((borrowerId, lenderId, participiantList) =>{

  defaultParticipiantsFn
  .execute(
    {
      borrower: borrowerId,
      lender: lenderId,
      exclude_ids: participiantList.filter((participiant) => participiant.icopart_is_default_participant === false).map((participiant) => participiant.icopart_person)
    }
  )
  .then((res) => {
    let acceptParticipiants = setParticipiantsByKind(res['accept'], 'accept')
    let informedParticipiants = setParticipiantsByKind(res['informed'], 'informed')
    setParticipiants((prev) => ({ ...prev, 'accept': acceptParticipiants, 'informed': informedParticipiants }))
  })
  .catch((error) => {
    console.log('err', error)
  });
}, [filteringDataLocal, onChangeAutocompleteLocal, selectedBorrower.current, selectedLender.current, icoParticipiants])

  const onChangeCustomConditionFormAutocomplete = useCallback((e, value_object, value_key, state_value_name) => {
    const { name, value, index } = onChangeAutocompleteLocal(e, value_object, value_key, state_value_name);
    if (name === "condition_disburement") {
      if (value === "once") {
        setFormValue((prev) => ({ ...prev, "disburement_amount": 1 }));

      } else {
        setFormValue((prev) => ({ ...prev, "disburement_amount": undefined }));
      }
    } else if (name === "condition_settlement") {
      if (value === "once") {
        setFormValue((prev) => ({ ...prev, "settlement_amount": 1 }));
      } else {
        setFormValue((prev) => ({ ...prev, "settlement_amount": undefined }));
      }

    } else if (name === "currency") {
      setSelectedCurrency(value_object)
    }
  }, [, onChangeAutocompleteLocal]);



  useEffect(() => {
    let currencyValues = filteringDataLocal?.curr_rate ? filteringDataLocal.curr_rate : []
    let currencyValue = currencyValues.find((cv) => cv.currency_id === selectedCurrency?.id)?.currat_value

    setFormValue((prev) => ({ ...prev, "condition_value_pln": (calculateNewConditionValueInPLN(currencyValue, formValue?.condition_value)) }));
  }, [selectedCurrency, formValue?.condition_value, filteringDataLocal?.curr_rate]);


  useEffect(() => {
    let interestValues = filteringDataLocal?.interest_rates ? filteringDataLocal.interest_rates : []
    if (!isEmptyValue(formValue?.interest)) {
      setInterestRateValue(interestValues.find((iv) => iv.interest_id === formValue?.interest)?.intrat_value)
    } else {
      setInterestRateValue(0)
    }

  }, [formValue?.interest]);



  useEffect(() => {
    if (icoSchedules.installment.length > 0 && icoSchedules.interest.length > 0 && formValue?.ico_date_activation) {
      let icoActivationDate = new Date(formValue?.ico_date_activation)
      setFirstInstalmmentDateAfterActivationDateValid(checkFirstDateIsAfterActivationValid(icoActivationDate, icoSchedules.installment[0].icoschedule_due_date))
      setFirstInterestDateAfterActivationDateValid(checkFirstInterestDateIsAfterActivationValid(icoActivationDate, icoSchedules.interest[0].icoschedule_due_date))
    }

  }, [formValue?.ico_date_activation, icoSchedules.installment, icoSchedules.interest])


  useEffect(() => {
    if (formValue?.ico_date_activation) {
      let minDate = new Date(formValue?.ico_date_activation)
      minDate.setDate(minDate.getDate() + 1);
      setMinRepaymentDate(minDate)
    }
  }, [formValue?.ico_date_activation])

  useEffect(() => {
    if (formValue?.ico_date_repayment) {
      let maxDate = new Date(formValue?.ico_date_repayment)
      maxDate.setDate(maxDate.getDate() - 1);
      setMaxActivationDate(maxDate)
    }
  }, [formValue?.ico_date_repayment])

  useEffect(() => {
    if (!isFullEditable) {
      switch (dialogMode) {
        case "preview":
          setEditableFieldsByMode(PREVIEW_MODE_FIELDS)
          break;
        case "edit":
          setEditableFieldsByMode(EDIT_MODE_FIELDS)
          break;
        case "changes_wo_appendix":
          setEditableFieldsByMode(CHANGE_COND_WO_APPENDIX)
          break;
        case "side_changes":
          setEditableFieldsByMode(CHANGE_AGREEMENT_SIDES)
          break;
      }
    }

  }, [dialogMode, isFullEditable]);


  const getDialogTitle = useCallback(() => {

    switch (dialogMode) {
      case "new":
        return t(`dialog.ico_details_dialog.ico_new_request`);
      case "edit":
        return t(`dialog.ico_details_dialog.ico_edit_agreement`);
      case "preview":
        return t(`dialog.ico_details_dialog.ico_agreement_preview`);
      case "changes_wo_appendix":
        return t(`dialog.ico_details_dialog.changes_wo_appendix`);
      case "appendix":
        return t(`dialog.ico_details_dialog.appendix_changes`);
      case "side_changes":
        return t(`dialog.ico_details_dialog.ico_side_changes`);
      case "consolidation":
        return t(`dialog.ico_details_dialog.consolidation`);
    }
  }, [dialogMode])


  const icoFilteringData = useAsync(() => {
    if (props.readOnly) {
      return getIcoFilteringData()
    }
    return getIcoSelectData()
  }, []);

  useEffect(() => {
    if (filteringDataLocal && filteringDataLocal.owners !== undefined) {
      selectedBorrower.current = filteringDataLocal.owners.find((o) => o.id === formValue.borrower)
      selectedLender.current = filteringDataLocal.owners.find((o) => o.id === formValue.lender)
    }

  }, [formValue.borrower, formValue.lender, filteringDataLocal])

  const setParticipiantsByKind = (data, kind) => {
    if (data === undefined) data = []
    let tempParticipiants = { ...icoParticipiants }
    let kindParticipiants = tempParticipiants[kind] ? tempParticipiants[kind] : []
    let nonDefaultParticipiants = kindParticipiants.filter(icoPart => icoPart.icopart_is_default_participant === false)
    let participiantsToRemove = kindParticipiants.filter(icoPart => icoPart.icopart_is_default_participant && icoPart.participiant_id).map((p) => ({ ...p, isEdited: true, icopart_active: false }))
    setParticipiantsToRemove((prev) => [...prev, ...participiantsToRemove])
    return [...nonDefaultParticipiants, ...data]

  }

  const icoDetailsData = useAsync(
    () => {
      if (localIcoId && ["edit", "preview", "side_changes", "changes_wo_appendix"].includes(dialogMode)) {
        return getIcoDetails(localIcoId)
      }
      else if (props.dialogParentId) {
        return getIcoDetails(props.dialogParentId)
      }
      else if (props.dialogCopyId) {
        return getIcoDetails(props.dialogCopyId)
      }
      else if (props.consolidationList.length > 0){
        return getInitConsolidationData({consolidated_icos : props.consolidationList})
      }
      return Promise.resolve({ ...formValue })
    },
    [localIcoId, props.dialogParentId, props.dialogCopyId]);


  const defaultIcoProduct = useAsync(getDefaultIcoProductData)

  useEffect(() => {
    if (icoDetailsData.loading) {
      return;
    }
    if (!isEmptyObject(icoDetailsData.value) && localIcoId) {
      setFormValue(icoDetailsData.value);
      setScheduleUpdated(true)
      let participiants = icoDetailsData.value.participiants ? icoDetailsData.value.participiants : []
      setParticipiants({
        'accept': participiants.filter((p) => p.icopart_role_acceptor).map((p) => ({ ...p, participiant_kind: "accept" })),
        'informed': participiants.filter((p) => p.icopart_role_informed).map((p) => ({ ...p, participiant_kind: "informed" })),
        'doer': participiants.filter((p) => p.icopart_role_doer).map((p) => ({ ...p, participiant_kind: "doer" }))
      })
      let schedules = icoDetailsData.value.schedules ? icoDetailsData.value.schedules : []
      setSchedules({
        'tranche': schedules.filter((s) => s.icoschedule_kind === "tranche"),
        'installment': schedules.filter((s) => s.icoschedule_kind === "installment"),
        'interest': schedules.filter((s) => s.icoschedule_kind === "interest")
      })
      setAllowCancelIco(icoDetailsData.value.allow_cancel_ico)
      if (["edit", "consolidation", "appendix"].includes(dialogMode)) {
        setFullEditable(icoDetailsData.value.ico_is_editable)
      }
      setTranchesChangedByUser(icoDetailsData.value.condition_schedule_transches_set_by_user)
      setInstallmentsChangedByUser(icoDetailsData.value.condition_schedule_instalmments_set_by_user)
      setInterestsChangedByUser(icoDetailsData.value.condition_schedule_interests_set_by_user)
      setIcoSchedulesChanged(false)
      setParentAgreementDisabled(icoDetailsData.value.ico_agreement === ICO_AGREEMENT_CONTRACT)
      setLocalConsolidatedIcos(icoDetailsData.value.consolidated_agreements)
      setLocalIcoPreBos(icoDetailsData.value.pre_bo)
    }
    else if (!isEmptyObject(icoDetailsData.value) && (props.dialogParentId || props.dialogCopyId)) {
      setFormValue({
        ...icoDetailsData.value,
        ...formValue,
        ico_agreement_parent: props.dialogParentId,
        ico_agreement: props.dialogCopyId ? ICO_AGREEMENT_CONTRACT : ICO_AGREEMENT_APPENDIX,
        ico_nr: undefined,
        draft: undefined,
        ico_name: undefined,
        ico_agreement_reason: undefined,
        ico_note_finance: undefined,
        ico_note_legal: undefined,
        ico_date_input: undefined
      });
      setScheduleUpdated(false)
      let participiants = icoDetailsData.value.participiants ? icoDetailsData.value.participiants : []
      setParticipiants({
        'accept': participiants.filter((p) => p.icopart_role_acceptor).map((p) => ({ ...p, participiant_id: undefined, participiant_kind: "accept" })),
        'informed': participiants.filter((p) => p.icopart_role_informed).map((p) => ({ ...p, participiant_id: undefined, participiant_kind: "informed" })),
        'doer': participiants.filter((p) => p.icopart_role_doer).map((p) => ({ ...p, participiant_id: undefined, participiant_kind: "doer" }))
      })
    
      setFullEditable(true)
      setAllowCancelIco(false)
      setTranchesChangedByUser(false)
      setInstallmentsChangedByUser(false)
      setInterestsChangedByUser(false)
      setIcoSchedulesChanged(false)
      setParentAgreementDisabled(false)
      getDefaultAcceptaptorsFn(icoDetailsData.value.borrower, icoDetailsData.value.lender,participiants)
    }
    else if (!isEmptyObject(icoDetailsData.value) && props.consolidationList.length > 0) {
      setFormValue({
        ...icoDetailsData.value,
        ...formValue,
        ico_agreement: ICO_AGREEMENT_CONTRACT,
        ico_nr: undefined,
        draft: undefined,
        ico_name: undefined,
        ico_agreement_reason: undefined,
        ico_note_finance: undefined,
        ico_note_legal: undefined,
        ico_date_input: undefined
      });
      setScheduleUpdated(false)
      let participiants = icoDetailsData.value.participiants ? icoDetailsData.value.participiants : []
      setParticipiants({
        'accept': participiants.filter((p) => p.icopart_role_acceptor).map((p) => ({ ...p, participiant_id: undefined, participiant_kind: "accept" })),
        'informed': participiants.filter((p) => p.icopart_role_informed).map((p) => ({ ...p, participiant_id: undefined, participiant_kind: "informed" })),
        'doer': participiants.filter((p) => p.icopart_role_doer).map((p) => ({ ...p, participiant_id: undefined, participiant_kind: "doer" }))
      })
      setFullEditable(true)
      setAllowCancelIco(false)
      setTranchesChangedByUser(false)
      setInstallmentsChangedByUser(false)
      setInterestsChangedByUser(false)
      setIcoSchedulesChanged(false)
    }
  }, [icoDetailsData.loading, isPreviewMode, props.dialogParentId]);

  useEffect(() => {
    if (icoFilteringData.loading) {
      return;
    }
    if (icoFilteringData.value) {
      setFilteringDataLocal(icoFilteringData.value);

    }
  }, [icoFilteringData.loading]);

  useEffect(() => {
    if (defaultIcoProduct.loading) {
      return;
    }
    if (defaultIcoProduct.value && localIcoId === undefined && formValue?.ico_product === undefined) {
      setFormValue((prev) => ({
        ...prev,
        ico_product: defaultIcoProduct.value.id,
      }));
    }
  }, [defaultIcoProduct.loading]);


  
  useEffect(() => {
    conditionValue.current = parseFloat(formValue?.condition_value)
  }, [formValue?.condition_value]);

  useEffect(() => {
    if (isEmptyValue(selectedCurrency) && filteringDataLocal && formValue?.currency){
      setSelectedCurrency(filteringDataLocal['currencies'].find((curr) =>formValue?.currency === curr.id))
    }
  }, [filteringDataLocal,formValue?.currency, selectedCurrency]);


  const onChangeByRow = useCallback((e, index, kind) => {
    setParticipiants((prev) => {
      let oldState = { ...prev };
      let kindState = [...oldState[kind]]
      let notRemovedUsers = kindState.filter((p) => p.icopart_active)
      let participiant = { ...notRemovedUsers[index] }
      const { name, value } = e.target;
      participiant[name] = value
      participiant.isEdited = true
      if (value) {
        participiant.icopart_is_default_participant = false
      } else if (kind === "accept" || kind == "informed") {
        participiant.icopart_is_default_participant = true
      }
      kindState[index] = { ...participiant }

      return { ...oldState, [kind]: [...kindState] };
    });
  }, [])

  const onRemoveByRow = useCallback((index, kind) => {
    setParticipiants((prev) => {
      let oldState = { ...prev };
      let kindState = [...oldState[kind]]
      let participiant = { ...kindState[index] }
      if (participiant.participiant_id) {
        participiant.icopart_active = false
        participiant.isEdited = true
        setParticipiantsToRemove((prev) => [...prev, participiant])
      }
      kindState.splice(index, 1);
      return { ...oldState, [kind]: [...kindState] };
    });
  }, [])

  const onAddParticipiant = useCallback((user) => {
    setParticipiants((prev) => ({ ...prev, [selectedParticipiantKind.current]: [...prev[selectedParticipiantKind.current], getNewParticipiantData(user)] }))
    onCloseAddUserDialog();
  }, [])


  const onCloseAddUserDialog = () => {
    selectedParticipiantKind.current = undefined
    handleCloseUserDialog()
  }

  const onOpenUserDialog = useCallback((kind) => {
    selectedParticipiantKind.current = kind
    handleOpenUserDialog()
  }, [])

  const onRecalculateSchedule = useCallback((e) => {
    setPrepareData(true)
    setSchedules((prev) => {
      let updatedSchedule = calculateNewSchedule(formValue.ico_date_activation, formValue.ico_date_repayment, prev, conditionData, interestRateValue, areTranchesChangedByUser, areInstallmentsChangedByUser, areInterestsChangedByUser)
      return updatedSchedule
    })
    setScheduleUpdated(true)
    setAllowRecalulate(true)
  }, [isScheduleUpdated, conditionData, formValue.ico_date_activation, formValue.ico_date_repayment, interestRateValue, areTranchesChangedByUser, areInstallmentsChangedByUser])



  const onChangeScheduleData = useCallback((name, value, index, kind) => {
    setSchedules((prev) => {
      let oldState = { ...prev };
      let kindState = [...oldState[kind]]
      let element = { ...kindState[index] }

      if (name === "icoschedule_amount_to_pay") {
        element[name] = value

      } else {
        element[name] = value
        if (kind === "tranche" && index === 0) {
          setFormValue((prev) => ({ ...prev, ico_date_activation: DateService.convertDateToFormatYYYYMMDD(value) }))
          setTranchesChangedByUser(false)
          setIcoConditionChanged(true)
        } else if ((kind === "installment" || kind === "interest") && index === kindState.length - 1) {
          setFormValue((prev) => ({ ...prev, ico_date_repayment: DateService.convertDateToFormatYYYYMMDD(value) }))
          setInstallmentsChangedByUser(false)
          setIcoConditionChanged(true)
          setInterestsChangedByUser(false)
          setIcoConditionChanged(true)
        }
      }
      element.editDate = Date.now()
      kindState[index] = { ...element }
      let newScheduleData = { ...oldState, [kind]: [...kindState] }
      if (name === "icoschedule_amount_to_pay") {
        switch (kind) {
          case "tranche":
            setTranchesSumTableValid(checkGroupSumIsEqualConditionValue(conditionValue.current, kindState))
            break;
          case "installment":
            setInstallmentSumTableValid(checkGroupSumIsEqualConditionValue(conditionValue.current, kindState))
            break;
        }
      }
      if (name === "icoschedule_due_date") {
        switch (kind) {
          case "tranche":
            setTranchesDatesTableValid(checkGroupDatesRanges(kindState, index))
            break;
          case "installment":
            setInstallmentDatesTableValid(checkGroupDatesRanges(kindState, index))
            break;
          case "interest":
            setInteresDatesTableValid(checkGroupDatesRanges(kindState, index))
            break;
        }
      }
      for (let i = 0; i < newScheduleData["installment"].length - 1; i++) {
        let condition = checkPartialInstallmentRatesAreLowerThanPartialTranchesDatesIsValid(i, newScheduleData["installment"][i].icoschedule_due_date, newScheduleData["tranche"], newScheduleData["installment"])

        if (!condition) {
          setCheckPartialInstallmentSumIsValid(condition)
          break;
        }
        setCheckPartialInstallmentSumIsValid(condition)
      }
      return newScheduleData;
    });
  }, [])



  const getFlatParticipinatsList = () => {
    return [...icoParticipiants['accept'], ...icoParticipiants['informed'], ...icoParticipiants['doer']]
  }

  const handleToSaveChangesInIcoConfigurator = (data, action = undefined) => {
    if (localIcoId) {
      onUpdateIco(data, action);

    } else {
      onCreateIco(data);
    }
  };

  const onSaveData = useCallback(() => {
    handleToSaveChangesInIcoConfigurator(onPrepareDataToSave());
  }, [
    formValue,
    isScheduleFormValid,
    participiantsToRemove,
    icoSchedules,
    icoParticipiants,
    areIcoConditionChanged,
    areInstallmentsChangedByUser,
    areInterestsChangedByUser,
    areTranchesChangedByUser,
    memoryIcoDocs
  ]);

  const onSendIcoToAccept = useCallback(() => {
    handleToSaveChangesInIcoConfigurator(onPrepareDataToSave(ICO_SEND_TO_ACCEPT_ACTION), ICO_SEND_TO_ACCEPT_ACTION);
  }, [
    formValue,
    isScheduleFormValid,
    participiantsToRemove,
    icoSchedules,
    icoParticipiants,
    areIcoConditionChanged,
    areInstallmentsChangedByUser,
    areInterestsChangedByUser,
    areTranchesChangedByUser,
    memoryIcoDocs
  ])

  const onCancelIco = useCallback(() => {
    handleToSaveChangesInIcoConfigurator({ ico: { id: localIcoId }, action: ICO_CANCEL_ACTION }, ICO_CANCEL_ACTION);
  }, [formValue, fieldsToSaveInIco])

  const onPrepareDataToSave = (action = undefined) => {
    let dataToSend = {};
    dataToSend.ico = getFieldsFromObject(formValue, fieldsToSaveInIco)
    dataToSend.ico_condition = {}
    dataToSend.ico_schedule = {}
    dataToSend.ico_participiants = []
    dataToSend.files = []
    if (action !== ICO_CANCEL_ACTION) {
      if (areIcoConditionChanged || localIcoId === undefined || props.dialogParentId || props.dialogCopyId) {
        dataToSend.ico_condition = getConditionDataToSave(formValue)
      }
      if (isScheduleFormValid && areIcoSchedulesChanged) {
        dataToSend.ico_schedule = onPrepareScheduleToBackend(formValue, formValue.ico_date_activation, formValue.ico_date_repayment, icoSchedules)
      }
      dataToSend.ico_participiants = onPrepareParticipiantsToBackend()
      if (!localIcoId) {
        dataToSend.files = prepareDocsIcosToSend(memoryIcoDocs)
      }
      if (props.dialogMode === "consolidation"){
        dataToSend.consolidation_list = props.consolidationList
      }
    }
    dataToSend.action = action;
    return dataToSend;
  };

  const getConditionDataToSave = (data) => {
    let dataToSave = getFieldsFromObject(data, fieldsToSaveInIcoCondition)
    if (data.condition_id) {
      dataToSave.id = data.condition_id
    }
    dataToSave.condition_schedule_transches_set_by_user = areTranchesChangedByUser
    dataToSave.condition_schedule_instalmments_set_by_user = areInstallmentsChangedByUser
    dataToSave.condition_schedule_interests_set_by_user = areInterestsChangedByUser
    return dataToSave
  }


  const onPrepareScheduleToBackend = (conditionData, ico_date_activation, ico_date_repayment, updatedSchedule) => {
    return {
      tranche: updatedSchedule["tranche"].filter(item => item !== undefined).map((tranche) => ({ ...tranche, icoschedule_kind: "tranche", icoschedule_amount_to_pay: parseFloat(tranche.icoschedule_amount_to_pay), icoschedule_start_date: DateService.convertDateToFormatYYYYMMDD(tranche.icoschedule_start_date), icoschedule_due_date: DateService.convertDateToFormatYYYYMMDD(tranche.icoschedule_due_date) })),
      installment: updatedSchedule["installment"].filter(item => item !== undefined).map((installment) => ({ ...installment, icoschedule_kind: "installment", icoschedule_amount_to_pay: parseFloat(installment.icoschedule_amount_to_pay), icoschedule_start_date: DateService.convertDateToFormatYYYYMMDD(installment.icoschedule_start_date), icoschedule_due_date: DateService.convertDateToFormatYYYYMMDD(installment.icoschedule_due_date) })),
      interest: updatedSchedule["interest"].filter(item => item !== undefined).map((interest) => ({ ...interest, icoschedule_kind: "interest", icoschedule_start_date: DateService.convertDateToFormatYYYYMMDD(interest.icoschedule_start_date), icoschedule_due_date: DateService.convertDateToFormatYYYYMMDD(interest.icoschedule_due_date) })),
      ico_date_repayment: typeof (ico_date_repayment) === "string" ? ico_date_repayment : DateService.convertDateToFormatYYYYMMDD(ico_date_repayment),
      ico_date_activation: typeof (ico_date_repayment) === "string" ? ico_date_activation : DateService.getIsoDateFromDatetime(ico_date_activation),
      condition_margin: conditionData.condition_margin,
      interest_id: conditionData.interest,
      condition_include_leap_years : conditionData.condition_include_leap_years
    }
  }

  const onPrepareParticipiantsToBackend = () => {
    let dataToBackend = []
    let participiants = [...getFlatParticipinatsList(), ...participiantsToRemove]
    for (let p of participiants) {
      let partData = {}
      if (p.participiant_id) {
        partData.id = p.participiant_id
      }
      partData.icopart_person = p.icopart_person
      partData.icopart_is_default_participant = p.icopart_is_default_participant
      partData.icopart_role_acceptor = p.participiant_kind === "accept"
      partData.icopart_role_informed = p.participiant_kind === "informed"
      partData.icopart_role_doer = p.participiant_kind === "doer"
      partData.icopart_note = p.icopart_note
      partData.icopart_active = p.icopart_active
      if (p.participiant_id === undefined || p.isEdited) {
        dataToBackend.push(partData)
      }
    }
    return dataToBackend
  }

  const isLoading = icoDetailsData.value === undefined || filteringDataLocal === undefined || defaultIcoProduct.value === undefined

  const isEditableField = useCallback((fieldName) => {
    return (editableFieldsByMode.includes(fieldName) || isFullEditable) && !isSavingData
  }, [isFullEditable, editableFieldsByMode, isSavingData])

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={getDialogTitle()}
      allwaysFullScreen={true}
      showTopCloseButton={false}
      showCustomFooter={false}
      showDialogActions={false}
      titleSx={{ height: `${64}px`, marginLeft: `${openMiniDrawerWidth}px` }}

    >
      <IsTestingAppWrapper />
      <FunctionsDrawer
        drawerType={FUNCTION_DRAWER_TYPE_ICOS}
        readOnly={isSavingData}
        onClose={props.onClose}
        onSaveChanges={onSaveData}
        onCancelIco={onCancelIco}
        onSendIcoToAccept={onSendIcoToAccept}
        disableSaveButton={!isScheduleUpdated || isSavingData || isPreviewMode}
        allowCancelIco={allowCancelIco}
        allowSendToAccept={allowSendToAccept && isFullEditable}
        isSavingData={isSavingData}
        icoId={localIcoId}
      >
        <LoaderWrapper showLoader={isLoading} >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
          {
            localIcoPreBos.length > 0 &&
            <Grid item xs={12}>
                 <BoxWithTypography
                style={{ padding: "10px" }}
                label={t(`dialog.ico_details_dialog.bo`)}
                component={"div"}
              >
              <BaseTable
                headers={TableService.getHeaderLables(
                  ICO_PREBO_TABLE_FIELDS_CONFIG
                )}
                rows={TableService.getPreparedDataForBaseTable(
                  ICO_PREBO_TABLE_FIELDS_CONFIG,
                  localIcoPreBos,
                  [],
                  []
                )}
                showNoRecordsPaper={false}
              />
              </BoxWithTypography>
            </Grid>
          }
            {localConsolidatedIcos.length > 0 &&
            <Grid item xs={12}>
              <BaseTable
                headers={TableService.getHeaderLables(
                  ICO_REGISTRY_TABLE_FIELDS_CONFIG
                )}
                rows={TableService.getPreparedDataForBaseTable(
                  ICO_REGISTRY_TABLE_FIELDS_CONFIG,
                  localConsolidatedIcos,
                  [],
                  []
                )}
                showNoRecordsPaper={false}
              />
            </Grid>}
            <Grid item xs={12}>
              <BaseBox>
                {filteringDataLocal &&
                  <IcoMainDataForm
                    isEditableField={isEditableField}
                    icoData={mainData}
                    readOnlyOwners={localConsolidatedIcos.length > 0}
                    icoId={localIcoId}
                    onChange={onChangeLocal}
                    onChangeDate={onChangeDateLocal}
                    onChangeAutocomplete={onChangeAutocompleteLocal}
                    onChangeCustomAutocomplete={onChangeCustomMainFormAutocomplete}
                    filteringData={filteringDataLocal}
                    parentAgreementDisabled={parentAgreementDisabled}
                  />}
              </BaseBox>
            </Grid>

            <Grid item xs={12}>
              <BoxWithTypography
                style={{ padding: "10px" }}
                label={t(`dialog.ico_details_dialog.finance_conditions`)}
                component={"div"}
              >

                {filteringDataLocal &&
                  <IcoFinanceConditionsForm
                    readOnly={!isFullEditable || isSavingData || isPreviewMode}
                    icoData={conditionData}
                    icoId={localIcoId}
                    onChange={onChangeLocal}
                    onChangeDate={onChangeDateLocal}
                    onChangeAutocomplete={onChangeAutocompleteLocal}
                    onChangeCustomAutocomplete={onChangeCustomConditionFormAutocomplete}
                    filteringData={filteringDataLocal}
                    selectedCurrency={selectedCurrency?.currency_name}
                  />}
              </BoxWithTypography>
            </Grid>

            <Grid item xs={12}>
              <BoxWithTypography
                error={!isScheduleUpdated}
                style={{ padding: "10px" }}
                label={t(`dialog.ico_details_dialog.schedule`)}
                component={"div"}
              >
                {getCalculatedDataFromBackend.loading || prepareData ?
                  <LoaderWrapper showLoader /> :
                  <IcoScheduleForm
                    readOnly={!isEditableField("schedules")}
                    icoSchedules={icoSchedules}
                    editableFieldsByMode={editableFieldsByMode}
                    isScheduleFormValid={isScheduleFormValid}
                    isScheduleUpdated={isScheduleUpdated}
                    setScheduleUpdated={setScheduleUpdated}
                    onRecalculateSchedule={onRecalculateSchedule}
                    onDataChange={onChangeScheduleData}
                    setTranchesChangedByUser={setTranchesChangedByUser}
                    setInstallmentsChangedByUser={setInstallmentsChangedByUser}
                    setInterestsChangedByUser={setInterestsChangedByUser}
                    areTranchesChangedByUser={areTranchesChangedByUser}
                    areInstallmentsChangedByUser={areInstallmentsChangedByUser}
                    areInterestsChangedByUser={areInterestsChangedByUser}
                    isTanchesSumTableValid={isTanchesSumTableValid}
                    isTanchesDatesTableValid={isTanchesDatesTableValid}
                    isInstallmentSumTableValid={isInstallmentSumTableValid}
                    isInstallmentDatesTableValid={isInstallmentDatesTableValid}
                    isInterestDatesTableValid={isInterestDatesTableValid}
                    isFirstInstalmmentDateAfterActivationDateValid={isFirstInstalmmentDateAfterActivationDateValid}
                    isFirstInterestDateAfterActivationDateValid={isFirstInterestDateAfterActivationDateValid}
                    checkPartialInstallmentSumIsValid={checkPartialInstallmentSumIsValid}
                    isActivationDateAfterMinInterestDate = {isActivationDateAfterMinInterestDate}
                  />}
              </BoxWithTypography>
            </Grid>

            <Grid item xs={12}>
              <BoxWithTypography
                style={{ padding: "10px" }}
                label={t(`dialog.ico_details_dialog.acceptations`)}
                component={"div"}
              >
                {filteringDataLocal &&
                  <IcoAcceptanceForm
                    readOnly={!isFullEditable || isSavingData}
                    allowAddNewDoerInforemdParticipiant={isEditableField("participiants_informed_doer") && !isSavingData}
                    showDates={!isFullEditable}
                    icoData={acceptanceData}
                    icoId={localIcoId}
                    onChange={onChangeLocal}
                    onChangeDate={onChangeDateLocal}
                    onChangeAutocomplete={onChangeAutocompleteLocal}
                    filteringData={filteringDataLocal}
                    onEditData={onChangeByRow}
                    onRemoveData={onRemoveByRow}
                    onAddData={onOpenUserDialog}
                    icoParticipiants={icoParticipiants}

                  />}
              </BoxWithTypography>
            </Grid>
            <Grid item xs={12}>
              <BaseBox>
                {localIcoId ? (
                  <FileUploadList
                    addEnclosureButtonProps={{ size: "mini" }}
                    enclosures={docs}
                    fileType={"docs"}
                    onPreviewEnclosure={onPreviewDoc}
                    onDownloadEnclosure={onDownloadDoc}
                    onDeleteEnclosure={hideDocs}
                    canRemoveEnclosures={true}
                    refetchDocs={refetchDocs}
                    readOnly={!isEditableField("docs") || isDocsLoading}
                    ico={localIcoId}
                    availableDocsRestrictedTypes={availableDocsRestrictedTypes}

                  />
                ) : (<FileUploadList
                  addEnclosureButtonProps={{ size: "mini" }}
                  enclosures={memoryIcoDocs}
                  onDownloadEnclosure={onDownloadOnMemoryFile}
                  onAddFile={onAddMemoryIcoDocs}
                  onDeleteEnclosure={onDeleteMemoryIcoDocs}
                  onUpdateEnclosure={onUpdateMemoryIcoDocs}
                  canRemoveEnclosures={true}
                  showDetailsButton={false}
                  fileType={"docs"}
                  filesOnMemory={true}
                  multiple={true}
                  readOnly={isDocsLoading || isSavingData}
                  availableDocsRestrictedTypes={availableDocsRestrictedTypes}

                />)
                }
              </BaseBox>
            </Grid>


            <Grid item xs={12}>
              <BoxWithTypography
                style={{ padding: "10px" }}
                label={t(`dialog.ico_details_dialog.transfers`)}
                component={"div"}
              >
                <IcoTransfersForm
                  transfers={transfers}
                />
              </BoxWithTypography>
            </Grid>
          </Grid>
        </LoaderWrapper>
      </FunctionsDrawer>

      {openUserDialog &&
        <SelectUserDialog
          open={openUserDialog}
          onClose={onCloseAddUserDialog}
          searchParams={{ 'is_active': true, 'is_external': false, 'exclude_ids': getFlatParticipinatsList().map((participiant) => participiant.icopart_person) }}
          returnObject={true}
          onSubmit={onAddParticipiant}
        />
      }

    </BasicDialog>
  );
};

IcoConfiguratorDialog.propTypes = {
  icoId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  readOnly: PropTypes.bool,
  dialogMode: PropTypes.oneOf([
    "new",
    "edit",
    "preview",
    "changes_wo_appendix",
    "appendix",
    "side_changes",
    "consolidation"
  ]),
  consolidationList: PropTypes.array
};

IcoConfiguratorDialog.defaultProps = {
  open: false,
  readOnly: false,
  dialogMode: "new",
  consolidationList: []
};

export default IcoConfiguratorDialog;
