import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import FullWidthTabs from "../FullWidthTabs/FullWidthTabs";
import IcoProductsTab from "./IcoProductsTab";
import useIcoService from "../../../services/icoService";
import { useAsync } from "../../../hooks/useAsync";
import CurrencyTab from "./CurrencyTab";
import CurrencyValueTab from "./CurrencyValueTab";
import InterestTab from "./InterestTab/InterestTab";
import InterestRateTab from "./InterestRateTab/InterestRateTab";
import { useSearchParams } from "react-router-dom";




const IcoAdminTabs = React.memo((props) => {
  const { t } = useTranslation();
  const [startTab, setStartTab] = useState("0")
  const [searchParams] = useSearchParams();

  const { getIcoFilteringData } = useIcoService();


  const icoFilteringData = useAsync(getIcoFilteringData);

  useEffect(() => {
    let tab = searchParams.get('interest_tab')
    if (tab) {
      setStartTab("4")
    }
  }, [searchParams]);

  return (
    <FullWidthTabs
      cleanFilters={true}
      startTab={startTab}
      items={[
        {
          label: t("tabs.ico_admin_tabs.finance_products"),
          content: (
            <IcoProductsTab
              filteringData={icoFilteringData}
            />
          ),
        },
        {
          label: t("tabs.ico_admin_tabs.currencies"),
          content: (
            <CurrencyTab
              filteringData={icoFilteringData}
            />
          ),
        },
        {
          label: t("tabs.ico_admin_tabs.currency_rates"),
          content: (
            <CurrencyValueTab
              filteringData={icoFilteringData}
            />
          ),
        },
        {
          label: t("tabs.ico_admin_tabs.interests"),
          content: (
            <InterestTab
              filteringData={icoFilteringData}
            />
          ),
        },
        {
          label: t("tabs.ico_admin_tabs.interest_rates"),
          content: (
            <InterestRateTab
              filteringData={icoFilteringData}
            />
          ),
        },
      ]}
    />
  );
});

IcoAdminTabs.propTypes = {
  pageName: PropTypes.string,
};

IcoAdminTabs.defaultProps = {
};

export default IcoAdminTabs;
