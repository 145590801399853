import { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useAsync } from "../../../hooks/useAsync";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import AutocompleteField from "../../field/AutocompleteField";
import { Button, Grid } from "@mui/material";
import useItemService from "../../../services/itemService";

const SelectItemDialog= (props) => {
  const { t } = useTranslation();

  const [selectedItem, setSelectedItem] = useState();
 
  const { getItemsSelectList } = useItemService();

  const itemSelectList = useAsync(() => getItemsSelectList ({'last_element': true, 'first_item_number' : props.treeIndex}), [props.treeIndex]);

  const onChangeAutocompleteFieldWithObjectOptions = (
    e,
    value_object,
    value_key, 
    state_value_name
  ) => {
    const newFormValue = Array.isArray(value_object)
      ? value_object.map((option) =>
          typeof option === "object" ? option[value_key] : option
        )
      : value_object[value_key];
    setSelectedItem(newFormValue);
  };
  const isLoading = itemSelectList.loading;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;  

    return (
      <>
        <Grid item xs={12}>
          <AutocompleteField
            name="item"
            label={t("dialog.select_item_dialog.select_item_from_purchase_tree")}
            value={selectedItem}
            options={itemSelectList ? itemSelectList.value : []}
            isObjectOption={true}
            optionLabelKey={"indexed_item_name"}
            addNewValue={false}
            onChange={onChangeAutocompleteFieldWithObjectOptions}
            required
          />
        </Grid>
        <Button
          sx={{ marginTop: "20px" }}
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => props.onSubmit(selectedItem)}
          disabled={!selectedItem}
        >
          {t("confirm")}
        </Button>
      </>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
      titleAlign="center"
      maxWidth="sm"
      title={t("dialog.select_item_dialog.select_item")}
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

SelectItemDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  ticketId: PropTypes.string,
  searchParams : PropTypes.object,
  treeIndex : PropTypes.number
};

SelectItemDialog.defaultProps = {
  treeIndex : 1
};

export default SelectItemDialog;
