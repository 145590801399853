import React, { useCallback, useEffect } from "react";
import PropTypes from 'prop-types';
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import { Grid, IconButton, Typography } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { styled } from "@mui/material/styles";
import './PurchasingTree.css'
import { useTranslation } from "react-i18next";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { SELECTED_ROW_COLOR } from "../../../helpers/constants";
import AutorenewIcon from '@mui/icons-material/Autorenew';

const StyledTreeItem = styled(TreeItem)((props) => {
    const borderColor = "gray";
    return {

        position: "relative",
        "&:before": {
            pointerEvents: "none",
            content: '""',
            position: "absolute",
            width: 32,
            left: -16,
            top: 12,
            borderBottom:
                // only display if the TreeItem is not root node
                props.rootnode === "false" ? `1px dashed ${borderColor}` : "none"
        },
        [`& .${treeItemClasses.group}`]: {
            marginLeft: 16,
            paddingLeft: 18,
            borderLeft: `1px dashed ${borderColor}`
        }
    };
});

const StyledItemTree = React.memo(({
    parentBranchId,
    branch,
    selectedIds,
    handleClickEdit,
    handleClickAdd,
    hadleClickItem,
    expanded,
    onRemoveChildExpand,
    handleClickRemove,
    hasEditPermission,
    selectedItemId,
    handleClickRelatedItems }) => {

    useEffect(() => {
        if (selectedIds.includes(branch.id)) {
            return hadleClickItem(branch.id, branch.has_childs);
        }

    }, [branch, selectedIds]);

    useEffect(() => {
        if (parentBranchId && !expanded.includes(parentBranchId)) {
            onRemoveChildExpand(branch.id)
        }
    }, [parentBranchId, expanded]);

    const onClickEdit = useCallback((e) => {
        handleClickEdit(e, branch.id)
    }, [branch.id])

    const onClickAdd = useCallback((e) => {
        handleClickAdd(e, branch.id)
    }, [branch.id])

    const onClickDelete = useCallback((e) => {
        handleClickRemove(e, branch.id)
    }, [branch.id])

    const onClickRelated= useCallback((e) => {
        handleClickRelatedItems(e, branch.id)
    }, [branch.id])

    const getStyle = useCallback((e) => {
        if(branch.id === selectedItemId){
            return {background : SELECTED_ROW_COLOR}
        }
       return {}
    }, [branch.id, selectedItemId])


    return (
        <StyledTreeItem key={branch.id} style={{ textAlign: "left"}} rootnode={branch.is_root_node.toString()} nodeId={branch.id.toString()} label={
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <Grid item xs={12} display="inline-flex" alignItems={"center"} style ={getStyle()}>
                    <Typography variant="h6" >
                        {branch.indexed_item_name}
                    </Typography>
                    {hasEditPermission &&
                        <>
                            {!branch.is_root_node &&
                                <IconButton onClick={onClickEdit} aria-label="delete" size="small" color="primary">
                                    <ModeEditOutlineOutlinedIcon fontSize="inherit" />
                                </IconButton>
                            }
                            <IconButton onClick={onClickAdd} aria-label="delete" size="small" color="success">
                                <AddCircleOutlineIcon fontSize="inherit" />
                            </IconButton>
                            <IconButton onClick={onClickRelated} aria-label="delete" size="small" color="success">
                                <AutorenewIcon fontSize="inherit" />
                            </IconButton>
                            {!branch.has_elements && !branch.has_childs &&
                                <IconButton onClick={onClickDelete} aria-label="delete" size="small" color="success">
                                    <RemoveCircleIcon fontSize="inherit" />
                                </IconButton>}
                        </>}

                </Grid>
            </Grid>}>
            {
                branch.has_childs ?
                    <>
                        {branch.branch_childs.map((_branch) => (
                            <StyledItemTree
                                parentBranchId={branch.id}
                                branch={_branch}
                                selectedIds={selectedIds}
                                handleClickEdit={handleClickEdit}
                                handleClickAdd={handleClickAdd}
                                handleClickRemove={handleClickRemove}
                                hadleClickItem={hadleClickItem}
                                expanded={expanded}
                                onRemoveChildExpand={onRemoveChildExpand}
                                hasEditPermission={hasEditPermission}
                                selectedItemId ={selectedItemId}
                                handleClickRelatedItems={handleClickRelatedItems}
                            />
                        ))}
                    </> :
                    null
            }

        </StyledTreeItem>
    );
});

export default StyledItemTree;

StyledItemTree.propTypes = {
    branchStucture: PropTypes.array,
    onClickAdd: PropTypes.func,
    onClickEdit: PropTypes.func,
    expandedList: PropTypes.array
}

StyledItemTree.defaultProps = {

}
