import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import TuneIcon from "@mui/icons-material/Tune";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import SearchButton from "../../button/SearchButton/SearchButton";
import SplitButton from "../../button/SplitButton";
import OpenCloseFilterButton from "../../button/OpenCloseFilterButton";
import ReplayIcon from "@mui/icons-material/Replay";
import { isSearchParamsObjectEmpty } from "../../../helpers/methods";
import useFilterSearchParams from "../../../hooks/useFilterSearchParams";

import {
  overflowButtonStyle,
  centerVericalAlignIconStyle,
} from "../../../helpers/styles";

export default function UniversalToolBar(props) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const { clearSearchParamsByFilterPrefix, getSearchParamsByFilterPrefix } =
    useFilterSearchParams(searchParams);

  const isFilterSet =
    props.isFilterSet !== undefined
      ? props.isFilterSet
      : !isSearchParamsObjectEmpty(
          getSearchParamsByFilterPrefix(props.filterPrefix)
        );

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      columnSpacing={1}
      rowSpacing={2}
      style={props.style}
    >
      {props.showCreateButton && (
        <Grid item xs={12} md={props.mdGridButton}>
          <Button
            fullWidth
            variant="contained"
            size="small"
            startIcon={
              <ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />
            }
            onClick={props.onClickCreateItem}
            sx={overflowButtonStyle}
          >
            {props.createButtonLabel
              ? props.createButtonLabel
              : props.itemType === "ticket"
              ? t("bar.tool_bar.create_ticket")
              : props.itemType === "task"
              ? t("bar.tool_bar.create_task")
              : props.itemType === "post_in"
              ? t("bar.tool_bar.create_post_in")
              : props.itemType === "post_out"
              ? t("bar.tool_bar.create_post_out")
              : props.itemType === "purchase"
              ? t("bar.tool_bar.create_purchase")
              : props.itemType === "ico" && props.singleIcoChecked && props.isRejectedAgreement
              ? t("bar.tool_bar.copy_agreement")
              : props.itemType === "ico" && props.singleIcoChecked && !props.allow_only_preview
              ? t("bar.tool_bar.edit_agreement")
              : props.itemType === "ico" 
              ? t("bar.tool_bar.create_agreement")
              : t("bar.tool_bar.create_order")}
          </Button>
        </Grid>
      )}
        {props.extraEditButtonList.map((button, idx) => (
        <Grid
          item
          xs={12}
          md={
            props.extraButtonListMdGrid
              ? props.extraButtonListMdGrid[idx]
              : props.mdGridExtraButton || props.mdGridButton
          }
        >
          {button}
        </Grid>
      ))}
      <Grid item xs={12} md={props.mdGridButton}>
        <Button
          fullWidth
          variant="contained"
          size="small"
          startIcon={<TuneIcon sx={centerVericalAlignIconStyle} />}
          onClick={props.onClickAdjustTable}
          sx={overflowButtonStyle}
        >
          {t("bar.tool_bar.adjust_table")}
        </Button>
      </Grid>
      {props.showMyFiltersButton && (
        <Grid item xs={12} md={props.mdGridButton}>
          <Button
            fullWidth
            variant="contained"
            size="small"
            startIcon={<FilterAltIcon sx={centerVericalAlignIconStyle} />}
            onClick={props.onClickMyFilters}
            color={isFilterSet ? "success" : "primary"}
            sx={overflowButtonStyle}
          >
            {t("bar.tool_bar.my_filters")}
          </Button>
        </Grid>
      )}
      {props.showCleanfiltersButton && (
        <Grid item xs={12} md={props.mdGridButton}>
          <Button
            fullWidth
            variant="contained"
            size="small"
            startIcon={<ReplayIcon sx={centerVericalAlignIconStyle} />}
            onClick={
              props.cleanFiltersFn ? 
              props.cleanFiltersFn :
              () =>
              setSearchParams(
                clearSearchParamsByFilterPrefix(props.filterPrefix)
              )
            }
            sx={overflowButtonStyle}
          >
            {t("bar.tool_bar.clear_filters")}
          </Button>
        </Grid>
      )}
      {props.showMassActionButton && (
        <Grid item xs={12} md={props.mdGridButton}>
          <SplitButton
            startIcon={<AccountTreeIcon sx={centerVericalAlignIconStyle} />}
            label={t("bar.tool_bar.mass_actions")}
            options={props.massActionButtonOptions}
          />
        </Grid>
      )}

      {props.showOpenCloseFilterButton && (
        <Grid item xs={12} md={props.mdGridButton}>
          <OpenCloseFilterButton filterPrefix={props.filterPrefix} />
        </Grid>
      )}

      {props.extraButtonList.map((button, idx) => (
        <Grid
          item
          xs={12}
          md={
            props.extraButtonListMdGrid
              ? props.extraButtonListMdGrid[idx]
              : props.mdGridExtraButton || props.mdGridButton
          }
        >
          {button}
        </Grid>
      ))}

      {props.showSearchButton && (
        <Grid item xs={12} md={0.5} textAlign={{ xs: "center", md: "right" }}>
          <SearchButton onClickSearch={props.onClickSearch} />
        </Grid>
      )}
    </Grid>
  );
}

UniversalToolBar.propTypes = {
  style: PropTypes.object,
  onClickAdjustTable: PropTypes.func,
  onClickMyFilters: PropTypes.func,
  onClickCreateItem: PropTypes.func,
  massActionButtonOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      callback: PropTypes.func,
    })
  ),
  onClickOpenCloseFilter: PropTypes.func,
  onClickSearch: PropTypes.func,
  itemType: PropTypes.oneOf(["ticket", "order", "task"]),
  showMassActionButton: PropTypes.bool,
  showCreateButton: PropTypes.bool,
  showOpenCloseFilterButton: PropTypes.bool,
  showCleanfiltersButton: PropTypes.bool,
  showMyFiltersButton: PropTypes.bool,
  showSearchButton: PropTypes.bool,
  mdGridButton: PropTypes.number,
  mdGridExtraButton: PropTypes.number,
  extraButtonList: PropTypes.array,
  extraButtonListMdGrid: PropTypes.array,
  filterPrefix: PropTypes.string,
  isFilterSet: PropTypes.bool,
  createButtonLabel: PropTypes.string,
  extraEditButtonList : PropTypes.array,
  cleanFiltersFn : PropTypes.func,
  isRejectedAgreement : PropTypes.bool
};

UniversalToolBar.defaultProps = {
  itemType: "ticket",
  showMassActionButton: true,
  showCreateButton: true,
  showCleanfiltersButton: true,
  showOpenCloseFilterButton: true,
  showMyFiltersButton: true,
  showSearchButton: true,
  mdGridButton: 1.75,
  extraButtonList: [],
  extraEditButtonList : [],
  isRejectedAgreement : false
};
