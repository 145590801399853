import { Grid } from "@mui/material";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import useCorrespondenceService from "../../services/correspondenceService";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import { NAVIGATION_DRAWER_TYPE_CORRESPONDENCE } from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import useCheckPermission from "../../hooks/usePermission";
import { EDIT_CORRESPONDENCE_PERMISSION } from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import AutocompleteField from "../../components/field/AutocompleteField";
import CorrespondencePostInTab from "../../components/tabs/CorrespondenceRelatedTablesTabs/CorrespondencePostInTab/CorrespondencePostInTab";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function PostAllocationPage(props) {
  const { t } = useTranslation();
  const { pageName } = props;
  const { getPostFilteringData, getPostSubcategoryList } = useCorrespondenceService();
  const [postSubcategorySelectList, setPostSubcategorySelectList] = useState([])
  const getPostSubcategoryListFn = useAsyncFn(getPostSubcategoryList)
  const postFilteringData = useAsync(getPostFilteringData);
  const [hasEditPermission] = useCheckPermission(EDIT_CORRESPONDENCE_PERMISSION)
  const [filterParams, setFilterParams] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  const handleAutocompleteChange = (e, value_object) => {
    setFilterParams((prevParams) => {
      const updatedParams = {
        ...prevParams,
        page:1, 
      };
       const newSearchParams = new URLSearchParams(searchParams);
       newSearchParams.set('page', 1);
       setSearchParams(newSearchParams)

      if (value_object?.id) {
        getPostSubcategoryListFn.execute({ post_category: value_object.id })
          .then((res) => setPostSubcategorySelectList(res));
        updatedParams.post_subcat_category = value_object.id; 
      } else {
        delete updatedParams.post_subcat_category;
        delete updatedParams.post_subcat;
        setPostSubcategorySelectList([]);
      }
  
      return updatedParams;
    });
  };
  
  const handleSubcategoryChange = (e, value_object) => {
    setFilterParams((prevParams) => {
      const updatedParams = {
        ...prevParams,
        page:1, 
      };
      const newSearchParams = new URLSearchParams(searchParams); 
      newSearchParams.set('page', 1);
      setSearchParams(newSearchParams)
      if (value_object?.id) {
        updatedParams.post_subcat = value_object.id;
      } else {
        delete updatedParams.post_subcat;
      }
  
      return updatedParams;
    });
  };

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_CORRESPONDENCE}
    >
      <DefaultPageWrapper titleKey={"post_allocation"}>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
        >

          <Grid
            container
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            rowSpacing={2}
          >
            <Grid item xs={2}>
              <AutocompleteField
                name="post_subcat_category"
                label={t("table.correspondence.shipment_category")}
                value={filterParams?.post_subcat_category}
                options={postFilteringData.value?.post_subcat_category}
                isObjectOption={true}
                optionLabelKey={"post_cat_name"}
                addNewValue={false}
                onChange={handleAutocompleteChange}
                disabled={props.readOnly}
              />
            </Grid>
            <Grid item xs={2}>
              <AutocompleteField
                name="post_subcat"
                label={t("form.post_form.shipment_subcategory")}
                value={filterParams?.post_subcat}
                options={postSubcategorySelectList}
                isObjectOption={true}
                optionLabelKey={"post_subcat_name"}
                addNewValue={false}
                onChange={handleSubcategoryChange}
                disabled={props.readOnly}
              />
            </Grid>

            <Grid item xs={12}></Grid>
            <Grid item xs={11}></Grid>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <CorrespondencePostInTab
              readOnly={props.readOnly}
              pageName={props.pageName}
              filteringData={postFilteringData}
              hasEditPermission={hasEditPermission}
              filterParams={filterParams}
            />
          </Grid>
        </Grid>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}

