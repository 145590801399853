import BasicDialog from "../../base/BasicDialog";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useState } from "react";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import useItemService from "../../../services/itemService";
import BaseTable from "../../base/BaseTable/BaseTable";
import TableService from "../../../services/tableService";
import AddButton from "../../button/AddButton";
import { ITEM_REFERENCE_TABLE_FIELDS_CONFIG } from "./DialogTablesConfig";
import useDialog from "../../../hooks/useDialog";
import SelectReferenceItemDialog from "../SelectReferenceItemDialog/SelectReferenceItemDialog";
import { getErrorMsg } from "../../../helpers/methods";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import ConfirmationDialog from "../ConfirmationDialog/confirmationDialog";

function ItemReferenceDialog(props) {
  const { t } = useTranslation();
  const [relatedItemsLocal, setRelatedItemsLocal] = useState([]);
  const [referenceToRemove, setReferenceToRemove] = useState()
  const snackbarAlert = useSnackbarAlert();

  const [
    openConfirmationDialog,
    onOpenConfirmationDialog,
    onCloseConfirmationDialog,
  ] = useDialog()

  const {
    getRelatedItems,
    getItemShortData,
    createItemReferenceValue,
    removeItemReference
  } = useItemService();

  const [
    openSelectReferenceDialog,
    onOpenSelectReferenceDialog,
    onCloseSelectReferenceDialog,
  ] = useDialog();

  const relatedItems = useAsync(
    () => getRelatedItems({ 'related_item': props.relatedItemId }),
    [props.relatedItemId]
  );

  const itemShortData = useAsync(
    () => getItemShortData(props.relatedItemId),
    [props.relatedItemId]
  );

  const createItemReferenceValueFn = useAsyncFn(createItemReferenceValue);
  const removeItemReferenceFn = useAsyncFn(removeItemReference);

  useEffect(() => {
    if (relatedItems.loading) {
      return;
    }
    setRelatedItemsLocal(relatedItems.value);
  }, [relatedItems.loading]);

  const getAddRowButton = useCallback(() => {
    return (<AddButton onClick={onOpenSelectReferenceDialog} />)
  }, [])

  const onClickRemove = useCallback((itemRefId) => {
    setReferenceToRemove(itemRefId)
    onOpenConfirmationDialog()
  }, [])

  
  const onRemove = useCallback(() => {
    onCloseConfirmationDialog()
    removeItemReferenceFn
      .execute(referenceToRemove)
      .then((res) => {
        relatedItems.refetch()
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.reference_removed")
        );
        setReferenceToRemove(undefined)
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(error.data),
          t("snackbar_alert.occurred_error_during_agreement_removing")
        );

      });
  }, [referenceToRemove])


  const onCreateReference = useCallback((item2Id) => {
    createItemReferenceValueFn
      .execute({
        "item_1": props.relatedItemId,
        "item_2": item2Id
      })
      .then((res) => {
        relatedItems.refetch()
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.reference_created")
        );
        onCloseSelectReferenceDialog()
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(error.data),
          t("snackbar_alert.occurred_error_during_reference_creating")
        );

      });

  }, []);

  const onCloseDialog = useCallback(() =>{
    setReferenceToRemove(undefined)
    props.onClose()
  },[])

  const handleCloseConfirmationDialog = useCallback(() =>{
    setReferenceToRemove(undefined)
    onCloseConfirmationDialog()
    onCloseSelectReferenceDialog()
  },[])

  const isLoading = relatedItems.loading || itemShortData.loading

  return (
    <BasicDialog
      open={props.open}
      onClose={onCloseDialog}
      titleAlign="center"
      title={t("dialog.item_reference_dialog.items_reference")}
      maxWidth="sm"
      showTopActionButton={false}
      showBottomActionButton={false}
      bottomActionStyle={{ padding: "10px" }}
      showCustomFooter={false}
      showDialogActions={false}
    >
      <LoaderWrapper showLoader={isLoading}>
        <div style={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
          {getAddRowButton()}
          <BaseTable
            headers={TableService.getHeaderLables(
              ITEM_REFERENCE_TABLE_FIELDS_CONFIG
            )}
            rows={TableService.getPreparedDataForBaseTable(
              ITEM_REFERENCE_TABLE_FIELDS_CONFIG,
              relatedItemsLocal,
              [],
              undefined,
              onClickRemove
            )}
            showNoRecordsPaper={false}
          />
        </div>
      </LoaderWrapper>
      {
        openSelectReferenceDialog &&
        <SelectReferenceItemDialog
          open={openSelectReferenceDialog}
          onClose={onCloseSelectReferenceDialog}
          item1Name={itemShortData?.value?.item_identifactor}
          searchParams={itemShortData?.value ? { exclude: itemShortData?.value?.id } : {}}
          onSubmit={onCreateReference}
        />
      }

      {
        <ConfirmationDialog
          open={openConfirmationDialog}
          onNo={handleCloseConfirmationDialog}
          onYes={onRemove}
          title={t("dialog.item_reference_confirmation_dialog.confirm_action")}
          content={t("dialog.item_reference_confirmation_dialog.are_you_sure_to_remove_reference")}
        />}
    </BasicDialog>
  );
}

ItemReferenceDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  relatedItemId: PropTypes.string

};

ItemReferenceDialog.defaultProps = {
  open: false,

};

export default ItemReferenceDialog;
