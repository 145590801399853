import { useEffect } from "react";

import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import { useAsync } from "../../../hooks/useAsync";
import useEquipmentService from "../../../services/equipmentService";
import useItemService from "../../../services/itemService";

import LoaderWrapper from "../../wrapper/LoaderWrapper/LoaderWrapper";

import MeterParametersForm from "./MeterParametersForm";
import MeterLocationForm from "./MeterLocationForm";
import MeterLogsForm from "./MeterLogsForm";
import MeterAtributesForm from "./MeterAtributesForm";
import MeterTenantForm from "./MeterTenantForm";
import MeterTicketForm from "./MeterTicketForm";
import MeterSchemeForm from "./MeterSchemeForm";
import MeterMeasuringLocationForm from "./MeterMeasuringLocationForm";
import MeterReadingHistoryForm from "./MeterReadingHistoryForm";
import MeterBasicInfoForm from "./MeterBasicInfoForm";
import MeterDescriptionForm from "./MeterDescriptionForm";
import MeterEnclosureForm from "./MeterEnclosuresForm";

import CounterAssignDataDialog from "../../dialog/CounterAssignDataDialog";

export default function MeterPassportForm(props) {
  const { t } = useTranslation();

  const { getEquipmentForMeterDetails } = useEquipmentService();
  const equipmentDetails = useAsync(() =>
    getEquipmentForMeterDetails(props.equipmentId)
  );

  useEffect(() => {
    if (equipmentDetails.loading) return;

    if (props.setIsReadOnly) {
      props.setIsReadOnly(equipmentDetails.value?.equipment_is_readonly);
    }
  }, [equipmentDetails.loading]);

  const { getParametersListForMeters } = useItemService();
  const parameterList = useAsync(() =>
    getParametersListForMeters({ equipment: props.equipmentId })
  );

  const handleSubmit = () => {
    if (props.setExistsChanges) {
      props.setExistsChanges(true);
    }
    equipmentDetails.refetch();
  };

  const isLoading = equipmentDetails.loading || parameterList.loading;

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={1}
      marginBottom={2}
    >
      <Grid item xs={5}>
        <MeterBasicInfoForm formValue={equipmentDetails.value} />
      </Grid>
      <Grid item xs={7}>
        <MeterParametersForm
          equipmentId={equipmentDetails.value.id}
          parameterList={parameterList.value}
          parametersValues={equipmentDetails.value.parameters}
          onSubmit={handleSubmit}
        />
      </Grid>
      <Grid container item xs={7}>
        <Grid item xs={12}>
          <MeterLocationForm
            equipmentId={equipmentDetails.value.id}
            location={equipmentDetails.value.instalation_location}
            lastInstalation={equipmentDetails.value.equipment_date_instalation}
            lastDeinstalation={
              equipmentDetails.value.equipment_date_deinstalation
            }
            onSubmit={handleSubmit}
          />
        </Grid>
        <Grid item xs={12}>
          <MeterMeasuringLocationForm
            locations={equipmentDetails.value.measuring_location}
            factors={equipmentDetails.value.measuring_location_factors}
            startSettlement={equipmentDetails.value.eios_start_settlement}
            equipmentId={equipmentDetails.value.id}
            onSubmit={handleSubmit}
          />
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <MeterLogsForm logs={equipmentDetails.value.logs} />
      </Grid>
      <Grid item xs={9} marginTop={2}>
        <MeterAtributesForm
          equipmentId={equipmentDetails.value.id}
          formValue={equipmentDetails.value}
          onSubmit={handleSubmit}
        />
      </Grid>

      <Grid item xs={3} marginTop={2}>
        <MeterTenantForm
          tenant={equipmentDetails.value.tenant}
          startSettlement={equipmentDetails.value.eits_start_settlement}
          equipmentId={equipmentDetails.value.id}
          equipmentTenantSetId={
            equipmentDetails.value.equipment_instalation_tenant_set_id
          }
          onSubmit={handleSubmit}
        />
      </Grid>
      <Grid container item xs={6} marginTop={2}>
        <Grid item xs={12}>
          <MeterDescriptionForm formValue={equipmentDetails.value} />
        </Grid>
        <Grid item xs={3}>
          <MeterSchemeForm />
        </Grid>
        <Grid item xs={3}>
          <MeterReadingHistoryForm />
        </Grid>
        <Grid item xs={3}>
          <MeterTicketForm />
        </Grid>
      </Grid>
      <Grid item xs={6} marginTop={2}>
        <MeterEnclosureForm equipmentId={equipmentDetails.value.id} />
      </Grid>
      {props.openAssignDataMeterDialog && (
        <CounterAssignDataDialog
          dialogTitle={t("dialog.equipment.edit_counter_data")}
          open={props.openAssignDataMeterDialog}
          onClose={props.onCloseMeterAssignDataDialog}
          onSubmitCallback={handleSubmit}
          equipmentId={props.equipmentId}
        />
      )}
    </Grid>
  );
}

MeterPassportForm.propTypes = {
  equipmentId: PropTypes.string,
  setExistsChanges: PropTypes.func,
  setIsReadOnly: PropTypes.func,
};
