import { useState, useEffect, useCallback } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import { NAVIGATION_DRAWER_TYPE_OFFERS, NAVIGATION_DRAWER_TYPE_WAREHOUSES } from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import PropTypes from "prop-types";
import useItemService from "../../services/itemService";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import PurchasingTree from "../../components/other/PurchasingTree";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import useDialog from "../../hooks/useDialog";
import useDialogWithId from "../../hooks/useDialogWithId"
import PurchasingTreeItemDialog from "../../components/dialog/PurchasingTreeItemDialog";
import { getErrorMsg, sortedIemByNumers } from "../../helpers/methods";
import { useSnackbarAlert } from "../../context/snackbarAlert";
import { MANAGE_ITEMS_TREE_PERMISSION } from "../../helpers/constants";
import useCheckPermission from "../../hooks/usePermission";
import ConfirmationDialog from "../../components/dialog/ConfirmationDialog/confirmationDialog";
import ItemReferenceDialog from "../../components/dialog/ItemReferenceDialog/ItemReferenceDialog";
import BaseBox from "../../components/base/BaseBox";
import { useForm } from "../../hooks/useForm";
import TreeItemForm from "../../components/form/TreeItemForm";
import BoxWithTypography from "../../components/box/BoxWithTypography/BoxWithTypography";
import BaseTable from "../../components/base/BaseTable/BaseTable";
import TableService from "../../services/tableService";
import { ITEMN_LINKED_ELEMENTS_TABLE_FIELDS_CONFIG } from "./BoxTablesConfig";



export default function ItemTreePage(props) {
  const { pageName } = props;

  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const [
    openItemTreeBranchDialog,
    onOpenItemTreeBranchDialog,
    onCloseItemTreeBranchDialog
  ] = useDialog();

  const [
    openRelatedItemsDialog,
    relatedItemId,
    onOpenRelatedItemsDialog,
    onCloseRelatedItemsDialog,
  ] = useDialogWithId();

  const [
    openConfirmationDialog,
    onOpenConfirmationDialog,
    onCloseConfirmationDialog,
  ] = useDialog()

  const {
    getBranchStructure,
    removeItem,
    getItemsFilteringData,
    getItemDataWitElements,
    updateItem
  } = useItemService();

  const {
    formValue: selectedItem,
    setFormValue: setSelectedItem,
    onChange,
    onChangeAutocompleteFieldWithObjectOptions: onChangeAutocomplete
  } = useForm();

  const removeItemFn = useAsyncFn(removeItem);
  const getItemDataFn = useAsyncFn(getItemDataWitElements);
  const itemFilteringData = useAsync(getItemsFilteringData);

  const getBranchStructureFn = useAsyncFn(getBranchStructure)
  const [branchStucture, setBranchStructure] = useState(undefined)
  const [itemBranchToRemove, setItemBranchToRemove] = useState(undefined)
  const [purchasingTreeBranchDialogData, setPurchasingTreeBranchDialogData] = useState({})
  const [expandedBranch, setExpandedBranch] = useState()
  const [hasEditPermission] = useCheckPermission(MANAGE_ITEMS_TREE_PERMISSION)
  const branchStuctureData = useAsync(() => getBranchStructure({ 'main_branch': true }));
  const updateItemFn = useAsyncFn(updateItem);

  useEffect(() => {
    if (branchStuctureData.loading) {
      return;
    }
    setBranchStructure(branchStuctureData.value);
  }, [branchStuctureData.loading]);

  const onClickEdit = useCallback((editBranchId) => {
    setPurchasingTreeBranchDialogData({ branchId: editBranchId, parentBranchId: undefined })
    onOpenItemTreeBranchDialog()
  }, [purchasingTreeBranchDialogData])


  const onClickAdd = useCallback((parentBranchId) => {
    setPurchasingTreeBranchDialogData({ branchId: undefined, parentBranchId: parentBranchId })
    onOpenItemTreeBranchDialog()
  }, [purchasingTreeBranchDialogData])


  const onClickRelatedItems = useCallback((branchId) => {
    onOpenRelatedItemsDialog(branchId)
  }, [])


  const onClickRemove = useCallback(() => {
    if (itemBranchToRemove) {
      removeItemFn
        .execute(itemBranchToRemove)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.item_removed")
          );
          onRefetchData()

          handleCloseConfirmationDialog()
        })
        .catch((err) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_item_removing")
          );
        });
    }
  }, [purchasingTreeBranchDialogData, itemBranchToRemove])

  const hadleClickItem = (branchId, has_childs) => {
    if (has_childs) {
      getBranchStructureFn.execute({ 'item_parent': branchId })
        .then((res) => {
          findItemById(branchStucture, branchId, res)
        })
    }

    getItemDataFn.execute(branchId)
      .then((res) => {
        setSelectedItem(res)
      })
  }

  const findItemById = (nodes, itemInternalId, childs) => {
    for (let node of nodes) {
      if (node.id === itemInternalId) {
        node.branch_childs = sortedIemByNumers(childs)
        return nodes;
      }
      if (node.has_childs) {
        const found = findItemById(node.branch_childs, itemInternalId, childs);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };


  const handleCloseItemBranchDialog = (dataAreChanged = false) => {
    onCloseItemTreeBranchDialog()
    setPurchasingTreeBranchDialogData({})
    if (dataAreChanged) {
      setExpandedBranch([])
      onRefetchData()
    }
  }

  const onRefetchData = () => {
    branchStuctureData.refetch()
    setSelectedItem(undefined)

  }

  const onSelectBranchToRemove = useCallback((itemId) => {
    setItemBranchToRemove(itemId)
    onOpenConfirmationDialog()
  }, itemBranchToRemove)

  const handleCloseConfirmationDialog = useCallback(() => {
    setItemBranchToRemove(undefined)
    onCloseConfirmationDialog()
  }, [itemBranchToRemove])

  const onSubmitForm = useCallback(() => {
      updateItemFn
        .execute(selectedItem.id, selectedItem)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.item_updated")
          );
          onRefetchData()
        })
        .catch((err) => {
          snackbarAlert.openErrorSnackbarAlert(
            getErrorMsg(err.data),
            t("snackbar_alert.occurred_error_during_item_updating")
          );
        });
   
  }, [selectedItem]);

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_WAREHOUSES}>
      <DefaultPageWrapper titleKey={"item_tree"}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          columnSpacing={2}
          rowSpacing={2}
          style={{

            overflowX: "hidden"
          }}
        >
          <Grid item xs={8}>
            <BaseBox
              style={{
                maxHeight: "60vh",
                overflowY: "auto",
                overflowX: "hidden"
              }}>
              <LoaderWrapper showLoader={branchStuctureData.loading}>
                {branchStucture !== undefined &&
                  <PurchasingTree
                    branchStucture={branchStucture}
                    onClickAdd={onClickAdd}
                    onClickEdit={onClickEdit}
                    onClickRemove={onSelectBranchToRemove}
                    onClickRelatedItems={onClickRelatedItems}
                    expandedList={expandedBranch}
                    hadleClickItem={hadleClickItem}
                    hasEditPermission={hasEditPermission}
                    selectedItemId = {selectedItem?.id} />}
              </LoaderWrapper>
            </BaseBox>
          </Grid>
          <Grid item xs={4}>
            <BoxWithTypography
              style={{
                padding: "10px",
                maxHeight: "57vh",
                overflowY: "auto",
                overflowX: "hidden"
              }}
              label={t("page.item_tree_page.linked_elements")}
              component={"div"}>
          <BaseTable
            headers={TableService.getHeaderLables(
              ITEMN_LINKED_ELEMENTS_TABLE_FIELDS_CONFIG
            )}
            rows={TableService.getPreparedDataForBaseTable(
              ITEMN_LINKED_ELEMENTS_TABLE_FIELDS_CONFIG,
              selectedItem?.elements? selectedItem.elements : [],

            )}
            showNoRecordsPaper={true}
          />
            </BoxWithTypography>
          </Grid>
          <Grid item xs={12}>
            <BoxWithTypography
                style={{ padding: "10px" }}
                label={t("page.item_tree_page.item_details")}
                component={"div"}
            >
              {selectedItem ?
           
                  <Grid item xs={12}>
                    <TreeItemForm
                      item={selectedItem}
                      filteringData={itemFilteringData}
                      readOnly={!hasEditPermission}
                      onChangeAutocomplete={onChangeAutocomplete}
                      onChange={onChange}
                      onSubmitForm={onSubmitForm}
                      allowChangeParent = {false}
                      parents={itemFilteringData?.value.items}
                    />
                  </Grid>

                :
                <Typography
                  textAlign="center"
                  variant="overline"
                  display="block"
                  gutterBottom
                  style={{ margin: 0, fontSize: "20px", color: "var(--primary)" }}
                >
                  {t("page.item_tree_page.choose_item_to_details")}
                </Typography>}
            </BoxWithTypography>
          </Grid>
        </Grid>
        {
          openItemTreeBranchDialog &&
          <PurchasingTreeItemDialog
            {...purchasingTreeBranchDialogData}
            open={openItemTreeBranchDialog}
            onClose={handleCloseItemBranchDialog}
          />
        }
        {
          openRelatedItemsDialog &&
          <ItemReferenceDialog
            open={openRelatedItemsDialog}
            onClose={onCloseRelatedItemsDialog}
            relatedItemId={relatedItemId}
          />
        }
        {
          <ConfirmationDialog
            open={openConfirmationDialog}
            onNo={handleCloseConfirmationDialog}
            onYes={onClickRemove}
            title={t("dialog.item_tree_confirmation_dialog.confirm_action")}
            content={t("dialog.item_tree_confirmation_dialog.are_you_sure_to_remove_item_branch")}
          />}
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
ItemTreePage.propTypes = {
  style: PropTypes.object,
};

ItemTreePage.defaultProps = {

};


