import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import AutocompleteField from "../../field/AutocompleteField";
import IconButtonWithTooltip from "../../button/IconButtonWithTooltip";
import AddButton from "@mui/icons-material/Add";
import CreateParameterDialog from "../../dialog/CreateParameterDialog/CreateParameterDialog";
import useDialog from "../../../hooks/useDialog";
import { sortedIemByNumers } from "../../../helpers/methods";
import PropTypes from "prop-types";

export default function PurchasingTreeBranchForm(props) {
  const { t } = useTranslation();

  const [
    openCreateParameterDialog,
    onOpenCreateParameterDialog,
    onCloseCreateParameterDialog,
  ] = useDialog();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={1}
    >
      {props.parentBranchId && (
        <>
          <Grid item xs={4}>
            <TextFieldFormControl
              label={t("form.purchasing_tree_branch_form.item_parent_number")}
              value={props.parentFormValue?.item_number}
              disabled
            />
          </Grid>
          <Grid item xs={8}>
            <TextFieldFormControl
              label={t("form.purchasing_tree_branch_form.item_parent_name")}
              value={props.parentFormValue?.item_name}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <AutocompleteField
              name="parents_parameters"
              label={t(`form.purchasing_tree_branch_form.parents_parameters`)}
              value={props.parentFormValue?.parameters}
              options={props.parameterList?.value || []}
              isObjectOption={true}
              optionLabelKey={"param_name"}
              onChange={props.onChangeAutocompleteFieldWithObjectOptions}
              readOnly
              multiple
            />
          </Grid>
        </>
      )}
      {!props.parentBranchId && (
        <>
          <Grid item xs={12}>
            <AutocompleteField
              name="parents_parameters"
              label={t(`form.purchasing_tree_branch_form.parents_parameters`)}
              value={props.itemFormValue?.parents_parameters}
              options={props.parameterList?.value || []}
              isObjectOption={true}
              optionLabelKey={"param_name"}
              onChange={props.onChangeAutocompleteFieldWithObjectOptions}
              readOnly
              multiple
            />
          </Grid>
          <Grid item xs={props.showAddParameterButton ? 11 : 12}>
            <AutocompleteField
              name="parameters"
              label={t(`form.purchasing_tree_branch_form.parameters`)}
              value={props.itemFormValue?.parameters}
              options={props.parameterList?.value || []}
              isObjectOption={true}
              optionLabelKey={"param_name"}
              onChange={props.onChangeAutocompleteFieldWithObjectOptions}
              readOnly={props.readOnly || props.readOnlyParameter}
              multiple
            />
          </Grid>
          {props.showAddParameterButton && (
            <Grid item xs={1} style={{ paddingLeft: "0" }}>
              <IconButtonWithTooltip
                style={{ border: "3px solid var(--secondary)" }}
                title={t(`form.part_parameter_form.create_parameter`)}
                onClick={onOpenCreateParameterDialog}
              >
                <AddButton
                  fontSize="medium"
                  sx={{
                    color: "var(--secondary)",
                  }}
                />
              </IconButtonWithTooltip>
            </Grid>
          )}
          {!props.showExtendForm &&
            <Grid item xs={12}>
              <AutocompleteField
                name="item_parent"
                label={t(`form.purchasing_tree_branch_form.item_parent`)}
                value={props.itemFormValue?.item_parent}
                options={props.itemSelectListData?.value || []}
                isObjectOption={true}
                optionLabelKey={"indexed_item_name"}
                onChange={props.onChangeAutocompleteItemParent}
                multiple={false}
                disableClearable
                addNewValue={false}

              />
            </Grid>}
        </>
      )}
      {!props.showExtendForm &&
        <>
          <Grid item xs={4}>
            <TextFieldFormControl
              name={"item_number"}
              label={t("form.purchasing_tree_branch_form.item_number")}
              value={props.itemFormValue?.item_number}
              onChange={props.onChange}
              readOnly={props.isItemParentChanged}
              disabled={props.parentBranchId !== undefined}
            />
          </Grid>
          <Grid item xs={8}>
            <TextFieldFormControl
              name={"item_name"}
              label={t("form.purchasing_tree_branch_form.item_name")}
              value={props.itemFormValue?.item_name}
              onChange={props.onChange}
            />

          </Grid></>}

      {openCreateParameterDialog && (
        <CreateParameterDialog
          open={openCreateParameterDialog}
          onClose={onCloseCreateParameterDialog}
          onRefetchParamatersList={props.parameterList.refetch}
        />
      )}
    </Grid>
  );
}

PurchasingTreeBranchForm.propTypes = {
  showExtendForm: PropTypes.bool,
};

PurchasingTreeBranchForm.defaultProps = {
  showAddParameterButton: true,
  showExtendForm: false
};
