import { useCallback, useRef } from "react";

import { useTranslation } from "react-i18next";

import useTicketService from "../services/ticketService";
import useOrderService from "../services/orderService";

import { useSnackbarAlert } from "../context/snackbarAlert";
import { useAsyncFn } from "./useAsync";
import useBasicDrawer from "./useBasicDrawer";
import { getErrorMsg } from "../helpers/methods";
import useCheckPermission from "./usePermission";
import {
  MANAGE_ORDERS_PERMISSION,
  MANAGE_TICKETS_PERMISSION,
} from "../helpers/constants";
import { useAuth } from "../context/auth";

const useTicketOrderDetailsDrawer = (
  ticketData,
  setTicketDataLocaly,
  ticketFilteringData,
  orderFilteringData
) => {
  const [openDetailsDrawer, setOpenDetailsDrawer] = useBasicDrawer(false);

  const [hasPermissionToManageTickets] = useCheckPermission(
    MANAGE_TICKETS_PERMISSION
  );
  const [hasPermissionToManageOrders] = useCheckPermission(
    MANAGE_ORDERS_PERMISSION
  );

  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const { updateTicketData, getTicketDetailsData } = useTicketService();

  const { getOrderDetailsData, updateOrderData } = useOrderService();

  const openedItemTypeRef = useRef();

  const openedTicketIdRef = useRef();
  const opendedTicketDetailsDataRef = useRef();
  const getTicketDetailsDataFn = useAsyncFn(getTicketDetailsData);

  const updateCurrentAndNextSelectedOrderTicketsState = (ticketId) =>
    setTicketDataLocaly((prevData) => {
      return prevData.map((rowData) => {
        if (rowData.id === ticketId || rowData.id === openedTicketIdRef.current)
          return {
            ...rowData,
            updatedSelectedInnerElement: new Date(),
          };
        return rowData;
      });
    });

  const handleOpenTicketDetailsDrawer = useCallback(
    (ticketId) => {
      if (
        openDetailsDrawer &&
        !openedOrderIdRef.current &&
        ticketId === opendedTicketDetailsDataRef.current?.id
      ) {
        setOpenDetailsDrawer(false);
        openedItemTypeRef.current = null;
        openedTicketIdRef.current = null;
        opendedTicketDetailsDataRef.current = null;
      } else {
        setOpenDetailsDrawer(true);
        openedItemTypeRef.current = "ticket_order";
        updateCurrentAndNextSelectedOrderTicketsState(ticketId);
        openedTicketIdRef.current = ticketId;
        openedOrderIdRef.current = null;
        executeGetTicketDetailsDataFn(ticketId);
      }
    },
    [openDetailsDrawer, openedTicketIdRef.current, openedItemTypeRef.current]
  );
  const executeGetTicketDetailsDataFn = (ticketId) => {
    getTicketDetailsDataFn
      .execute(ticketId)
      .then((data) => (opendedTicketDetailsDataRef.current = data));
  };
  const updateTicketDataFn = useAsyncFn(updateTicketData);
  const onUpdateTicketDataPromise = (ticketId, dataToSend) => {
    const isUpdateStatus = Object.keys(dataToSend).includes("status_data");
    return updateTicketDataFn
      .execute(ticketId, dataToSend)
      .then((data) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.ticket_data_updated")
        );
        if (isUpdateStatus) {
          setTicketDataLocaly(undefined);
        }
        ticketData.refetch();
        return Promise.resolve(data);
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error.data,
            t("snackbar_alert.occurred_error_during_updating_ticket_data")
          )
        );
        return Promise.reject(error);
      });
  };

  const openedOrderIdRef = useRef();
  const opendedOrderDetailsDataRef = useRef();
  const getOrderDetailsDataFn = useAsyncFn(getOrderDetailsData);
  const handleOpenOrderDetailsDrawer = useCallback(
    (orderId, ticketId) => {
      if (
        openDetailsDrawer &&
        openedItemTypeRef.current === "order" &&
        orderId === opendedOrderDetailsDataRef.current?.id
      ) {
        setOpenDetailsDrawer(false);
        openedItemTypeRef.current = null;
        openedOrderIdRef.current = null;
        opendedOrderDetailsDataRef.current = null;
      } else {
        setOpenDetailsDrawer(true);
        openedItemTypeRef.current = "order";
        updateCurrentAndNextSelectedOrderTicketsState(ticketId);

        openedOrderIdRef.current = orderId;
        openedTicketIdRef.current = ticketId;
        executeGetOrderDetailsDataFn(orderId);
      }
    },
    [
      openDetailsDrawer,
      openedOrderIdRef.current,
      openedItemTypeRef.current,
      openedTicketIdRef.current,
    ]
  );

  const handleToggleDetailsDrawer = (state) => {
    setTicketDataLocaly((prevData) => {
      return prevData.map((rowData) => {
        if (rowData.id === openedTicketIdRef.current)
          return {
            ...rowData,
            updatedSelectedInnerElement: new Date(),
          };
        return rowData;
      });
    });
    openedTicketIdRef.current = null;
    openedOrderIdRef.current = null;
    openedItemTypeRef.current = null;
    setOpenDetailsDrawer(state);
  };

  const handleToSetShowChangesUpdated = (ticketId) => {
    setTicketDataLocaly((prevData) => {
      return prevData.map((rowData) => {
        if (rowData.id === ticketId)
          return {
            ...rowData,
            has_changes: false,
          };
        return rowData;
      });
    });
  };

  const executeGetOrderDetailsDataFn = (orderId) => {
    getOrderDetailsDataFn
      .execute(orderId)
      .then((data) => (opendedOrderDetailsDataRef.current = data));
  };

  const updateOrderDataFn = useAsyncFn(updateOrderData);

  const onUpdateOrderDataPromise = (orderId, dataToSend) => {
    const isUpdateStatus = Object.keys(dataToSend).includes("status_data");
    return updateOrderDataFn
      .execute(orderId, dataToSend)
      .then((data) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.order_data_updated")
        );
        setTicketDataLocaly((prevData) => {
          return prevData.map((rowData) => {
            if (rowData.id === data.ticket_id)
              return {
                ...rowData,
                updatedInnerElementData: new Date(),
              };
            return rowData;
          });
        });
        if (isUpdateStatus) {
          ticketData.refetch();
        }
        return Promise.resolve(data);
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error.data,
            t("snackbar_alert.occurred_error_during_updating_order_data")
          )
        );
        return Promise.reject(error);
      });
  };

  const getAvailableActionButtons = () => {
    if (openedItemTypeRef.current === "ticket_order") {
      let actionButtons = [
        "claim_ticket",
        "related_tickets",
        "send_offer",
        "copy_ticket",
        "enclosures",
        "questionnaire",
        "ticket_protocol",
      ];
      if (hasPermissionToManageTickets) {
        actionButtons.push("change_status");
      }
      return actionButtons;
    }
    if (openedItemTypeRef.current === "order") {
      if (hasPermissionToManageOrders) {
        return ["change_status", "enclosures", "equipment_readings"];
      }
      return ["enclosures"];
    }

    return null;
  };

  return {
    openDetailsDrawer,
    handleToggleDetailsDrawer,
    handleOpenTicketDetailsDrawer,
    handleOpenOrderDetailsDrawer,
    handleToSetShowChangesUpdated,
    openedTicketIdRef,
    openedOrderIdRef,
    itemData:
      openedItemTypeRef.current === "ticket_order"
        ? opendedTicketDetailsDataRef.current
        : opendedOrderDetailsDataRef.current,
    filteringData:
      openedItemTypeRef.current === "ticket_order"
        ? ticketFilteringData?.value
        : orderFilteringData?.value,
    itemType: openedItemTypeRef.current,
    isLoading:
      openedItemTypeRef.current === "ticket_order"
        ? getTicketDetailsDataFn.loading || !opendedTicketDetailsDataRef.current
        : getOrderDetailsDataFn.loading || !opendedOrderDetailsDataRef.current,
    onUpdateDataPromise:
      openedItemTypeRef.current === "ticket_order"
        ? onUpdateTicketDataPromise
        : onUpdateOrderDataPromise,
    onRefetchData:
      openedItemTypeRef.current === "ticket_order"
        ? () =>
            executeGetTicketDetailsDataFn(
              opendedTicketDetailsDataRef.current?.id
            )
        : () =>
            executeGetOrderDetailsDataFn(
              opendedOrderDetailsDataRef.current?.id
            ),
    availableActionButtons: getAvailableActionButtons(),
    hasPermissionToManageTickets,
    hasPermissionToManageOrders,
  };
};

export default useTicketOrderDetailsDrawer;
