import { useAsync } from "./useAsync";
import { useState, useEffect } from "react";

const useLocalData = (dataFn, searchParams,  withPagination = false) => {



  const [localData, setLocalData] = useState();
  const [dataCount, setDataCount]= useState();

  const getData = useAsync(() => {
    return dataFn(searchParams)
  }, [ searchParams])

  
    useEffect(() => {
      if (getData.loading) {
        return;
      }
      if (withPagination && getData.value){
        setLocalData(getData.value.results)
        setDataCount(getData.value.count)
      }else{
        setLocalData(getData.value)
      }
    }, [getData.loading, withPagination ]);
  



  return {
    data : localData,
    dataCount : dataCount,
    dataLoading : getData.loading,
    dataRefetch : getData.refetch,
    searchParams
  };
};

export default useLocalData;
