import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useForm } from "../../../hooks/useForm";
import { useCallback, useEffect } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { getErrorMsg, isEmptyObject, isEmptyValue } from "../../../helpers/methods";
import useIcoService from "../../../services/icoService";
import AutocompleteField from "../../field/AutocompleteField";
import FloatField from "../../base/FloatField";
import DateField from "../../field/DateField/DateField";


const InterestRateDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeAutocompleteFieldWithObjectOptions,
    onChangeDate
  } = useForm({});


  const {
    getInterestRateDetails,
    createInterestRate,
    updateInterestRate,
  } = useIcoService();


  const createinterestRateFn = useAsyncFn(createInterestRate)
  const updateinterestRateFn = useAsyncFn(updateInterestRate)

  const interestRateData = useAsync(
    () => {
      if (props.interestRateId) {
        return getInterestRateDetails(props.interestRateId)
      }
      return Promise.resolve(formValue)
    },
    [props.interestRateId]
  );


  useEffect(() => {
    if (interestRateData.loading) {
      return;
    }
    if (!isEmptyObject(interestRateData.value)) {
      setFormValue(interestRateData.value);
    }
  }, [interestRateData.loading]);



  const onSubmit = useCallback(() => {
    if (props.interestRateId) {
      updateinterestRateFn
        .execute(props.interestRateId, formValue)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.interest_rate_updated")
          );
          props.onClose();
          props.onRefetch();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(getErrorMsg(
            error.data,
            t("snackbar_alert.occurred_error_during_interest_rate_updating")
          ));
        });
    } else {
      createinterestRateFn
        .execute(formValue)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.interest_rate_created")
          );
          props.onClose();
          props.onRefetch();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            getErrorMsg(
              error.data,
              t("snackbar_alert.occurred_error_during_interest_rate_creating")
            )
          );
        });
    }

  }, [formValue]);

  const isValid = !isEmptyValue(formValue.interest) && !isEmptyValue(formValue.intrat_value) && !isEmptyValue(formValue.intrat_date)


  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={props.interestRateId ? t(`dialog.ico_interest_rate_dialog.interest_rate_details`) : t(`dialog.ico_interest_rate_dialog.add_interest_rate`)}
      maxWidth="sm"
      showDialogActions
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowGap={1}
      >
        {interestRateData.loading ?
          <LoaderWrapper isLoading={true} />
          :
          <>
            <Grid item xs={12}>
              <AutocompleteField
                name="interest"
                label={t("dialog.ico_interest_rate_dialog.interest")}
                value={formValue?.interest}
                options={props.filteringData?.value?.interests}
                isObjectOption={true}
                optionLabelKey={"interest_name"}
                addNewValue={false}
                onChange={onChangeAutocompleteFieldWithObjectOptions}
                required
                readOnly={props.interestRateId}
              />
            </Grid>

            <Grid item xs={12}>
              <FloatField
                name="intrat_value"
                label={t("dialog.ico_interest_rate_dialog.intrat_value")}
                value={formValue?.intrat_value}
                required
                decimalPlaces={4}
                onChange={onChange}
                suffix={"%"}
              />
            </Grid>

            <Grid item xs={12}>
              <DateField
                name="intrat_date"
                label={t("dialog.ico_interest_rate_dialog.intrat_date")}
                value={formValue?.intrat_date}
                onChange={onChangeDate}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                variant="contained"
                color="primary"
                fullWidth
                loading={createinterestRateFn.loading || updateinterestRateFn.loading}
                disabled={!isValid}
                onClick={onSubmit}
              >
                {props.interestRateId ? t("save") : t("create")}
              </LoadingButton>
            </Grid>
          </>
        }
      </Grid>
    </BasicDialog>
  );
};

InterestRateDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onRefetch: PropTypes.func,
  interest_rateRateId: PropTypes.string,
  filteringData: PropTypes.object,
};

InterestRateDialog.defaultProps = {
  open: false,
};

export default InterestRateDialog
