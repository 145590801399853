import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import AutocompleteField from "../../field/AutocompleteField";
import FloatField from "../../base/FloatField/FloatField";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import SelectField from "../../field/SelectField";
import DateField from "../../field/DateField/DateField";
import LoadingButton from "@mui/lab/LoadingButton";
import { isEmptyValue } from "../../../helpers/methods";



const TreeItemForm = (props) => {

  const { t } = useTranslation();

  const requiredFields = ["item_number", "item_name"];

  const isValid = props.item
    ? requiredFields.every(
      (fieldName) => !isEmptyValue(props.item[fieldName])
    )
    : false;


  const getFirstItemDataRow = () => {
    return (
      <>

        <Grid item xs={12} sm={2}>
          <AutocompleteField
            name={"item_parent"}
            label={t("form.tree_item_form.item_parent")}
            value={props.item?.item_parent}
            options={props.parents}
            isObjectOption={true}
            optionLabelKey={"indexed_item_name"}
            addNewValue={false}
            disabled={!props.allowChangeParent}
            onChange={props.onChangeAutocompleteItemParent}
          />
        </Grid>
        <Grid item xs={12} sm={1}>
          <TextFieldFormControl
            name={"item_number"}
            label={t("form.tree_item_form.item_number")}
            value={props.item?.item_number}
            onChange={props.onChange}
            disabled={props.readOnly}
            required
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextFieldFormControl
            name={"item_name"}
            label={t("form.tree_item_form.item_name")}
            value={props.item?.item_name}
            onChange={props.onChange}
            disabled={props.readOnly}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldFormControl
            name={"item_description"}
            label={t("form.tree_item_form.item_description")}
            value={props.item?.item_description}
            onChange={props.onChange}
            disabled={props.readOnly}
            resize={"vertical"}
            multiline
            rows={1}
          />
        </Grid>
      </>
    )
  }
  const getSecondItemDataRow = () => {
    return (
      <>

        <Grid item xs={12} sm={2}>
          <AutocompleteField
            name={"knr"}
            label={t("form.tree_item_form.knr")}
            value={props.item?.knr}
            options={props.filteringData?.value?.knrs}
            isObjectOption={true}
            optionLabelKey={"knr_indexed_name"}
            addNewValue={false}
            onChange={props.onChangeAutocomplete}
            disabled={props.readOnly}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <AutocompleteField
            name={"gtu"}
            label={t("form.tree_item_form.gtu")}
            value={props.item?.gtu}
            options={props.filteringData?.value?.gtus}
            isObjectOption={true}
            optionLabelKey={"gtu_name"}
            addNewValue={false}
            onChange={props.onChangeAutocomplete}
            disabled={props.readOnly}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <AutocompleteField
            name={"item_procurement_category"}
            label={t("form.tree_item_form.procurement_category")}
            value={props.item?.item_procurement_category}
            options={props.filteringData?.value?.procurement_categories}
            isObjectOption={true}
            optionLabelKey={"proccat_name"}
            addNewValue={false}
            onChange={props.onChangeAutocomplete}
            disabled={props.readOnly}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <AutocompleteField
            name={"item_procurement_keeper"}
            label={t("form.tree_item_form.item_procurement_keeper")}
            value={props.item?.item_procurement_keeper}
            options={props.filteringData?.value?.users}
            isObjectOption={true}
            optionLabelKey={"full_name"}
            addNewValue={false}
            onChange={props.onChangeAutocomplete}
            disabled={props.readOnly}
          />
        </Grid>
        <Grid item xs={1}>
          <SelectField
            name={"item_procurement_criticaly"}
            label={t("form.tree_item_form.procurement_criticaly")}
            value={props.item?.item_procurement_criticaly}
            options={props.filteringData?.value?.item_procurement_criticaly_list}
            onChange={props.onChange}
            isObjectOption={false}
            disabled={props.readOnly}

          />
        </Grid>
        <Grid item xs={1}>
          <DateField
            name="created"
            label={t("form.tree_item_form.created")}
            value={props.item?.created}
            disabled
            showClearIcon={false}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextFieldFormControl
            name={"creator"}
            label={t("form.tree_item_form.creator")}
            value={props.item?.creator}
            disabled
          />
        </Grid>
      </>)
  }



  return (
    <Grid container spacing={1}>

      <Grid item xs={12}>
        <Grid container columns={{ sm: 12, xs: 12 }} spacing={1}>
          {getFirstItemDataRow()}
          {getSecondItemDataRow()}
          {props.showSubmitButton &&
            <Grid item xs={12}>
              <LoadingButton
                variant="contained"
                color="primary"
                fullWidth
                loading={false}
                disabled={!isValid}
                onClick={props.onSubmitForm}
              >
                {t("save")}
              </LoadingButton>
            </Grid>}
        </Grid>
      </Grid>
    </Grid>


  );

};

TreeItemForm.propTypes = {
  item: PropTypes.object,
  filteringData: PropTypes.object,
  readOnly: PropTypes.bool,
  allowChangeParent: PropTypes.bool,
  parents: PropTypes.array,
  showSubmitButton: PropTypes.bool
};

TreeItemForm.defaultProps = {
  readOnly: true,
  allowChangeParent: false,
  parents: [],
  showSubmitButton: true
};

export default TreeItemForm;
