import { useState, useEffect, useMemo, useRef, useCallback } from "react";

import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";

import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import { NAVIGATION_DRAWER_TYPE_REPORTS } from "../../components/drawer/NavigationDrawer/NavigationDrawer";

import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";

import useTicketService from "../../services/ticketService";
import { useAsync } from "../../hooks/useAsync";

import TicketTable from "../../components/table/TicketTable";
import UniversalToolBarWithDialogs from "../../components/bar/UniversalToolBarWithDialogs";
import TicketChartToolBar from "../../components/bar/TicketChartToolBar";

import TableService from "../../services/tableService";

import ChartService from "../../services/chartService";
import DateService from "../../services/dateService";
import VerticalBarChart from "../../components/charts/VerticalBarChart/VerticalBarChart";

import OpenCloseFilterButton from "../../components/button/OpenCloseFilterButton";
import CollapseOrExpandButton from "../../components/other/CollapseOrExpandButton";

import MultipleLocalizationDialog from "../../components/dialog/MutlipleLocalizationDialog/MultipleLocalizationDialog";
import useDialog from "../../hooks/useDialog";

import { exportTicketsSlaToFileUrl } from "../../helpers/apiUrls";
import { getObjectFromSearchParams } from "../../helpers/methods";

import InfoButton from "../../components/button/InfoButton";
import ChartInformationDialog from "../../components/dialog/ChartInformationDialog";

import {
  TICKET_SLA_TABLE_FIELDS_CONFIG,
  TICKET_SLA_TABLE_NAME,
} from "./TableConfig";

const TABLE_CONFIGS = [
  { name: TICKET_SLA_TABLE_NAME, config: TICKET_SLA_TABLE_FIELDS_CONFIG },
];

export default function TicketsReportBasePage(props) {
  const { t } = useTranslation();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
  } = usePaginationWithSearchParams();

  const { getTicketWithSlaShortData, getTicketSlaFilteringData } =
    useTicketService();

  const getLabelByKey = (seachParamsObject) => {
    if (props.labelBy === "time_range") {
      return seachParamsObject.time_range === "day" ? "hour" : "day";
    }
    return props.labelBy;
  };

  const ticketSlaData = useAsync(() => {
    let tempSearchParams = {
      ...getObjectFromSearchParams(searchParams),
    };

    tempSearchParams.group_by_key = props.groupBy;
    tempSearchParams.label_by_key = getLabelByKey(tempSearchParams);
    tempSearchParams.hours_timezone_offset =
      DateService.getHoursTimezoneOffset();
    return getTicketWithSlaShortData(tempSearchParams);
  }, [searchParams]);

  const ticketSlaFilteringData = useAsync(() =>
    getTicketSlaFilteringData({ include_ticket_object: true })
  );

  const [ticketSlaDataLocaly, setTicketSlaDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();
  const [ticketDistribution, setTicketDistribution] = useState(undefined);

  useEffect(() => {
    if (ticketSlaData.loading) {
      setTicketDistribution(undefined);
      return;
    }
    setTicketSlaDataLocaly(ticketSlaData.value.results);
    setCountRecords(ticketSlaData.value.count);
    setTicketDistribution(ticketSlaData.value.tickets_distribution);
  }, [ticketSlaData.loading]);

  const [hiddenColumnsForTables, setHiddenColumnsForTables] =
    useState(undefined);
  const [columnsOrdersForTables, setColumnsOrdersForTables] =
    useState(undefined);

  const refreshTable = () => {
    setTicketSlaDataLocaly(undefined);
    setTicketDistribution(undefined);
    ticketSlaData.refetch();
  };

  const [openChartInfoDialog, onOpenChartInfoDialog, onCloseChartInfoDialog] =
    useDialog();

  const [
    openMultipleLocalizationDialog,
    onOpenMultipleLocalizationDialog,
    onCloseMultipleLocalizationDialog,
  ] = useDialog();
  const clickedTicketMultipleLocalization = useRef();

  const handleOpenMultipleLocalizationDialog = useCallback(
    (e, ticketId) => {
      e.stopPropagation();
      clickedTicketMultipleLocalization.current = ticketSlaDataLocaly.find(
        (ticket) => ticket.id === ticketId
      ).locations;
      onOpenMultipleLocalizationDialog();
    },
    [onOpenMultipleLocalizationDialog, ticketSlaDataLocaly]
  );

  const handleCloseMultipleLocalizationDialog = () => {
    clickedTicketMultipleLocalization.current = null;
    onCloseMultipleLocalizationDialog();
  };

  const ticketSlaExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      TICKET_SLA_TABLE_FIELDS_CONFIG,
      hiddenColumnsForTables?.[TICKET_SLA_TABLE_NAME],
      columnsOrdersForTables?.[TICKET_SLA_TABLE_NAME]
    );
  }, [
    hiddenColumnsForTables?.[TICKET_SLA_TABLE_NAME],
    columnsOrdersForTables?.[TICKET_SLA_TABLE_NAME],
    TICKET_SLA_TABLE_FIELDS_CONFIG,
  ]);

  const isMultipleGroupsChart = ticketDistribution?.is_multiple_groups_chart;

  const [chartIsFullScreen, setChartFullScreen] = useState(false);

  const getUniveralToolBarExtraButtonList = () => {
    let buttonList = [
      <CollapseOrExpandButton
        isExpanded={chartIsFullScreen}
        onChangeIsExpanded={setChartFullScreen}
      />,
    ];

    if (props.showChartInformationDialog) {
      buttonList = [
        <InfoButton
          label={t("chart_description")}
          onClick={onOpenChartInfoDialog}
        />,
        ...buttonList,
      ];
    }

    if (props.showTenantVisibilityButton) {
      buttonList = [
        <OpenCloseFilterButton
          openCloseFilterLabel={t(
            "page.feedback_management_page.tenant_rr_all"
          )}
          closeFilterLabel={t(
            "page.feedback_management_page.tenat_rr_not_visibility"
          )}
          openFilterLabel={t(
            "page.feedback_management_page.tenat_rr_visibility"
          )}
          openFilterKey={"tenant_rr_visibility"}
        />,
        ...buttonList,
      ];
    }

    return buttonList;
  };

  const getExtraButtonListMdGrid = () => {
    let buttonListMdGrid = [0.5];
    if (props.showChartInformationDialog) {
      buttonListMdGrid = [1.5, ...buttonListMdGrid];
    }

    if (props.showTenantVisibilityButton) {
      buttonListMdGrid = [2.5, ...buttonListMdGrid];
    }

    return buttonListMdGrid;
  };

  const isLoading =
    ticketSlaDataLocaly === undefined || ticketSlaFilteringData.loading;

  return (
    <NavigationDrawer
      pageName={props.pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_REPORTS}
    >
      <DefaultPageWrapper titleKey={props.pageTitleKey}>
        <LoaderWrapper showLoader={isLoading}>
          <Grid
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            rowSpacing={1}
          >
            {props.showNavigationBar && (
              <Grid item xs={12}>
                {props.NavigationBar}
              </Grid>
            )}
            <Grid item xs={12}>
              <TicketChartToolBar
                pageName={props.pageName}
                showSelectTimeRange={props.showSelectTimeRange}
                showSelectObjectLevel2={props.showSelectObjectLevel2}
                showTicketOwner={props.showTicketOwner}
                showOnlySelectDay={props.showOnlySelectDay}
                showOpenCloseFilterButton={props.showOpenCloseFilterButton}
                showTenantVisibilityButton={props.showTenantVisibilityButton}
                showSelectCategoryButton={props.showSelectCategoryButton}
                showSelectTicketK={props.showSelectTicketK}
                tableFilteringData={ticketSlaFilteringData}
                tableFieldConfigs={TICKET_SLA_TABLE_FIELDS_CONFIG}
              />
            </Grid>
            {ticketDistribution && (
              <>
                <Grid
                  item
                  xs={12}
                  style={{
                    height: chartIsFullScreen
                      ? props.chartHeightInFullScreen
                      : props.chartHeight,
                  }}
                >
                  <VerticalBarChart
                    data={{
                      labels: ticketDistribution.labels,
                      datasets:
                        ChartService.prepareDataForSingleOrMultiGroupsChart(
                          ticketDistribution.data,
                          isMultipleGroupsChart
                        ),
                    }}
                    legendDisplay={isMultipleGroupsChart}
                    titleDisplay={false}
                    dataLabelsDisplay={true}
                    tooltipFormaterFn={(value, context) =>
                      props.showChartTooltipFormaterForPercentRatio
                        ? ChartService.getChartTooltipFormaterForPercentRatioFn(
                            value,
                            context
                          )
                        : undefined
                    }
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} marginY={1}>
              <UniversalToolBarWithDialogs
                pageName={props.pageName}
                tableConfig={TABLE_CONFIGS}
                filteringData={ticketSlaFilteringData}
                refreshTable={refreshTable}
                resetPageNumber={resetPageNumber}
                hiddenColumnsForTables={hiddenColumnsForTables}
                setHiddenColumnsForTables={setHiddenColumnsForTables}
                columnsOrdersForTables={columnsOrdersForTables}
                setColumnsOrdersForTables={setColumnsOrdersForTables}
                showMassActionButton={false}
                showCreateButton={false}
                showOpenCloseFilterButton={props.showOpenCloseFilterButton}
                showCleanfiltersButton={false}
                showMyFiltersButton={false}
                showSearchButton={false}
                extraButtonList={getUniveralToolBarExtraButtonList()}
                extraButtonListMdGrid={getExtraButtonListMdGrid()}
              />
            </Grid>
            <Grid item xs={12}>
              {hiddenColumnsForTables &&
                columnsOrdersForTables &&
                !chartIsFullScreen && (
                  <TicketTable
                    showCleanFilterIcon={false}
                    showFilters={true}
                    showDetailsIcon={true}
                    data={ticketSlaDataLocaly}
                    filteringData={ticketSlaFilteringData}
                    countRecords={countRecords}
                    page={page}
                    onPageChange={handleChangePageWithSearchParams}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    resetPageNumber={resetPageNumber}
                    tableConfig={TICKET_SLA_TABLE_FIELDS_CONFIG}
                    hiddenColumns={
                      hiddenColumnsForTables[TICKET_SLA_TABLE_NAME]
                    }
                    columnsOrders={
                      columnsOrdersForTables[TICKET_SLA_TABLE_NAME]
                    }
                    style={{ maxHeight: "65vh" }}
                    showExportToFileButton={true}
                    exportToFileUrl={exportTicketsSlaToFileUrl}
                    exportToFileSearchParams={searchParams}
                    exportToFileHeaders={ticketSlaExportHeaders}
                    exportToFileFileName={`${t(
                      "dialog.ticket_sla_dialog.tickets"
                    ).replace(" ", "_")}.xlsx`}
                    itemDetailsLinkPrefix={"ticket_details"}
                    onClickMultipleLocationAlert={
                      handleOpenMultipleLocalizationDialog
                    }
                  />
                )}
            </Grid>
            {openMultipleLocalizationDialog &&
              clickedTicketMultipleLocalization.current && (
                <MultipleLocalizationDialog
                  open={openMultipleLocalizationDialog}
                  onClose={handleCloseMultipleLocalizationDialog}
                  localizationData={clickedTicketMultipleLocalization.current}
                  itemType={"ticket"}
                />
              )}
            {openChartInfoDialog && (
              <ChartInformationDialog
                open={openChartInfoDialog}
                onClose={onCloseChartInfoDialog}
                content={props.chartDescrtiption}
              />
            )}
          </Grid>
        </LoaderWrapper>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}

TicketsReportBasePage.propTypes = {
  labelBy: PropTypes.string,
  groupBy: PropTypes.string,
  showSelectTimeRange: PropTypes.bool,
  showTicketOwner: PropTypes.bool,
  showSelectObjectLevel2: PropTypes.bool,
  pageName: PropTypes.string,
  pageTitleKey: PropTypes.string,
  NavigationBar: PropTypes.element,
  showNavigationBar: PropTypes.bool,
  showOnlySelectDay: PropTypes.bool,
  showOpenCloseFilterButton: PropTypes.bool,
  showTenantVisibilityButton: PropTypes.bool,
  showSelectCategoryButton: PropTypes.bool,
  showSelectTicketK: PropTypes.bool,
  chartHeight: PropTypes.string,
  chartHeightInFullScreen: PropTypes.string,
  showChartInformationDialog: PropTypes.bool,
  chartDescrtiption: PropTypes.string,
  showChartTooltipFormaterForPercentRatio: PropTypes.bool,
};

TicketsReportBasePage.defaultProps = {
  chartHeight: "45vh",
  chartHeightInFullScreen: "72vh",
  showChartTooltipFormaterForPercentRatio: false,
};
